<template>
  <div class="OneclickLogin">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>一键登录</span>
          <p>用户无需谨记账号和密码，一键登录。</p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/about/oneclickLogin/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <PartFlexSwiper
      :pFlexSwiperData="p2Data"
      :pFlexSwiperDatas="p2Datas"
      :pFlexSwiperTitle="p2Title"
    ></PartFlexSwiper>
    <section class="part3">
      <span class="part-title">一键登录产品特性</span>
      <div class="part3_main p3_pc">
        <div class="part3-left reveal-left">
          <div
            class="part3-item"
            v-for="(item, index) in part3Data"
            :key="index"
            @click="onP3Item(item, index)"
          >
            <p>{{ item.p }}</p>
          </div>
        </div>
        <img class="reveal-right" :src="part3Bg" alt="" />
      </div>
      <div class="part3_main p3_mobile">
        <el-carousel
          trigger="click"
          :interval="500000"
          arrow="always"
          ref="p3m2Carousel"
        >
          <el-carousel-item
            class="part3-item"
            v-for="(item, index) in part3Data"
            :key="index"
          >
            <div>
              <img :src="item.img" alt="" />
            </div>
            <p>{{ item.p }}</p>
          </el-carousel-item>
        </el-carousel>
      </div>
    </section>
    <section class="part4">
      <span class="part-title">一键登录表现形式</span>
      <div class="p4_main reveal-bottom">
        <img src="../../assets/img/about/oneclickLogin/part4-icon.png" alt="" />
        <div class="p4_right">
          <div class="p4_cBig">
            <span>场景提炼</span>
          </div>
          <img
            src="../../assets/img/about/oneclickLogin/part4-arrow.png"
            alt=""
          />
          <div class="p4_rMain p4_pc">
            <div class="p4_rItem" v-for="(item, i) in p4Data" :key="i">
              <div class="p4_cSmall">
                <span v-text="item.span"></span>
              </div>
              <p v-text="item.p"></p>
            </div>
          </div>
          <div class="p4_mobile">
            <div class="p4_rItem">
              <div
                class="p4_cSmall"
                v-for="(item, i) in p4Data"
                :key="i"
                @click="p4Click(i, item.p)"
              >
                <span v-text="item.span"></span>
              </div>
            </div>
            <p v-text="p4ItemP"></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PartFlexSwiper from '@/components/solution/PartFlexSwiper.vue';
export default {
  data() {
    return {
      // p2
      p2Data: [
        {
          img: require('../../assets/img/about/oneclickLogin/part2-icon1.png'),
          imgOver: require('../../assets/img/about/oneclickLogin/part2-icon1-over.png'),
          span: '应用场景广',
          p: '相较于SDK版，H5版不再仅仅局限于App内调用，可拓宽至 H5页面内调用',
        },
        {
          img: require('../../assets/img/about/oneclickLogin/part2-icon2.png'),
          imgOver: require('../../assets/img/about/oneclickLogin/part2-icon2-over.png'),
          span: '对接开发简单',
          p: 'H5页面只需引入js-sdk，不需要过多额外的开发学习成本(IOS、安卓)，相较于SDK版更易于上手',
        },
        {
          img: require('../../assets/img/about/oneclickLogin/part2-icon3.png'),
          imgOver: require('../../assets/img/about/oneclickLogin/part2-icon3-over.png'),
          span: '跨平台验证',
          p: '登陆验证操作无需考虑手机操作系统是安卓、IOS亦或是其他，有浏览器即可',
        },
        {
          img: require('../../assets/img/about/oneclickLogin/part2-icon4.png'),
          imgOver: require('../../assets/img/about/oneclickLogin/part2-icon4-over.png'),
          span: '便于版本升级',
          p: 'H5版只需更新接口或js，即可实现实时更新，SDK版则需重新集成开发与发布',
        },
      ],
      p2Datas: [],
      p2Title: '一键登录特殊优势',
      // p3
      part3Data: [
        {
          p: '用户无需谨记账号和密码，输入四位本机号后即可一键登录，降低应用注册/登录门槛，减轻用户记忆负担，提高用户体验。',
          img: require('../../assets/img/about/oneclickLogin/part3-icon1.png'),
        },
        {
          p: '操作流程化繁为简，相比短信验证码的登录时长缩短80%，有效提升拉新转化率和用户留存率。',
          img: require('../../assets/img/about/oneclickLogin/part3-icon2.png'),
        },
        {
          p: '传统短信验证只要发送就计费，一键登录验证成功调用后才计费，无形中节省了一大笔无效短信验证费用。',
          img: require('../../assets/img/about/oneclickLogin/part3-icon3.png'),
        },
        {
          p: '每到节假日常常通道拥挤，就有用户反馈收取不到验证码，而一键登录直连运营商网关，3秒内即可完成校验，且取号流程无短信黑名单机制。',
          img: require('../../assets/img/about/oneclickLogin/part3-icon4.png'),
        },
      ],
      part3Bg: require('../../assets/img/about/oneclickLogin/part3-icon1.png'),
      part3Datas: [],
      // p4
      p4Data: [
        {
          span: '用户转账支付',
          p: '在支付、转账等需对用户身份校验的场景，借助一键登录能力，可与运营商直接交互，过程无法被不法分子所模拟，期间所产生的验证数据也不会泄露给第三方，有效地保障了交易操作的安全进行。',
        },
        {
          span: '用户登录/注册',
          p: '各类业务在用户注册/登录阶段，可以使用H5一键登录对原有的其它注册/登录方式进行优化替代，让用户快速完成注册/登录流程，提高用户体验。帮助业务拉新促活，实现快捷登入和安全风控。',
        },
        {
          span: '用户信息更新',
          p: '用户在注册时留下的联系信息可能比较久远了，业务方需要确认用户手机号是否有更新，可以在用户使用一键登录的过程中静默校验用户留存的手机号是否是当前正在使用的手机号，在减少对用户打扰的同时，更高效地维系与用户的联系。',
        },
      ],
      p4ItemP: '',
    };
  },
  components: {
    PartFlexSwiper,
  },
  mounted() {
    // 数据初始化
    this.p2Datas = [
      JSON.parse(JSON.stringify(this.p2Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p2Data.slice(-2))),
    ];
    // 内容初始化
    this.part3Datas = [
      JSON.parse(JSON.stringify(this.part3Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.part3Data.slice(-2))),
    ];
    // 状态初始化
    this.start();
  },
  methods: {
    // 初始化
    start() {
      $('.part3-item').eq(0).addClass('active');
      this.p4Click(0, this.p4Data[0].p);
    },
    onP3Item(item, index) {
      $('.part3-item').removeClass('active');
      $('.part3-item').eq(index).addClass('active');
      this.part3Bg = item.img;
    },
    p4Click(i, p) {
      this.p4ItemP = p;
      $('.p4_cSmall').removeClass('active');
      $('.p4_mobile .p4_cSmall').eq(i).addClass('active');
    },
  },
};
</script>

<style lang="less" scoped>
/* 第一部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 723px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      position: absolute;
      width: 523px;
      height: 691px;
      z-index: -1;
      top: 28px;
      right: 57px;
    }
  }
  .part1-title {
    width: 538px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 67px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
.part3 {
  box-sizing: border-box;
  width: 100%;
  height: 852px;
  padding-top: 77px;
  .part-title {
    margin-bottom: 130px;
  }
  .part3_main {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .part3-left {
      display: grid;
      grid-template-columns: 346px 346px;
      grid-template-rows: 180px 180px;
      column-gap: 32px;
      row-gap: 52px;
      margin-right: 74px;
      .part3-item {
        background: #ffffff;
        box-shadow: 11px 4px 27px 0px rgba(122, 130, 148, 0.03);
        display: flex;
        flex-direction: row;
        justify-content: center;
        cursor: pointer;
        padding-top: 38px;
        p {
          display: block;
          width: 288px;
          text-align: justify;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          letter-spacing: 2px;
        }
      }
      .part3-item.active {
        background: #1b6abb;
        p {
          color: #ffffff;
        }
      }
    }
  }
  .p3_mobile {
    display: none;
  }
}
.part4 {
  width: 100%;
  padding-top: 74px;
  padding-bottom: 66px;
  background: linear-gradient(-88deg, #ffffff 0%, #f2f4fa 52%, #ffffff 100%);
  .part-title {
    margin-bottom: 113px;
  }
  .p4_main {
    width: 1120px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    .p4_right {
      width: 777px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-left: -45px;
      margin-top: -34px;
      .p4_cBig {
        width: 156px;
        height: 156px;
        background: linear-gradient(130deg, #6c97ce 0%, #005cb9 100%);
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        span {
          font-size: 18px;
          font-family: FontExcellent;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .p4_rMain {
        .p4_rItem {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 28px;
          .p4_cSmall {
            width: 101px;
            height: 101px;
            background: linear-gradient(130deg, #6c97ce 0%, #005cb9 100%);
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 29px;
            span {
              font-size: 18px;
              font-family: FontExcellent;
              font-weight: 400;
              color: #ffffff;
              line-height: 18px;
              text-align: center;
              display: block;
              width: 74px;
            }
          }
          p {
            box-sizing: border-box;
            display: block;
            width: 420px;
            background: #ffffff;
            border-radius: 11px;
            font-size: 16px;
            line-height: 27px;
            font-weight: 400;
            color: #666666;
            padding: 15px 28px;
            text-align: justify;
          }
        }
        .p4_rItem:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  .p4_mobile {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  /* 第一部分 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        content: url('../../assets/img/about/oneclickLogin/mobile/part1-icon.png');
        width: 3.33rem;
        height: 4.18rem;
        top: 0.1rem;
        right: 0.06rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0px;
        margin-top: -0.62rem;
      }
      p,
      a {
        display: none;
      }
    }
  }
  .part3 {
    height: auto;
    background: #f2f6fa;
    padding-top: 0.48rem;
    .part-title {
      margin-bottom: 0.5rem;
    }
    .part3_main {
      width: 6.5rem;
      padding-bottom: 0.68rem;
      .part3-item {
        width: 3.32rem;
        margin-left: 1.59rem;
        height: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
          width: min-content;
          height: 1.52rem;
          margin-bottom: 0.42rem;
          img {
            max-height: 100%;
          }
        }
        p {
          width: 3.32rem;
          font-size: 0.16rem;
          line-height: 0.28rem;
          text-align: justify;
        }
      }
    }
    .p3_pc {
      display: none;
    }
    .p3_mobile {
      display: block;
    }
  }
  .part4 {
    padding-top: 0.49rem;
    padding-bottom: 0.84rem;
    background: #ffffff;
    .part-title {
      margin-bottom: 0.49rem;
    }
    .p4_main {
      width: 6rem;
      flex-direction: column;
      align-items: center;
      > img {
        width: 3.82rem;
        height: 4.97rem;
        margin-bottom: 0.6rem;
      }
      .p4_right {
        width: 100%;
        flex-direction: column;
        margin-left: 0px;
        margin-top: 0px;
        .p4_cBig {
          width: 1.56rem;
          height: 1.56rem;
          margin-bottom: 0.15rem;
          span {
            font-size: 0.18rem;
          }
        }
        > img {
          content: url('../../assets/img/about/oneclickLogin/mobile/part4-arrow.png');
          width: 0.3rem;
          height: 0.39rem;
          margin-bottom: 0.17rem;
        }
        .p4_pc {
          display: none;
        }
        .p4_mobile {
          display: block;
          width: 100%;
          margin: 0 auto;
          .p4_rItem {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-bottom: 0.3rem;
            .p4_cSmall {
              width: 1.01rem;
              height: 1.01rem;
              background: linear-gradient(130deg, #3f5164 0%, #47596c 100%);
              border-radius: 50%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              span {
                display: block;
                width: 0.74rem;
                font-size: 0.18rem;
                font-family: FontExcellent;
                font-weight: 400;
                color: #ffffff;
                line-height: 0.18rem;
                text-align: center;
              }
            }
            .p4_cSmall.active {
              background: linear-gradient(130deg, #6c97ce 0%, #005cb9 100%);
            }
          }
          > P {
            box-sizing: border-box;
            display: block;
            width: 5.7rem;
            height: auto;
            margin: 0 auto;
            background: #0b5db3;
            border-radius: 0.11rem;
            padding: 0.2rem;
            font-size: 0.16rem;
            text-align: justify;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.OneclickLogin {
  .partFlexSwiper {
    background: url('../../assets/img/about/oneclickLogin/part2-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part-title {
      margin-bottom: 78px;
    }
    .partFlexSwiper-item {
      width: 250px !important;
      height: 400px !important;
      padding-top: 47px !important;
      > div {
        height: 85px !important;
      }
      p {
        width: 172px !important;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .OneclickLogin {
    .partFlexSwiper {
      height: 6.34rem;
      background: url('../../assets/img/about/oneclickLogin/mobile/part2-bg.png')
        no-repeat;
      background-size: 100% 100%;
      .part-title {
        margin-bottom: 0.52rem;
      }
      .partFlexSwiper-item {
        width: 2.5rem !important;
        height: 4rem !important;
        padding-top: 0.47rem !important;
        > div {
          height: 0.85rem !important;
        }
        p {
          width: 1.72rem !important;
        }
      }
      .swiper_bg_blue {
        margin-top: 0.31rem;
      }
    }
    .el-carousel__indicators {
      display: none;
    }
    .el-carousel__container {
      height: 3rem;
      .el-carousel__arrow {
        width: 0.58rem;
        height: 0.58rem;
        background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
      }
      .el-icon-arrow-right::before,
      .el-icon-arrow-left::before {
        font-size: 0.3rem;
      }
    }
  }
}
</style>
