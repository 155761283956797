<template>
  <section class="partCore">
    <span class="part-title">核心业务场景</span>
    <div class="pCore_main pCore_m_pc reveal-bottom2" :style="{ width }">
      <div
        class="pCore_item"
        v-for="(item, i) in pCoreData"
        :key="i"
        :style="{
          background: 'url(' + item.img + ') no-repeat',
          backgroundSize: '100% 100%',
        }"
        @mousemove="pCoreMove(i, item.imgOver)"
      >
        <span v-text="item.span"></span>
        <p v-text="item.p"></p>
      </div>
    </div>
    <div class="pCore_main pCore_m_mobile">
      <div
        class="pCore_m_left"
        :style="{
          background: 'url(' + pCoreDataItem.imgOver + ') no-repeat',
          height,
          paddingTop: height == '3rem' ? '1.8rem' : '2.3rem',
        }"
      >
        <span v-text="pCoreDataItem.span"></span>
        <p v-text="pCoreDataItem.p"></p>
      </div>
      <div class="pCore_m_right">
        <div
          class="pCore_item2"
          v-for="(item, i) in pCoreData"
          :key="i"
          @click="pCoreClick(i, item.img2, item)"
        >
          <span v-text="item.span"></span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      pCoreDataItem: {},
    };
  },
  props: {
    pCoreData: {},
    width: {
      default: "1270px",
      typeof: String,
    },
    height: {
      default: "3.6rem",
      typeof: String,
    },
  },
  methods: {
    // pCore
    pCoreMove(i, img) {
      $(".pCore_item").removeClass("active");
      $(".pCore_item").each((index, item2) => {
        $(item2).css({
          background: "url(" + this.pCoreData[index].img + ") no-repeat",
          backgroundSize: "103% 100%",
        });
      });
      $(".pCore_item").eq(i).addClass("active");
      $(".pCore_item")
        .eq(i)
        .css({
          background: "url(" + img + ") no-repeat",
          backgroundSize: "102% 100%",
        });
    },
    pCoreClick(i, img, item) {
      this.pCoreDataItem = item;
      $(".pCore_item2").css("background", "#ffffff");
      $(".pCore_item2")
        .eq(i)
        .css({
          background: "url(" + img + ") no-repeat",
          backgroundSize: "100% 100%",
        });
    },
  },
};
</script>

<style lang="less" scoped>
.partCore {
  width: 100%;
  padding-top: 90px;
  padding-bottom: 123px;
  .part-title {
    margin-bottom: 74px;
  }
  .pCore_main {
    width: 1270px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #000000;
    .pCore_item {
      box-sizing: border-box;
      width: 182px;
      height: 360px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 282px;
      // transition: all 0.5s;
      span {
        font-size: 24px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #005cb9;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        line-height: 30px;
        text-align: justify;
        width: 304px;
        text-align: center;
        display: none;
      }
    }
    .pCore_item.active {
      width: 368px;
      height: 360px;
      padding-top: 210px;
      span {
        color: #ffffff;
        margin-bottom: 14px;
      }
      p {
        display: block;
      }
    }
  }
  .pCore_m_mobile {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .partCore {
    width: 100%;
    padding-top: 0.67rem;
    padding-bottom: 0.89rem;
    .part-title {
      margin-bottom: 0.67rem;
    }
    .pCore_main {
      width: 6.18rem;
      justify-content: center;
      .pCore_m_left {
        box-sizing: border-box;
        width: 3.68rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-size: 100% 100% !important;
        span {
          font-size: 0.21rem;
          font-family: FontExcellent;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 0.1rem;
        }
        p {
          font-size: 0.17rem;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.27rem;
          display: flex;
          width: 2.7rem;
          text-align: center;
        }
      }
      .pCore_item2 {
        box-sizing: border-box;
        width: 2.5rem;
        height: 0.6rem;
        background: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #f1f1f1;
        span {
          font-size: 0.19rem;
          font-family: FontExcellent;
          font-weight: 400;
          color: #005cb9;
        }
      }
      .pCore_item2:last-child {
        border-bottom: 1px solid #f1f1f1;
      }
    }
    .pCore_m_pc {
      display: none;
    }
    .pCore_m_mobile {
      display: flex;
    }
  }
}
</style>
