<template>
  <div class="Ecommerce">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>电商解决方案</span>
          <p>
            百悟科技为电商企业提供安全，稳定的解决方案，帮助电商企业实现精准营销，促进客户增长。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/solution/ecommerce/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <PartFlexSwiper
      :pFlexSwiperData="p2Data"
      :pFlexSwiperDatas="p2Datas"
      :pFlexSwiperTitle="p2Title"
    ></PartFlexSwiper>
    <PartCore ref="Part3Core" :pCoreData="p3Data"></PartCore>
    <PartSupport :pSData="p4Data" :pSDData="p4DData"></PartSupport>
    <section class="part5">
      <span class="part-title">解决方案结构</span>
      <div class="p5_main reveal-bottom">
        <div class="p5_up">
          <span
            v-text="item.span"
            v-for="(item, i) in p5Data"
            :key="i"
            @click="p5Click(i, item)"
          ></span>
        </div>
        <div class="p5_down">
          <img :src="p5DataItem.img" alt="" style="margin: 0 auto" />
          <!-- <div class="p5_right">
            <p v-text="p5DataItem.p"></p>
            <div
              class="p5_rSpan"
              v-for="(item, i) in p5DataItem.spans"
              :key="i"
              @mousemove="p5ItemClick(i)"
            >
              <span></span>
              <p v-text="item"></p>
            </div>
          </div> -->
        </div>
      </div>
    </section>
    <PartImgSwiper
      ref="part6Img"
      :pImgData="p6Data"
      :pImgDatas="p6Datas"
    ></PartImgSwiper>
  </div>
</template>

<script>
import PartFlexSwiper from '@/components/solution/PartFlexSwiper.vue';
import PartCore from '@/components/solution/PartCore.vue';
import PartSupport from '@/components/solution/PartSupport.vue';
import PartImgSwiper from '@/components/solution/PartImgSwiper.vue';
export default {
  data() {
    return {
      // p2
      p2Data: [
        {
          img: require('../../assets/img/solution/ecommerce/part2-icon1.png'),
          imgOver: require('../../assets/img/solution/ecommerce/part2-icon1-over.png'),
          span: '流畅的购物体验',
          p: '电商网站涉及大量的静态图片需要提升用户访问电商网站时的网页速度，解决网络延时、加载过慢等问题，优化用户网上购物体验。',
        },
        {
          img: require('../../assets/img/solution/ecommerce/part2-icon2.png'),
          imgOver: require('../../assets/img/solution/ecommerce/part2-icon2-over.png'),
          span: '安全的信息沟通',
          p: '电商网站涉及咨询、下单、付款、送货、退货、退款等一系列流程，需要保障平台、商家、用户三方之间便捷的信息沟通，解决信息不畅通、虚假信息等问题，提供便捷的信息沟通平台保障。',
        },
        {
          img: require('../../assets/img/solution/ecommerce/part2-icon3.png'),
          imgOver: require('../../assets/img/solution/ecommerce/part2-icon3-over.png'),
          span: '顺畅应对业务高峰期',
          p: '电商平台的小高峰在晚间及周末，大高峰在每年几次的促销活动日，瞬间访问量会达到平常的几十甚至几百倍，需要提升平台的高峰期响应速度，解决拥堵、瘫痪等问题，保障高峰期用户的良好购物体验。',
        },
        {
          img: require('../../assets/img/solution/ecommerce/part2-icon4.png'),
          imgOver: require('../../assets/img/solution/ecommerce/part2-icon4-over.png'),
          span: '保护用户信息安全',
          p: '随着对信息安全和个人信息认证的越来越重视，电商平台上包含了用户的大量隐私信息，电商的多个环节都需要注意用户信息安全，保障用户信息不被泄露，实现安全的购物体验。',
        },
      ],
      p2Datas: [],
      p2Title: '行业需求',
      // p3
      p3Data: [
        {
          img: require('../../assets/img/solution/ecommerce/part3-icon1.png'),
          img2: require('../../assets/img/solution/ecommerce/mobile/part3-icon1.png'),
          imgOver: require('../../assets/img/solution/ecommerce/part3-icon1-over.png'),
          span: '短信通知',
          p: '及时的短信通知保障商品信息顺利到达',
        },
        {
          img: require('../../assets/img/solution/ecommerce/part3-icon2.png'),
          img2: require('../../assets/img/solution/ecommerce/mobile/part3-icon2.png'),
          imgOver: require('../../assets/img/solution/ecommerce/part3-icon2-over.png'),
          span: '短信/语音验证码',
          p: '即时短信/语音验证码保障网上购物的信息安全',
        },
        {
          img: require('../../assets/img/solution/ecommerce/part3-icon3.png'),
          img2: require('../../assets/img/solution/ecommerce/mobile/part3-icon3.png'),
          imgOver: require('../../assets/img/solution/ecommerce/part3-icon3-over.png'),
          span: '智能客服',
          p: '智能客服降低客服成本、提升在线沟通效率',
        },
        {
          img: require('../../assets/img/solution/ecommerce/part3-icon4.png'),
          img2: require('../../assets/img/solution/ecommerce/mobile/part3-icon4.png'),
          imgOver: require('../../assets/img/solution/ecommerce/part3-icon4-over.png'),
          span: '售后服务',
          p: '助力更便捷、安全的售后咨询、换货、退款等服务',
        },
        {
          img: require('../../assets/img/solution/ecommerce/part3-icon5.png'),
          img2: require('../../assets/img/solution/ecommerce/mobile/part3-icon5.png'),
          imgOver: require('../../assets/img/solution/ecommerce/part3-icon5-over.png'),
          span: '精准广告推送',
          p: '帮助电商网站实现精准的广告推送，提高成交量',
        },
        {
          img: require('../../assets/img/solution/ecommerce/part3-icon6.png'),
          img2: require('../../assets/img/solution/ecommerce/mobile/part3-icon6.png'),
          imgOver: require('../../assets/img/solution/ecommerce/part3-icon6-over.png'),
          span: '跨境信息沟通',
          p: '为跨境电商的信息沟通提供国际通信便利与保障',
        },
      ],
      // p4
      p4Data: [
        {
          title: '短信平台',
          img: require('../../assets/img/solution/ecommerce/part4-icon1.png'),
          p: '百悟科技可为电商客户个性化定制短信平台开发，采用面向对象技术（即OOA/OOD）和RUP迭代式开发，为客户提供高可用的短信发送方式',
        },
        {
          title: '私有云平台',
          img: require('../../assets/img/solution/ecommerce/part4-icon2.png'),
          p: '百悟科技可为电商客户提供私有云服务，通过私有云平台，电商客户可快速开展业务，提高营销效率，降低运营成本，为电商“新零售”赋能',
        },
        {
          title: 'CDN平台',
          img: require('../../assets/img/solution/ecommerce/part4-icon3.png'),
          p: '百悟科技可为电商客户提供CDN加速服务，通过网络的动态流量分配控制器，将电商用户请求自动指向到健康可用并距离用户最近缓存服务器上，以提高用户访问的响应速度和服务的可用性，提升购物体验。',
        },
      ],
      p4DData: [
        {
          img: require('../../assets/img/solution/ecommerce/part4-img1.png'),
          imgOver: require('../../assets/img/solution/ecommerce/part4-img1-over.png'),
          span: '经验丰富，行业领先性',
        },
        {
          img: require('../../assets/img/solution/ecommerce/part4-img2.png'),
          imgOver: require('../../assets/img/solution/ecommerce/part4-img2-over.png'),
          span: '共享服务，高度灵活性',
        },
        {
          img: require('../../assets/img/solution/ecommerce/part4-img3.png'),
          imgOver: require('../../assets/img/solution/ecommerce/part4-img3-over.png'),
          span: '业务中立不触碰数据',
        },
      ],
      // p5
      p5Data: [
        {
          span: '实时信息沟通',
          img: require('../../assets/img/solution/ecommerce/part5-icon1.png'),
          p: '百悟短信网关采用集群架构，多接收端通过LVS的DR模式负载处理各个业务线提交的短信数据，所有数据优先通过redis哨兵集群在内存快速处理为主数据库处理为辅的方式提高数据处理效率，最终信息通过不同发送端将信息路由到不同的运营商网关，实现短信息的高效分发。',
          spans: [
            '技术前瞻性',
            '功能可拓展',
            '系统安全性',
            '数据完整性',
            '易用性',
            '可定制服务',
          ],
        },
        {
          span: '网络加速支撑',
          img: require('../../assets/img/solution/ecommerce/part5-icon2.png'),
          p: '通过全球负载均衡技术(GSLB)和节点负载均衡技术将电商的终端用户访问调度到网络距离最近的健康节点上，并快速从缓存(Cache)服务器直接响应访问内容，以达到电商页面类业务内容加速的目的，提高客户的终端用户的访问速度，增强终端用户的访问体验效果。',
          spans: [
            '负载均衡系统',
            '本地缓存服务器',
            '节点负载均衡',
            '中心父节点',
          ],
        },
      ],
      p5DataItem: {},
      // p6
      p6Data: [
        {
          img: require('../../assets/img/solution/ecommerce/part6-icon1.png'),
          imgOver: require('../../assets/img/solution/ecommerce/part6-icon1-over.png'),
        },
        {
          img: require('../../assets/img/solution/ecommerce/part6-icon2.png'),
          imgOver: require('../../assets/img/solution/ecommerce/part6-icon2-over.png'),
        },
        {
          img: require('../../assets/img/solution/ecommerce/part6-icon3.png'),
          imgOver: require('../../assets/img/solution/ecommerce/part6-icon3-over.png'),
        },
      ],
      p6Datas: [],
    };
  },
  components: {
    PartFlexSwiper,
    PartCore,
    PartSupport,
    PartImgSwiper,
  },
  mounted() {
    // 数据初始化
    this.p2Datas = [
      JSON.parse(JSON.stringify(this.p2Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p2Data.slice(-2))),
    ];
    this.p6Datas = [
      JSON.parse(JSON.stringify(this.p6Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p6Data.slice(-1))),
    ];
    // 状态初始化
    this.start();
  },
  methods: {
    // 初始化
    start() {
      this.$refs.Part3Core.pCoreMove(0, this.p3Data[0].imgOver);
      this.$refs.Part3Core.pCoreClick(0, this.p3Data[0].img2, this.p3Data[0]);
      this.p5Click(0, this.p5Data[0]);
      setTimeout(() => {
        this.p5ItemClick(0);
      }, 100);
      this.$refs.part6Img.pImgClick(0, this.p6Data[0].imgOver);
    },
    p5Click(i, item) {
      this.p5DataItem = item;
      $('.p5_up span').removeClass('active');
      $('.p5_up span').eq(i).addClass('active');
    },
    p5ItemClick(i) {
      $('.p5_rSpan').removeClass('active');
      $('.p5_rSpan').eq(i).addClass('active');
    },
    p6Click(i, img) {
      $('.p6_item').each((index, item) => {
        $(item).find('img').attr('src', this.p6Data[index].img);
      });
      $('.p6_item').removeClass('active');
      $('.p6_item').eq(i).find('img').attr('src', img);
    },
  },
};
</script>

<style lang="less" scoped>
/* 第一部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 723px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      position: absolute;
      width: 830px;
      height: 674px;
      z-index: -1;
      top: 49px;
      right: -269px;
    }
  }
  .part1-title {
    width: 538px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 67px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
.part5 {
  width: 100%;
  padding-top: 79px;
  padding-bottom: 128px;
  background: url('../../assets/img/solution/ecommerce/part5-bg.png') no-repeat;
  background-size: 100% 100%;
  .part-title {
    color: #ffffff;
    margin-bottom: 66px;
  }
  .p5_main {
    width: 1113px;
    margin: 0 auto;
    height: 718px;
    background: rgba(241, 241, 241, 0.92);
    border-radius: 7px;
    .p5_up {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      height: 109px;
      padding: 0px 160px;
      span {
        font-size: 24px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
        position: relative;
      }
      span::after {
        display: block;
        content: '';
        position: absolute;
        top: 10px;
        left: -26px;
        width: 10px;
        height: 10px;
        background: #0b63bb;
        border-radius: 50%;
        display: none;
      }
      span.active {
        color: #0b63bb;
      }
      span.active::after {
        display: block;
      }
    }
    .p5_down {
      box-sizing: border-box;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      > img {
        width: 671px;
        height: 577px;
        border-radius: 10px;
        overflow: hidden;
      }
      .p5_right {
        box-sizing: border-box;
        width: 409px;
        height: 577px;
        border: 2px solid #e4e6e9;
        border-radius: 7px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 53px;
        > p {
          display: block;
          width: 322px;
          font-size: 16px;
          line-height: 27px;
          font-weight: 400;
          color: #333333;
          text-align: justify;
          margin-bottom: 33px;
        }
        .p5_rSpan {
          width: 322px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 8px;
          cursor: pointer;
          span {
            width: 5px;
            height: 5px;
            background: #000000;
            border-radius: 50%;
            margin-right: 21px;
          }
          p {
            font-size: 24px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #333333;
          }
        }
        .p5_rSpan.active {
          span,
          p {
            color: #005cb9;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  /* 第一部分 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        content: url('../../assets/img/solution/ecommerce/mobile/part1-icon.png');
        width: 4.89rem;
        height: 4.1rem;
        top: 0.18rem;
        right: -0.66rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0px;
        margin-top: -0.62rem;
      }
      p,
      a {
        display: none;
      }
    }
  }
  .part5 {
    padding-top: 0.58rem;
    padding-bottom: 1.04rem;
    background: url('../../assets/img/solution/ecommerce/mobile/part5-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part-title {
      margin-bottom: 0.69rem;
    }
    .p5_main {
      width: 7.09rem;
      height: auto;
      padding-bottom: 0.37rem;
      border-radius: 0.07rem;
      .p5_up {
        height: 0.89rem;
        padding: 0px 0.85rem;
        span {
          font-size: 0.24rem;
        }
        span::after {
          top: 0.1rem;
          left: -0.26rem;
          width: 0.1rem;
          height: 0.1rem;
        }
      }
      .p5_down {
        flex-direction: column;
        align-items: center;
        > img {
          width: 6.47rem;
          height: 5.77rem;
          margin-bottom: 0.21rem;
        }
        .p5_right {
          width: 6.47rem;
          height: 4.2rem;
          border: 0px solid #e4e6e9;
          border-radius: 0.09rem;
          padding-top: 0.22rem;
          > p {
            width: 5.3rem;
            font-size: 0.16rem;
            line-height: 0.27rem;
            margin-bottom: 0.33rem;
          }
          .p5_rSpan {
            width: 5.3rem;
            margin-bottom: 0.08rem;
            span {
              width: 0.05rem;
              height: 0.05rem;
              margin-right: 0.21rem;
            }
            p {
              font-size: 0.24rem;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.Ecommerce {
  .partSupport .pS_main .pS_dTwo .pS_dTItem span {
    text-align: center;
  }
  .partFlexSwiper {
    background: url('../../assets/img/solution/transportation/part2-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part-title {
      margin-bottom: 78px;
    }
    .partFlexSwiper-item {
      width: 256px !important;
      height: 458px !important;
      padding-top: 46px !important;
      > div {
        height: 86px !important;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .Ecommerce {
    .partFlexSwiper {
      background: url('../../assets/img/solution/transportation/mobile/part2-bg.png')
        no-repeat;
      background-size: 100% 100%;
      .part-title {
        margin-bottom: 0.78rem;
      }
      .partFlexSwiper-item {
        width: 2.56rem !important;
        height: 4.58rem !important;
        padding-top: 0.46rem !important;
        > div {
          height: 0.86rem !important;
        }
      }
    }
  }
}
</style>
