<template>
  <div class="videoSMS">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>视频短信</span>
          <p>
            视频短信实现短彩信形态升级，是基于短信行业网关wappush能力向个人用户下发包含音、视频等富媒体内容的新消息形态。视频短信具有点对点应用表达准确、内容短小、互动性强、信息量丰富等优势特征，大大提高了企业与客户沟通的质量和效率，也成为了当下企业开拓市场、加强与客户沟通交流的有效途径之一。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/videoSMS/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <div class="part2_main">
        <span class="part-title">视频短信功能介绍</span>
        <p class="reveal-bottom3">
          视频短信是可以发送带视频内容的短消息，视频短信相对于普通短/彩信来讲容量更大，可以发送文本、图片、链接、视频、音频等内容，具有比短/彩信更加有效的宣传效果。
        </p>
        <div class="p2m-major p2mm-pc1">
          <div class="p2m-major-left reveal-left">
            <div
              class="p2m-major-item"
              v-for="(item, i) in p2data"
              :key="i"
              @click="p2textOver(i)"
            >
              <span>{{ item.lTitle }}</span>
              <p>{{ item.lContent }}</p>
            </div>
          </div>
          <div class="p2m-major-right reveal-right">
            <img
              src="../../assets/img/product/videoSMS/part2-right-img.png"
              alt=""
            />
            <span v-for="(item, i) in p2data" :key="i" @click="p2textOver(i)">{{
              item.rTitle
            }}</span>
          </div>
        </div>
        <div class="p2m-major p2mm-pc2">
          <div class="p2m-major-right reveal-right">
            <img
              src="../../assets/img/product/videoSMS/part2-right-img.png"
              alt=""
            />
            <span v-for="(item, i) in p2data" :key="i" @click="p2mmRClick(i)">{{
              item.rTitle
            }}</span>
          </div>
          <div class="p2m-major-left reveal-left">
            <el-carousel
              trigger="click"
              :interval="5000"
              arrow="never"
              @change="p2mmlMobile"
              ref="p2mmLeftcarousel"
            >
              <el-carousel-item
                v-for="(item, i) in p2data"
                :key="i"
                class="p2m-major-item"
              >
                <span>{{ item.lTitle }}</span>
                <p>{{ item.lContent }}</p>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </section>
    <section class="part3">
      <div class="part3_main">
        <span class="part-title">视频短信功能优势</span>
        <div class="p3m-major reveal-bottom2">
          <div
            class="p3m-major-item"
            v-for="(item, i) in p3data"
            :key="i"
            @mouseover="p3Action(i, item.iconOver)"
            @mouseout="p3Action(i, item.icon)"
          >
            <img :src="item.icon" alt="" />
            <span>{{ item.title }}</span>
            <p>{{ item.content }}</p>
          </div>
        </div>
        <div class="p3m-major">
          <swiper :options="swiperOption">
            <swiper-slide
              v-for="(item, i) in p3datas"
              :key="i"
              class="swiper-item"
            >
              <div
                class="p3m-major-item"
                v-for="(item2, i2) in item"
                :key="i2"
                @click="clkItem($event, item2)"
              >
                <img :src="item2.icon" alt="" />
                <span>{{ item2.title }}</span>
                <p>{{ item2.content }}</p>
              </div>
            </swiper-slide>
          </swiper>
          <div
            class="swiper-pagination p3m-pagination swiper_bg_blue"
            slot="pagination"
          ></div>
        </div>
      </div>
    </section>
    <section class="part4">
      <div class="part4_main">
        <span class="part-title">视频短信场景应用</span>
        <div class="p4m-major">
          <img
            src="../../assets/img/product/videoSMS/part4-img-bg.png"
            alt=""
          />
          <div class="p4m-major-top reveal-bottom2">
            <div class="p4m-major-topLine"></div>
            <div
              class="p4m-major-topItem"
              v-for="(item, i) in p4data"
              :key="i"
              @click="p4TopAction(i, item.topIconOver)"
            >
              <img :src="item.topIcon" alt="" />
              <span>{{ item.topText }}</span>
            </div>
          </div>
          <div class="p4m-major-bottom reveal-bottom3">
            <div
              class="p4m-major-bottomItem"
              v-for="(item, i) in p4data"
              :key="i"
            >
              <span>{{ item.bottomTitle }}</span>
              <div class="p4m-major-bottomLeft">
                <img :src="item.bottomImg1" alt="" />
                <img :src="item.bottomImg2" alt="" />
              </div>
              <div class="p4m-major-bottomRight">
                <div class="p4m-firt-div">
                  <span>{{ item.bottomTitle }}</span>
                  <p v-for="(item2, i2) in item.bottomContent" :key="i2">
                    {{ item2 }}
                  </p>
                </div>
                <div class="p4m-last-div">
                  <div>
                    <span v-for="(item2, i2) in item.bottomSpan" :key="i2">
                      {{ item2 }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'VideoSMS',
  data() {
    return {
      flag: true,
      p2data: [
        {
          lTitle: '签名',
          lContent: '支持插入定制的企业签名，如【百悟科技】',
          rTitle: '企业签名',
        },
        {
          lTitle: '图片',
          lContent: '支持jpg/png/gif格式，每条最多可承载20张高清图片',
          rTitle: '图片内容',
        },
        { lTitle: '音频', lContent: '支持mp3格式', rTitle: '音频内容' },
        {
          lTitle: '文本',
          lContent: '支持插入普通和带特殊标点符号的文本内容',
          rTitle: '文本内容',
        },
        {
          lTitle: '链接',
          lContent: '支持插入链接，点击跳转至指定网页',
          rTitle: '链接内容',
        },
        {
          lTitle: '视频',
          lContent:
            '支持mp4/3gp格式的内容，不超过1.9M，10秒-30秒帧总数不可超过15帧',
          rTitle: '视频内容',
        },
      ],
      p3data: [
        {
          title: '内容形态多样化',
          content:
            '打破传统短信文本单一的形式，支持文本、图片、视频等多媒体信息发送通，满足客户不同内容维度需求，提高用户点击率',
          icon: require('../../assets/img/product/videoSMS/part3-icon1.png'),
          iconOver: require('../../assets/img/product/videoSMS/part3-icon1-over.png'),
        },
        {
          title: '终端全面覆盖',
          content:
            '无需预装SDK或APP，无操作系统限制，智能终端直接一对一精准展示',
          icon: require('../../assets/img/product/videoSMS/part3-icon2.png'),
          iconOver: require('../../assets/img/product/videoSMS/part3-icon2-over.png'),
        },
        {
          title: '实现流量减免',
          content:
            '联网流量由企业买单，个人用户接收不产生流量资费，有效提升用户体验',
          icon: require('../../assets/img/product/videoSMS/part3-icon3.png'),
          iconOver: require('../../assets/img/product/videoSMS/part3-icon3-over.png'),
        },
        {
          title: '运营商直审信任度高',
          content:
            '短信链接与内容经过运营商直审才能通过，信任度高，高点击率低投诉率',
          icon: require('../../assets/img/product/videoSMS/part3-icon4.png'),
          iconOver: require('../../assets/img/product/videoSMS/part3-icon4-over.png'),
        },
        {
          title: '特殊协议不易拦截',
          content:
            '手机终端或者手机管家及运营商极少会进行拦截，从而影响信息的触达率',
          icon: require('../../assets/img/product/videoSMS/part3-icon5.png'),
          iconOver: require('../../assets/img/product/videoSMS/part3-icon5-over.png'),
        },
      ],
      p4data: [
        {
          topIcon: require('../../assets/img/product/videoSMS/part4-icon1.png'),
          topIconOver: require('../../assets/img/product/videoSMS/part4-icon1-over.png'),
          topText: '影音娱乐',
          bottomImg1: require('../../assets/img/product/videoSMS/part4-img1-1.png'),
          bottomImg2: require('../../assets/img/product/videoSMS/part4-img1-2.png'),
          bottomTitle:
            '适用于影音行业、娱乐行业，新电影发布、新动态视频的宣传推广等。',
          bottomContent: [
            '与视频、媒体、影院合作，可发新片预告、片花，或电影优惠视频短信等，增大产品黏性、扩大收视率；',
            '向老会员发送新片预告，养成定时阅读、定时看电影的习惯、维护忠实的用户群，增大收益；',
            '多用于短视频宣传、动漫产品、艺术宣传、媒体及阅读的娱乐推广；',
          ],
          bottomSpan: ['影视推广', '会员预告', '活动宣传'],
        },
        {
          topIcon: require('../../assets/img/product/videoSMS/part4-icon2.png'),
          topIconOver: require('../../assets/img/product/videoSMS/part4-icon2-over.png'),
          topText: '游戏休闲',
          bottomImg1: require('../../assets/img/product/videoSMS/part4-img2-1.png'),
          bottomImg2: require('../../assets/img/product/videoSMS/part4-img2-2.png'),
          bottomTitle:
            '适用于游戏、休闲相关行业，新游戏的发布上线、运营推广，娱乐视频的宣传推广等。',
          bottomContent: [
            '可用于向注册用户宣传游戏的新版本发布、新皮肤优惠活动等，促进用户活跃度，增大平台黏性；',
            '游戏视频直冲眼球，时长吊足用户胃口，增强宣传效果；',
            '多用于游戏类的宣传，包括大型手游、端游、页游和休闲类的游戏推广；',
            '非传奇类诱导充值游戏，游戏大厂出品，有正规游戏版号，应用商城或者网站评价良好；',
          ],
          bottomSpan: ['手游发布', '游戏版本更新', '休闲游戏推广'],
        },
        {
          topIcon: require('../../assets/img/product/videoSMS/part4-icon3.png'),
          topIconOver: require('../../assets/img/product/videoSMS/part4-icon3-over.png'),
          topText: '电商促销',
          bottomImg1: require('../../assets/img/product/videoSMS/part4-img3-1.png'),
          bottomImg2: require('../../assets/img/product/videoSMS/part4-img3-2.png'),
          bottomTitle:
            '适应于电商行业活动节日促销、品牌推广、会员关怀等信息发送。',
          bottomContent: [
            '美妆、服装等电商、大型商超 618 双11等营销节日；',
            '采用图片加文字的形式来做电商平台节日宣传，视频短信的大容量可展示更多信息；',
            '一条视频短信可以承载多张海报大图，活动视频及活动链接入口，让用户更全面了解节日促销活动信息；',
          ],
          bottomSpan: ['节日促销', '会员优惠', '新品发布'],
        },
        {
          topIcon: require('../../assets/img/product/videoSMS/part4-icon4.png'),
          topIconOver: require('../../assets/img/product/videoSMS/part4-icon4-over.png'),
          topText: '政企金融',
          bottomImg1: require('../../assets/img/product/videoSMS/part4-img4-1.png'),
          bottomImg2: require('../../assets/img/product/videoSMS/part4-img4-2.png'),
          bottomTitle:
            '为政府、企事业单位提供品牌宣传、文化传播，可结合我省旅游行业、党政建设等行业需求开展业务推广；适应于银行保险行业活动促销、存储理财动账提醒、信用卡优惠等信息发送。',
          bottomContent: [
            '理财、存储、保单到期续约提醒、信用卡优惠信息、活动推广',
            '党建活动宣传、廉政工作建设、文化传播、工会活动、旅游宣传推广',
          ],
          bottomSpan: ['金融行业推广', '政企建设宣传', '文化传播'],
        },
      ],
      p3datas: [],
      // 轮播图参数
      swiperOption: {
        pagination: {
          el: '.p3m-major .p3m-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false, // 手动切换之后继续自动轮播
        // },
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
    };
  },
  mounted() {
    $('.p4m-major-topItem').eq(0).addClass('active');
    $('.p4m-major-topItem')
      .eq(0)
      .find('img')
      .attr('src', this.p4data[0].topIconOver);
    $('.p4m-major-bottomItem').eq(0).css('display', 'flex');
    // 内容初始化
    this.p3datas = [
      JSON.parse(JSON.stringify(this.p3data.slice(0, 3))),
      JSON.parse(JSON.stringify(this.p3data.slice(-2))),
    ];
    const that = this;
    // 内容随着屏幕变化
    $(window).resize(function () {
      that.p4TopAction(0);
    });
  },
  methods: {
    p2textOver(i) {
      $('.p2m-major-right span').removeClass('active');
      $('.p2m-major-item').removeClass('active');
      $('.p2m-major-right span').eq(i).toggleClass('active');
      $('.p2m-major-item').eq(i).toggleClass('active');
    },
    p3Action(i, icon) {
      $('.p3m-major-item').eq(i).toggleClass('active');
      $('.p3m-major-item').eq(i).find('img').attr('src', icon);
    },
    // p3点击方法
    clkItem(e, item) {
      $('.p3m-major-item').each((index, item2) => {
        this.p3data.find((item3) => {
          if (item3.title == $(item2).children('span').text()) {
            $(item2).children('img').attr('src', item3.icon);
          }
        });
      });
      $('.p3m-major-item').removeClass('active');
      $(e.currentTarget).addClass('active');
      $(e.currentTarget).find('img').attr('src', item.iconOver);
    },
    p4TopAction(i, iconOver) {
      let obj = {};
      if (iconOver == undefined) {
        iconOver = this.p4data[0].topIconOver;
      }
      if ($(window).width() > 768) {
        obj.gap = 50;
        obj.width = 285;
        obj.unit = 'px';
        obj.display = 'flex';
      } else {
        obj.gap = 0.1;
        obj.width = 1.62;
        obj.unit = 'rem';
        obj.display = 'block';
      }
      $('.p4m-major-topLine')
        .stop()
        .animate({ left: obj.gap + i * obj.width + obj.unit }, 200);
      // 初始化
      for (let index = 0; index < this.p4data.length; index++) {
        $('.p4m-major-topItem')
          .eq(index)
          .find('img')
          .attr('src', this.p4data[index].topIcon);
      }
      $('.p4m-major-bottomItem').css('display', 'none');
      $('.p4m-major-topItem').removeClass('active');
      // 设置效果
      $('.p4m-major-topItem').eq(i).toggleClass('active');
      $('.p4m-major-topItem').eq(i).find('img').attr('src', iconOver);
      $('.p4m-major-bottomItem').eq(i).css('display', obj.display);
    },
    // mobile
    p2mmRClick(i) {
      $('.p2mm-pc2 .p2m-major-right span').removeClass('active');
      $('.p2mm-pc2 .p2m-major-right span').eq(i).toggleClass('active');
      this.$refs.p2mmLeftcarousel.setActiveItem(i);
    },
    p2mmlMobile(val) {
      $('.p2mm-pc2 .p2m-major-right span').removeClass('active');
      $('.p2mm-pc2 .p2m-major-right span').eq(val).toggleClass('active');
    },
  },
};
</script>

<style lang="less" scoped>
/* 第一个部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 723px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      width: 665px;
      height: 704px;
      margin-right: -171px;
      margin-top: 49px;
    }
    .part1-title {
      width: 538px;
      height: 280px;
      span {
        font-family: FontExcellent;
        font-size: 40px;
        color: #333333;
        display: block;
        margin-bottom: 22px;
        letter-spacing: 1px;
      }
      p {
        font-size: 16px;
        color: #666666;
        text-align: justify;
        margin-bottom: 78px;
        line-height: 28px;
      }
      a {
        display: block;
        width: 184px;
        height: 53px;
        background-color: rgb(36, 110, 187);
        border-radius: 7px;
        text-align: center;
        line-height: 53px;
        font-size: 16px;
        color: #f9fbfd;
        letter-spacing: 0.5px;
      }
    }
  }
}
/* 第二个部分 */
.part2 {
  box-sizing: border-box;
  width: 100%;
  height: 997px;
  background: linear-gradient(-88deg, #ffffff 0%, #fafbfd 52%, #ffffff 100%);
  .part2_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    padding-top: 71px;
    > span {
      margin-bottom: 47px;
    }
    > p {
      display: block;
      width: 1110px;
      font-size: 18px;
      line-height: 25px;
      font-weight: 400;
      color: #333333;
      margin-bottom: 110px;
    }
    .p2m-major {
      width: 1100px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .p2m-major-left {
        box-sizing: border-box;
        width: 575px;
        height: 555px;
        padding-top: 58px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .p2m-major-item {
          box-sizing: border-box;
          width: 575px;
          height: 77px;
          padding-left: 22px;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          span {
            font-family: FontExcellent;
            font-size: 20px;
            font-weight: 400;
            color: #333333;
            margin-top: 8px;
            margin-bottom: 0px;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
          }
        }
        .p2m-major-item.active {
          background: linear-gradient(0deg, #ffffff 0%, #f6f9ff 100%);
        }
        .p2m-major-item.active span {
          color: #3176c3;
        }
      }
      .p2m-major-right {
        width: 414px;
        height: 555px;
        position: relative;
        img {
          width: 265px;
          height: 555px;
          position: absolute;
          top: 0;
          right: 0;
        }
        span {
          display: block;
          width: 117px;
          height: 37px;
          background: linear-gradient(-46deg, #3f5164 0%, #47596c 100%);
          border-radius: 5px;
          font-size: 18px;
          font-family: FontExcellent;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 37px;
          position: absolute;
        }
        span::after {
          display: block;
          content: '';
          width: 43px;
          height: 2px;
          background-color: rgb(65, 83, 102);
          margin-top: -20px;
          margin-left: 117px;
        }
        span:nth-of-type(5)::after {
          width: 115px;
        }
        span:nth-of-type(1) {
          top: 123px;
          left: 13px;
        }
        span:nth-of-type(2) {
          top: 180px;
          left: 13px;
        }
        span:nth-of-type(3) {
          top: 431px;
          left: 13px;
        }
        span:nth-of-type(4) {
          top: 268px;
          left: 13px;
        }
        span:nth-of-type(5) {
          top: 323px;
          left: 13px;
        }
        span:nth-of-type(6) {
          top: 380px;
          left: 13px;
        }
        span.active {
          left: 0px;
          width: 130px;
          height: 42px;
          background: linear-gradient(-46deg, #0c63bb 0%, #6392cd 100%);
          margin-top: -3px;
          line-height: 42px;
        }
        span.active::after {
          margin-top: -22px;
          margin-left: 130px;
        }
      }
    }
    .p2mm-pc2 {
      display: none;
    }
  }
}
.p2m-major-item {
  cursor: pointer;
}
.p2m-major-right span {
  cursor: pointer;
}
/* 第三个部分 */
.part3 {
  box-sizing: border-box;
  width: 100%;
  height: 768px;
  background: url('../../assets/img/product/videoSMS/part3-bg.png') no-repeat;
  background-size: 100% 100%;
  .part3_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    padding-top: 111px;
    > span {
      margin-bottom: 93px;
      color: #ffffff;
    }
    .p3m-major {
      width: 1100px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .p3m-major-item {
        box-sizing: border-box;
        width: 204px;
        height: 400px;
        background: #ffffff;
        box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.1);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          font-size: 18px;
          font-family: FontExcellent;
          font-weight: 400;
          color: #333333;
          margin-bottom: 18px;
        }
        p {
          display: block;
          width: 138px;
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          text-align: justify;
          line-height: 28px;
        }
      }
      .p3m-major-item.active {
        background: linear-gradient(0deg, #0c63bb 0%, #6392cd 100%);
        box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.1);
        span {
          color: #ffffff;
        }
        p {
          color: #ffffff;
        }
      }
      .p3m-major-item:nth-of-type(1) img {
        width: 91px;
        height: 99px;
        margin-top: 26px;
        margin-bottom: 18px;
      }
      .p3m-major-item:nth-of-type(2) img {
        width: 75px;
        height: 85px;
        margin-top: 31px;
        margin-bottom: 27px;
      }
      .p3m-major-item:nth-of-type(3) img {
        width: 80px;
        height: 80px;
        margin-top: 36px;
        margin-bottom: 27px;
      }
      .p3m-major-item:nth-of-type(4) img {
        width: 86px;
        height: 91px;
        margin-top: 28px;
        margin-bottom: 24px;
      }
      .p3m-major-item:nth-of-type(5) img {
        width: 83px;
        height: 78px;
        margin-top: 41px;
        margin-bottom: 24px;
      }
    }
    .p3m-major:nth-of-type(2) {
      display: none;
    }
  }
}
/* 第四个部分 */
.part4 {
  box-sizing: border-box;
  width: 100%;
  height: 1174px;
  background: linear-gradient(-88deg, #ffffff 0%, #fafbfd 52%, #ffffff 100%);
  .part4_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    padding-top: 133px;
    > span {
      margin-bottom: 103px;
    }
    .p4m-major {
      position: relative;
      width: 1100px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      > img {
        position: absolute;
        top: 220px;
        right: -410px;
        z-index: 1;
        width: 510px;
        height: 571px;
      }
      .p4m-major-top {
        box-sizing: border-box;
        width: 100%;
        height: 56px;
        padding: 0px 50px;
        border-bottom: 1px solid #cecece;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-bottom: 60px;
        .p4m-major-topLine {
          width: 146px;
          height: 1px;
          position: absolute;
          bottom: 0;
          left: 50px;
          background: #1266bc;
        }
        .p4m-major-topItem {
          width: 146px;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          img {
            margin-right: 10px;
          }
          span {
            font-size: 18px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #333333;
          }
        }
        .p4m-major-topItem.active span {
          color: #1267bc;
        }
      }
      .p4m-major-bottom {
        width: 1112px;
        height: 597px;
        z-index: 2;
        .p4m-major-bottomItem {
          box-sizing: border-box;
          width: 1112px;
          height: 597px;
          padding-top: 76px;
          padding-left: 42px;
          padding-right: 62px;
          background: #ffffff;
          box-shadow: 35px 38px 40px 0px rgba(134, 134, 134, 0.04);
          border-radius: 15px;
          display: none;
          flex-direction: row;
          justify-content: space-between;
          > span {
            display: none;
          }
          .p4m-major-bottomLeft {
            width: 490px;
            height: 480px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            img:first-child {
              width: 229px;
              height: 457px;
            }
            img:last-child {
              width: 250px;
              height: 464px;
            }
          }
          .p4m-major-bottomRight {
            box-sizing: border-box;
            width: 490px;
            height: 430px;
            padding-top: 11px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .p4m-firt-div {
              padding-left: 25px;
              display: flex;
              flex-direction: column;
              > span {
                display: block;
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                margin-bottom: 64px;
                letter-spacing: -0.2px;
                margin-left: -25px;
              }
              p {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                line-height: 26px;
                margin-bottom: 4px;
                position: relative;
              }
              p::after {
                display: block;
                content: '';
                width: 9px;
                height: 9px;
                background: linear-gradient(-40deg, #0c63bb 0%, #6392cd 100%);
                border-radius: 50%;
                position: absolute;
                top: 9px;
                left: -25px;
              }
            }
            .p4m-last-div {
              width: 100%;
              display: flex;
              flex-direction: column;
              div {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 32px;
              }
              span {
                display: block;
                width: 146px;
                height: 46px;
                background: linear-gradient(267deg, #0c63bb 0%, #6392cd 100%);
                border-radius: 7px;
                text-align: center;
                line-height: 46px;
                font-size: 16px;
                font-weight: 400;
                color: #ffffff;
              }
              > a {
                font-size: 14px;
                font-family: FontExcellent;
                font-weight: 400;
                color: #3e7ec6;
                margin-left: 8px;
                :hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
.p4m-major-topItem {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  /* 第一个部分 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.6rem;
      > img {
        width: 3.82rem;
        height: 4.04rem;
        margin-right: 0px;
        margin-top: 0.04rem;
      }
      .part1-title {
        width: auto;
        height: auto;
        margin-left: 0.22rem;
        span {
          font-size: 0.4rem;
          font-weight: 400;
          margin-bottom: 0rem;
        }
        p,
        a {
          display: none;
        }
      }
    }
  }
  /* 标题样式 */
  .part-title {
    font-size: 0.3rem;
    margin-bottom: 0rem;
  }
  /* 第二个部分 */
  .part2 {
    height: 11.26rem;
    .part2_main {
      width: 6.15rem;
      padding-top: 0.34rem;
      > span {
        margin-bottom: 0.47rem;
      }
      > p {
        width: 100%;
        font-size: 0.18rem;
        line-height: 0.27rem;
        margin-bottom: 0.45rem;
        text-align: justify;
      }
      .p2m-major {
        width: 100%;
        display: block;
        .p2m-major-left {
          box-sizing: border-box;
          width: 6.12rem;
          margin: 0 auto;
          height: auto;
          padding-top: 0.67rem;
          display: block;
          .p2m-major-item {
            box-sizing: border-box;
            width: 6.12rem;
            padding-left: 0px;
            padding-bottom: 0px;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            align-items: center;
            span {
              display: block;
              width: 5.32rem;
              font-size: 0.2rem;
              color: #3176c3;
              margin-top: 0px;
              margin-bottom: 0.1rem;
            }
            p {
              width: 5.32rem;
              font-size: 0.16rem;
              line-height: 0.24rem;
            }
          }
        }
        .p2m-major-right {
          width: 4.14rem;
          height: 5.55rem;
          margin-left: 0.61rem;
          position: relative;
          img {
            width: 2.65rem;
            height: 5.55rem;
            position: absolute;
            top: 0;
            right: 0;
          }
          span {
            width: 1.17rem;
            height: 0.37rem;
            border-radius: 0.05rem;
            font-size: 0.18rem;
            line-height: 0.37rem;
          }
          span::after {
            width: 0.43rem;
            height: 0.02rem;
            margin-top: -0.2rem;
            margin-left: 1.17rem;
          }
          span:nth-of-type(5)::after {
            width: 1.15rem;
          }
          span:nth-of-type(1) {
            top: 1.23rem;
            left: 0.13rem;
          }
          span:nth-of-type(2) {
            top: 1.8rem;
            left: 0.13rem;
          }
          span:nth-of-type(3) {
            top: 4.31rem;
            left: 0.13rem;
          }
          span:nth-of-type(4) {
            top: 2.68rem;
            left: 0.13rem;
          }
          span:nth-of-type(5) {
            top: 3.23rem;
            left: 0.13rem;
          }
          span:nth-of-type(6) {
            top: 3.8rem;
            left: 0.13rem;
          }
          span.active {
            left: 0px;
            width: 1.3rem;
            height: 0.42rem;
            background: linear-gradient(-46deg, #0c63bb 0%, #6392cd 100%);
            margin-top: -0.03rem;
            line-height: 0.42rem;
          }
          span.active::after {
            margin-top: -0.22rem;
            margin-left: 1.3rem;
          }
        }
      }
      .p2mm-pc1 {
        display: none;
      }
      .p2mm-pc2 {
        display: block;
      }
    }
  }
  /* 第三个部分 */
  .part3 {
    height: 6.87rem;
    background: url('../../assets/img/product/videoSMS/mobile/part3-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part3_main {
      width: 6.8rem;
      padding-top: 0.57rem;
      > span {
        margin-bottom: 0.67rem;
        color: white;
      }
      .p3m-major {
        width: 100%;
        position: relative;
        .swiper-item {
          width: 6.6rem !important;
          padding: 0px 0.1rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .swiper-item:last-child {
          justify-content: flex-start;
          .p3m-major-item {
            margin-right: 0.25rem;
          }
        }
        .p3m-major-item {
          width: 2.04rem;
          height: 4rem;
          box-shadow: 0px 0.3rem 0.21rem 0px rgba(196, 197, 199, 0.1);
          border-radius: 0.1rem;
          span {
            font-size: 0.18rem;
            font-family: FontExcellent;
            font-weight: 400;
            color: #333333;
            margin-bottom: 0.18rem;
          }
          p {
            width: 1.38rem;
            font-size: 0.16rem;
            line-height: 0.24rem;
          }
        }
        .p3m-major-item.active {
          box-sizing: border-box;
          background: linear-gradient(0deg, #0c63bb 0%, #6392cd 100%);
          border: 0.02rem solid #ffffff;
          box-shadow: 0px 0.3rem 0.21rem 0px rgba(196, 197, 199, 0.1);
          span {
            color: #ffffff;
          }
          p {
            color: #ffffff;
          }
        }
        .p3m-major-item:nth-of-type(1) img {
          width: 0.91rem;
          height: 0.99rem;
          margin-top: 0.26rem;
          margin-bottom: 0.18rem;
        }
        .p3m-major-item:nth-of-type(2) img {
          width: 0.75rem;
          height: 0.85rem;
          margin-top: 0.31rem;
          margin-bottom: 0.27rem;
        }
        .p3m-major-item:nth-of-type(3) img {
          width: 0.8rem;
          height: 0.8rem;
          margin-top: 0.36rem;
          margin-bottom: 0.27rem;
        }
        .p3m-major-item:nth-of-type(4) img {
          width: 0.86rem;
          height: 0.91rem;
          margin-top: 0.28rem;
          margin-bottom: 0.24rem;
        }
        .p3m-major-item:nth-of-type(5) img {
          width: 0.83rem;
          height: 0.78rem;
          margin-top: 0.41rem;
          margin-bottom: 0.24rem;
        }
      }
      .p3m-major:nth-of-type(1) {
        display: none;
      }
      .p3m-major:nth-of-type(2) {
        display: block;
      }
    }
  }
  /* 第四个部分 */
  .part4 {
    height: 13.37rem;
    .part4_main {
      width: 6.5rem;
      padding-top: 0.64rem;
      .part-title {
        margin-bottom: 0.45rem;
      }
      .p4m-major {
        width: 100%;
        > img {
          display: none;
        }
        .p4m-major-top {
          width: 100%;
          height: 0.85rem;
          padding: 0px 0.1rem;
          margin-bottom: 0.43rem;
          .p4m-major-topLine {
            width: 1.46rem;
            left: 0.1rem;
          }
          .p4m-major-topItem {
            width: 1.46rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            img {
              max-height: 0.3rem;
              margin-right: 0rem;
              margin-bottom: 0.19rem;
            }
            span {
              font-size: 0.18rem;
            }
          }
          .p4m-major-topItem.active span {
            color: #1267bc;
          }
        }
        .p4m-major-bottom {
          width: 100%;
          height: auto;
          .p4m-major-bottomItem {
            width: 100%;
            height: auto;
            padding-top: 0px;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            box-shadow: 35px 38px 40px 0px rgba(134, 134, 134, 0);
            border-radius: 0px;
            display: none;
            flex-direction: column;
            justify-content: flex-start;
            > span {
              display: block;
              width: 5.29rem;
              margin: 0 auto;
              font-size: 0.18rem;
              font-weight: 400;
              color: #333333;
              margin-bottom: 0.32rem;
            }
            .p4m-major-bottomLeft {
              width: 4.8rem;
              height: auto;
              margin: 0 auto;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              margin-bottom: 0.79rem;
              img:first-child {
                width: 2.29rem;
                height: 4.57rem;
              }
              img:last-child {
                width: 2.5rem;
                height: 4.64rem;
              }
            }
            .p4m-major-bottomRight {
              box-sizing: border-box;
              width: 5.5rem;
              height: auto;
              padding-top: 0px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .p4m-firt-div {
                height: 2rem;
                padding-left: 0px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-bottom: 0.61rem;
                > span {
                  display: none;
                }
                p {
                  font-size: 0.16rem;
                  line-height: 0.24rem;
                  margin-bottom: 0.04rem;
                }
                p::after {
                  display: block;
                  content: '';
                  width: 0.09rem;
                  height: 0.09rem;
                  top: 0.09rem;
                  left: -0.25rem;
                }
              }
              .p4m-last-div {
                width: calc(100% + 0.24rem);
                margin-left: -0.24rem;
                div {
                  width: 100%;
                  margin-bottom: 0.32rem;
                }
                span {
                  display: block;
                  width: 1.46rem;
                  height: 0.46rem;
                  background: linear-gradient(267deg, #0c63bb 0%, #6392cd 100%);
                  border-radius: 0.07rem;
                  text-align: center;
                  line-height: 0.46rem;
                  font-size: 0.16rem;
                  font-weight: 400;
                  color: #ffffff;
                }
                > a {
                  font-size: 0.14rem;
                  font-family: FontExcellent;
                  font-weight: 400;
                  color: #3e7ec6;
                  margin-left: 0.08rem;
                  :hover {
                    text-decoration: none;
                  }
                }
              }
            }
          }
          .p4m-major-bottomItem:last-child {
            .p4m-firt-div {
              height: 0.7rem;
              margin-bottom: 1.61rem;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.videoSMS .swiper_bg_blue {
  width: 100%;
  top: calc(100% + 0.15rem);
}
</style>
