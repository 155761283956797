<template>
  <div class="smallAndMediumsizedEnterprises">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>中小企业通信解决方案</span>
          <span>中小企业<br />通信解决方案</span>
          <p>
            综合百悟完善的企业通信生态体系，依托自主研发的大象能力平台，集成多品牌客户多产品服务经验，百悟科技为中小企业客户提供平台式的自助式企业通信解决方案，实现一键接入，便捷服务！
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/solution/smallAndMediumsizedEnterprises/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <PartFlexSwiper
      :pFlexSwiperData="p2Data"
      :pFlexSwiperDatas="p2Datas"
      :pFlexSwiperTitle="p2Title"
    ></PartFlexSwiper>
    <section class="part3">
      <span class="part-title">解决方案结构</span>
      <img
        class="reveal-bottom2"
        src="../../assets/img/solution/smallAndMediumsizedEnterprises/part3-icon.png"
        alt=""
      />
    </section>
    <PartAdvantage
      :pAdvantageData="p4Data"
      :pAdvantageDatas="p4Datas"
    ></PartAdvantage>
  </div>
</template>

<script>
import PartFlexSwiper from '@/components/solution/PartFlexSwiper.vue';
import PartAdvantage from '@/components/solution/PartAdvantage.vue';
export default {
  data() {
    return {
      // p2
      p2Data: [
        {
          img: require('../../assets/img/solution/smallAndMediumsizedEnterprises/part2-icon1.png'),
          imgOver: require('../../assets/img/solution/smallAndMediumsizedEnterprises/part2-icon1-over.png'),
          span: '企业通信需求较为分散',
          p: '中小企业体量虽小，但五脏俱全，对于企业通信需求仍然非常广泛。但由于需求较为分散，且每个需求体量较小，如何完善地满足企业通信需求、支撑业务正常运行，对于中小企业经营来说存在一定的困扰。',
        },
        {
          img: require('../../assets/img/solution/smallAndMediumsizedEnterprises/part2-icon2.png'),
          imgOver: require('../../assets/img/solution/smallAndMediumsizedEnterprises/part2-icon2-over.png'),
          span: '企业运营维护成本较高',
          p: '中小企业由于人力、物力成本限制，无法设置专人、专岗进行企业通信的及时维护，而分散的企业通信需求使得中小企业的运营成本不断提高，对于上升期的中小企业来说，对业务发展构成了一定的压力。',
        },
        {
          img: require('../../assets/img/solution/smallAndMediumsizedEnterprises/part2-icon3.png'),
          imgOver: require('../../assets/img/solution/smallAndMediumsizedEnterprises/part2-icon3-over.png'),
          span: '企业业务发展变化较快',
          p: '中小企业业务更新迭代非常迅速，常常需要面临快速的业务发展变化引发的企业通信需求变化，实现业务快速更迭、上线、发展、维护的稳定支撑，针对企业业务发展变化，快速做出企业通信的同步支撑。',
        },
        {
          img: require('../../assets/img/solution/smallAndMediumsizedEnterprises/part2-icon4.png'),
          imgOver: require('../../assets/img/solution/smallAndMediumsizedEnterprises/part2-icon4-over.png'),
          span: '企业系统功能不完善',
          p: '中小企业主要将重心放在公司主营产品及业务的市场发展上，一般缺乏精力优化企业通信等领域的企业采购标准、选择不同企业支撑服务的供应商，不完善的企业系统功能，使得中小企业在业务支撑侧较为乏力。',
        },
      ],
      p2Datas: [],
      p2Title: '中小企业通信痛点',
      // p4
      p4Data: [
        {
          span: '平台自助式',
          p: '百悟科技中小企业通信解决方案，依托于自主研发的大象能力平台，提供自助式的平台开放服务，客户只需接入API接口个性化开发，即可一键实现个性化的需求定制，轻松接入、便捷使用。',
        },
        {
          span: '业务集成化',
          p: '百悟科技中小企业通信解决方案，集成企业通信产品服务，提供多种套餐、多项选择，客户可根据业务需要及项目预算，选择高性价比、高集成化的套餐服务，满足业务的多种需求。',
        },
        {
          span: '服务稳定性',
          p: '百悟科技中小企业通信解决方案，依托于百悟多年的品牌客户服务经验，集成历史企业通信资源接入、客户服务、平台开发的丰富经验，可实现为中小企业客户提供稳定、高效、快速的服务。',
        },
        {
          span: '产品多样性',
          p: '百悟科技中小企业通信解决方案，是包含了企业短信、语音、物联网、CDN、云计算、新媒体广告、ICT等打包式服务，客户可通过百悟中小企业服务平台，获得完善的企业通信一条龙产品服务。',
        },
      ],
      p4Datas: [],
    };
  },
  components: {
    PartFlexSwiper,
    PartAdvantage,
  },
  mounted() {
    // 数据初始化
    this.p2Datas = [
      JSON.parse(JSON.stringify(this.p2Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p2Data.slice(-2))),
    ];
    this.p4Datas = [
      JSON.parse(JSON.stringify(this.p4Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p4Data.slice(-2))),
    ];
    // 修改圆点样式
    $('.pFlexSwiper-swiper .swiper-pagination').removeClass('swiper_bg_blue');
    $('.pFlexSwiper-swiper .swiper-pagination').addClass('swiper_bg_white');
  },
  methods: {},
};
</script>

<style lang="less" scoped>
/* 第一部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 723px;
  position: relative;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > img {
      position: absolute;
      width: 898px;
      height: 720px;
      z-index: -1;
      top: 3px;
      right: 85px;
    }
  }
  .part1-title {
    width: 538px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    span:nth-of-type(2) {
      display: none;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 67px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
.part3 {
  width: 100%;
  padding-top: 77px;
  padding-bottom: 130px;
  background: url('../../assets/img/solution/smallAndMediumsizedEnterprises/part3-bg.png')
    no-repeat;
  background-size: 100% 100%;
  .part-title {
    color: #fefefe;
    margin-bottom: 70px;
  }
  img {
    display: block;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
  }
}
@media screen and (max-width: 768px) {
  /* 第一部分 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        content: url('../../assets/img/solution/smallAndMediumsizedEnterprises/mobile/part1-icon.png');
        width: 4.52rem;
        height: 4.28rem;
        top: 0.1rem;
        right: 0rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0px;
        margin-top: -0.62rem;
        line-height: 0.7rem;
      }
      span:nth-of-type(1) {
        display: none;
      }
      span:nth-of-type(2) {
        display: block;
      }
      p,
      a {
        display: none;
      }
    }
  }
  .part3 {
    padding-top: 0.54rem;
    padding-bottom: 1.22rem;
    background: url('../../assets/img/solution/smallAndMediumsizedEnterprises/mobile/part3-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part-title {
      margin-bottom: 0.52rem;
    }
    img {
      display: block;
      width: 6.86rem;
      height: 4.19rem;
      margin: 0 auto;
    }
  }
}
</style>
<style lang="less">
.smallAndMediumsizedEnterprises {
  .partFlexSwiper {
    background: url('../../assets/img/solution/smallAndMediumsizedEnterprises/part2-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part-title {
      color: #005cb9;
    }
    .partFlexSwiper-item {
      padding-top: 26px;
      > div {
        height: 89px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .smallAndMediumsizedEnterprises {
    .partFlexSwiper {
      background: url('../../assets/img/solution/smallAndMediumsizedEnterprises/mobile/part2-bg.png')
        no-repeat;
      background-size: 100% 100%;
      .partFlexSwiper-item {
        padding-top: 0.26rem;
        > div {
          height: 0.89rem;
        }
      }
      .pFlexSwiper-swiper {
        .swiper_bg_white {
          width: 5.6rem;
          margin-top: 0.45rem;
        }
      }
    }
    .smallAndMediumsizedEnterprisesP2-swiper {
      .swiper-pagination {
        width: 5.6rem;
        margin-top: 0.45rem;
        .swiper-pagination-bullet {
          box-sizing: border-box;
          width: 0.18rem;
          height: 0.18rem;
          margin: 0rem 0.15rem;
          opacity: 1;
          background: #f1f1f1;
          border: 1px solid #005cb9;
          border-radius: 50%;
        }
        .swiper-pagination-bullet-active {
          border: 0px solid #ffffff;
          background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
        }
      }
    }
  }
}
</style>
