<template>
  <div class="AI">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>人工智能解决方案</span>
          <span>人工智能<br />解决方案</span>
          <p>
            基于云计算及人工智能深度学习平台，综合信息审核、NLP自然语言理解、搜索引擎、情感分析、文本分析处理、用户画像等人工智能应用方案，百悟科技面向客户提供涵盖生活、办公等多领域的人工智能解决方案服务，助力企业提升效率，实现更加智能化的企业应用。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/solution/AI/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <span class="part-title">解决方案结构</span>
      <div class="p2_main reveal-bottom2">
        <img src="../../assets/img/solution/AI/part2-icon.png" alt="" />
        <div class="p2_right">
          <div class="p2_rFlexed">
            <span>百悟AI应用平台</span>
            <p>
              基于先进的人工智能技术，深度结合各项平台服务，一站式提供自然语言、图像识别、情感分析、文本分析、智能分配等人工智能基础服务，以智能审核、智能客服、智能运营、智能打卡等解决方案，打造高效化的人工智能行业应用。
            </p>
          </div>
          <div class="p2_rLeft">
            <div
              class="p2_rLItem"
              v-for="(item, i) in p2Data"
              :key="i"
              @mousemove="p2Click(i)"
            >
              <span v-text="item.span"></span>
            </div>
          </div>
          <div class="p2_rRight">
            <div
              class="p2_rRItem"
              v-for="(item, i) in p2Data"
              :key="i"
              @mousemove="p2Click(i)"
            >
              <p v-text="item.p"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <PartFlexSwiper
      :pFlexSwiperData="p3Data"
      :pFlexSwiperDatas="p3Datas"
      :pFlexSwiperTitle="p3Title"
    ></PartFlexSwiper>
  </div>
</template>

<script>
import PartFlexSwiper from '@/components/solution/PartFlexSwiper.vue';
export default {
  data() {
    return {
      // p2
      p2Data: [
        {
          span: '算法领先',
          p: '专业化团队和人工智能超前投入，打造先进的人工智能领域相关算法技术。',
        },
        // {
        //   span: '数据可靠',
        //   p: '丰富的业务场景和专业数据，提供可靠的海量人工智能领域相关信息基础。',
        // },
        {
          span: '高智能度',
          p: '实现准确率高达99.8%的人工智能应用，大大提高应用效率。',
        },
        {
          span: '场景丰富',
          p: '实现涵盖生活、办公等多领域的人工智能应用，大大提升智能体验。',
        },
      ],
      // p3
      p3Data: [
        {
          img: require('../../assets/img/solution/AI/part3-icon1.png'),
          imgOver: require('../../assets/img/solution/AI/part3-icon1-over.png'),
          span: '信审机器人',
          p: '基于人工智能技术的信息审核系统，NLP自然语言理解技术、搜索引擎技术、情感分析技术、文本分析处理技术、用户画像技术和机器学习技术的深度结合，构建智能审核模型，打造信审机器人应用，可全年7*24小时稳定工作，并代替原有信息审核',
        },
        {
          img: require('../../assets/img/solution/AI/part3-icon2.png'),
          imgOver: require('../../assets/img/solution/AI/part3-icon2-over.png'),
          span: '智能运营',
          p: '基于较大规模较优模型的构建和求解实现，对上千万个客户账号和数千条通道进行较低成本的智能通道调拨方案的生成和推荐，在保障用户较好可用性的同时，尽可能促成通道套餐和升档实现，将更多的流量分配到综合成本低通道上去，到达低成本运营，高运营效率。',
        },
        {
          img: require('../../assets/img/solution/AI/part3-icon3.png'),
          imgOver: require('../../assets/img/solution/AI/part3-icon3-over.png'),
          span: '客服机器人',
          p: '基于NLP自然语言理解技术、搜索引擎技术、用户画像技术、机器学习技术和深度学习技术，打造智能客服系统，对接网站和用户咨询入口，实现自动的语义分析、特征提取、意图识别、情感分析等功能，为用户提供基础的业务问答和业务咨询服务，同时针对无法回答的问题，可以自动无缝转接到人工客服进行处理。',
        },
        {
          img: require('../../assets/img/solution/AI/part3-icon4.png'),
          imgOver: require('../../assets/img/solution/AI/part3-icon4-over.png'),
          span: '智能打卡',
          p: '基于机器人视觉图像识别技术的智能打卡系统，可以根据公司摄像头的监控视频对员工上下班情况进行实时处理和识别，实现员工上下班的智能打卡功能，在节省公司管理成本的同时，大大提升了员工的考勤体验。',
        },
      ],
      p3Datas: [],
      p3Title: 'AI应用场景',
    };
  },
  components: {
    PartFlexSwiper,
  },
  mounted() {
    this.p2Click(0);
    this.p3Datas = [
      JSON.parse(JSON.stringify(this.p3Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p3Data.slice(-2))),
    ];
    // 修改圆点样式
    $('.pFlexSwiper-swiper .swiper-pagination').removeClass('swiper_bg_blue');
    $('.pFlexSwiper-swiper .swiper-pagination').addClass('swiper_bg_white');
  },
  methods: {
    // p2
    p2Click(i) {
      $('.p2_rLItem').removeClass('active');
      $('.p2_rRItem').removeClass('active');
      $('.p2_rLItem').eq(i).addClass('active');
      $('.p2_rRItem').eq(i).addClass('active');
    },
  },
};
</script>

<style lang="less" scoped>
/* 第一部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 723px;
  position: relative;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > img {
      position: absolute;
      width: 1089px;
      height: 703px;
      z-index: -1;
      top: 20px;
      right: 30px;
    }
  }
  .part1-title {
    width: 538px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    span:nth-of-type(2) {
      display: none;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 67px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
.part2 {
  width: 100%;
  padding-top: 79px;
  padding-bottom: 127px;
  background: url('../../assets/img/solution/AI/part2-bg.png') no-repeat;
  background-size: 100% 100%;
  .part-title {
    color: #fefefe;
    margin-bottom: 62px;
  }
  .p2_main {
    width: 1111px;
    margin: 0 auto;
    height: 590px;
    background: rgba(241, 241, 241, 0.92);
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .p2_right {
      box-sizing: border-box;
      width: 513px;
      height: 521px;
      border: 2px solid #e4e6e9;
      border-radius: 7px;
      background: #ffffff;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      .p2_rFlexed {
        position: absolute;
        top: 30px;
        left: 40px;
        span {
          display: block;
          font-size: 24px;
          font-family: FontExcellent;
          font-weight: 400;
          color: #005cb9;
          margin-bottom: 10px;
        }
        p {
          display: block;
          width: 437px;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          line-height: 27px;
          text-align: justify;
        }
      }
      .p2_rLeft {
        box-sizing: border-box;
        width: 152px;
        height: calc(100% - 203px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 16px;
        border-right: 1px solid #e4e6e9;
        margin-top: 203px;
        .p2_rLItem {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          span {
            font-size: 24px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #333333;
          }
        }
        .p2_rLItem.active {
          span {
            color: #005cb9;
            position: relative;
          }
          span::after {
            content: '';
            display: block;
            width: 4px;
            height: 38px;
            background: #005cb9;
            position: absolute;
            top: -3px;
            left: -13px;
          }
        }
      }
      .p2_rRight {
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 203px;
        .p2_rRItem {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          p {
            display: block;
            width: 300px;
            text-align: justify;
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 27px;
          }
        }
        .p2_rRItem.active {
          p {
            font-weight: bold;
            color: #333333;
          }
        }
      }
    }
  }
}
.partFlexSwiper {
  background: url('../../assets/img/solution/AI/part3-bg.png') no-repeat;
  background-size: 100% 100%;
}
@media screen and (max-width: 768px) {
  /* 第一部分 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        content: url('../../assets/img/solution/AI/mobile/part1-icon.png');
        width: 5.55rem;
        height: 4.24rem;
        top: 0rem;
        right: 0rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0px;
        margin-top: -0.62rem;
        line-height: 0.7rem;
      }
      span:nth-of-type(1) {
        display: none;
      }
      span:nth-of-type(2) {
        display: block;
      }
      p,
      a {
        display: none;
      }
    }
  }
  .part2 {
    padding-top: 0.58rem;
    padding-bottom: 0.83rem;
    background: #005cb9;
    .part-title {
      margin-bottom: 0.69rem;
    }
    .p2_main {
      box-sizing: border-box;
      width: 6.2rem;
      height: 11.7rem;
      border-radius: 0.07rem;
      padding: 0.23rem 0px;
      flex-direction: column;
      > img {
        width: 5.8rem;
      }
      .p2_right {
        width: 5.78rem;
        height: 5.79rem;
        border: 0.02rem solid #e4e6e9;
        border-radius: 0.07rem;
        .p2_rFlexed {
          top: 0.3rem;
          left: 0.4rem;
          span {
            font-size: 0.24rem;
            margin-bottom: 0.1rem;
          }
          p {
            width: 4.75rem;
            font-size: 0.16rem;
            line-height: 0.27rem;
          }
        }
        .p2_rLeft {
          width: 1.52rem;
          height: calc(100% - 2.03rem);
          margin-top: 2.03rem;
          padding-bottom: 0.16rem;
          .p2_rLItem {
            flex: 1;
            span {
              font-size: 0.24rem;
            }
          }
          .p2_rLItem.active {
            span::after {
              width: 0.04rem;
              height: 0.38rem;
              top: -0.03rem;
              left: -0.13rem;
            }
          }
        }
        .p2_rRight {
          padding-top: 2.03rem;
          .p2_rRItem {
            p {
              width: 3.14rem;
              font-size: 0.16rem;
              line-height: 0.27rem;
            }
          }
        }
      }
    }
  }
  .partFlexSwiper {
    background: url('../../assets/img/solution/AI/mobile/part3-bg.png')
      no-repeat;
    background-size: 100% 100%;
    height: 8.46rem;
    padding-top: 0.62rem;
  }
}
</style>
<style lang="less">
.AI {
  .partFlexSwiper {
    .part-title {
      color: #005cb9;
    }
    .partFlexSwiper-item {
      width: 256px !important;
      height: 561px !important;
      padding-top: 26px !important;
      margin-bottom: 106px !important;
      > div {
        height: 95px !important;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .AI {
    .partFlexSwiper {
      .part-title {
        margin-bottom: 0.57rem;
      }
      .partFlexSwiper-item {
        width: 2.56rem !important;
        height: 5.61rem !important;
        padding-top: 0.26rem !important;
        margin-bottom: 0px !important;
        > div {
          height: 0.95rem !important;
        }
      }
      .pFlexSwiper-swiper {
        .swiper-pagination {
          width: 5.6rem;
          margin-top: 0.42rem;
        }
      }
    }
  }
}
</style>
