<template>
  <div class="Message955G">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>产品中心</span>
          <a href="">商务合作</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/navigation/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <div class="p2_main">
        <ul>
          <li class="p2_item" v-for="(item, i) in p2Data" :key="i">
            <div class="p2_iLeft">
              <div>
                <img :src="item.imgSpan" alt="" />
              </div>
              <span v-text="item.span"></span>
            </div>
            <div class="p2_iRight">
              <div
                class="p2_iRItem"
                v-for="(item2, i2) in item.content"
                :key="i2"
              >
                <router-link :to="item2.path" v-if="item2.path != undefined">
                  <span v-text="item2.span"></span>
                  <p v-text="item2.p"></p>
                </router-link>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      p2Data: [
        {
          imgSpan: require('../../assets/img/index/part2-icon1.png'),
          span: '消息云',
          content: [
            {
              span: '5G消息：',
              p: '以企业chatbot为窗口，构建全新的消息生态',
              path: '/5GMessage',
            },
            {
              span: '视频短信：',
              p: '图片、动画、音频等信息内容，高效直达，即点即看',
              path: '/videoSMS',
            },
            {
              span: '智媒短信：',
              p: '识别短信核心内容智能转换终端卡片消息，精准营销降本增效',
              path: '/wisdomMedia',
            },
            {
              span: '会员短信：',
              p: '个性发送、一主多备、高覆盖',
              path: '/memberSMS',
            },
            {
              span: '通知短信：',
              p: '快速稳定、海量并发、智能管理',
              path: '/noticeSMS',
            },
          ],
        },
        {
          imgSpan: require('../../assets/img/index/part2-icon2.png'),
          span: '语音',
          content: [
            {
              span: '电话名片: ',
              p: '号码与品牌有效关联，精准品牌曝光，防止误标记',
              path: '/telephoneCard',
            },
            {
              span: '语音验证码: ',
              p: '安全稳定，多资源灾备，高品质 语音质量',
              path: '/voiceCode',
            },
            {
              span: '呼叫中心平台: ',
              p: '拥有语音机器人、工单系统、知识库、智能外呼、在线客服、呼叫中心平台等多种功能',
              path: '/callPlatform',
            },
            {
              span: '固话外呼平台: ',
              p: '丰富的全国码号资源，强大的支撑并发处理能力',
              path: '/fixedCall',
            },
            {
              span: '隐私通话: ',
              p: '号源丰富，业务模式灵活、安全',
              path: '/hideConnect',
            },
          ],
        },
        {
          imgSpan: require('../../assets/img/index/part2-icon5.png'),
          span: '95服务',
          content: [
            {
              span: '95语音: ',
              p: '三网合一全国统一热线，企业“ 实力和信誉”必备名片',
              path: '/voice',
            },
            {
              span: '95 5G消息:',
              p: ' 属于全网呼叫中心号码，全国统一的稀缺资源',
              path: '/message955G',
            },
            {
              span: '95码号服务:',
              p: '隶属于工信部终身使用的特殊短号码，具有全国唯一、三网合一的特性',
              path: '/serve95',
            },
          ],
        },
        {
          imgSpan: require('../../assets/img/index/part2-icon4.png'),
          span: '私有云',
          content: [
            {
              span: '5G消息平台: ',
              p: '提供Chatbot接入和管理服务， 实现消息下发',
              path: '/5GMessagePlatform',
            },
            {
              span: '短信平台: ',
              p: '集企业短信智能路由、智能运营、系统管理、统计查询等功能的综合性企业通讯平台',
              path: '/message',
            },
            {
              span: '呼叫中心平台: ',
              p: '多种通信手段为客户提供交互式自助服务和人工服务',
              path: '/callPlatform',
            },
            {
              span: '融合通信平台: ',
              p: '更专业、全站式、一体化多场景解决方案',
              path: '/fuseMessage',
            },
          ],
        },
        {
          imgSpan: require('../../assets/img/index/part2-icon3.png'),
          span: '国际通讯',
          content: [
            {
              span: '国际短信:',
              p: '为企业提供简单、安全、稳定的一站式通讯解决方案',
              path: '/internationalSms',
            },
            {
              span: '国际营销短信:',
              p: '稳定的通道，快速的下达，优惠的资费',
              path: '/internationalMarketingSms',
            },
            {
              span: '国际验证码短信:',
              p: '多国语言，高效接入，秒级到达',
              path: '/internationalCode',
            },
          ],
        },
        {
          imgSpan: require('../../assets/img/index/part2-icon6.png'),
          span: '一键登录',
          content: [
            {
              span: '一键登录:',
              p: '用户无需谨记账号和密码，一键登录',
              path: '/oneclickLogin',
            },
          ],
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
/* 第一部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 454px;
  background: rgb(234, 244, 250);
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      position: absolute;
      width: 800px;
      height: 440px;
      z-index: 0;
      top: 14px;
      right: -119px;
    }
  }
  .part1-title {
    width: 538px;
    span {
      font-family: FontExcellent;
      font-size: 48px;
      color: #333333;
      display: block;
      margin-bottom: 48px;
      letter-spacing: 1px;
    }
    a {
      display: block;
      width: 139px;
      height: 43px;
      background: #005cb9;
      border-radius: 22px;
      text-align: center;
      line-height: 43px;
      font-size: 18px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
.part2 {
  width: 100%;
  .p2_main {
    width: 1164px;
    margin: 0 auto;
    .p2_item {
      border-bottom: 1px solid #d0d0d0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 35px 0px;
      .p2_iLeft {
        width: 18%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        div {
          width: 56px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          img {
            max-width: 100%;
          }
        }
        span {
          font-size: 24px;
          font-family: FontExcellent;
          font-weight: 400;
          color: #333333;
        }
      }
      .p2_iRight {
        flex: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .p2_iRItem {
          display: flex;
          justify-content: flex-end;
          width: 33%;
          margin-bottom: 30px;
          p,
          span {
            display: block;
            width: 225px;
            font-size: 18px;
            font-weight: 400;
            color: #333333;
            margin-bottom: 6px;
          }
          p {
            font-size: 16px;
            text-align: justify;
            line-height: 27px;
          }
          span {
            font-size: 22px;
            font-family: FontExcellent;
          }
        }
        .p2_iRItem:hover {
          span {
            color: #005cb9;
          }
          p {
            font-weight: bold;
          }
        }
        .p2_iRItem:nth-of-type(4),
        .p2_iRItem:nth-of-type(5),
        .p2_iRItem:nth-of-type(6) {
          margin-bottom: 0px;
        }
      }
    }
    .p2_item:hover {
      .p2_iLeft span {
        color: #005cb9;
      }
    }
  }
}
</style>
