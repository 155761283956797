<template>
  <div class="honest">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>廉洁合规</span>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/about/honest/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part-jump reveal-bottom2">
      <div class="pJ_main">
        <ul>
          <li
            v-for="(item, i) in pJump"
            :key="i"
            @click="pJClick($event, i, item.address)"
          >
            <a href="#" v-text="item.span"></a>
          </li>
        </ul>
      </div>
    </section>
    <section class="part2">
      <span class="part-title reveal-bottom2">廉洁合规</span>
      <div class="part2_main">
        <p class="reveal-bottom2">
          商无信不立，业无诚不远，遵守商业道德规范是企业可持续发展的基石。百悟科技要求每位员工以商业道德规范为工作行为基本准则，坚定地推行内部规范化管理，通过建立完善的企业合规管理体系，开展员工的法律法规及管理制度规范的培训工作，有效提升了员工的企业合规风险管理意识。每个企业公平诚信、合法经营，才能使整个营商环境健康有序发展，百悟倡导廉洁的企业文化，希望与行业伙伴一同推动行业健康发展。
        </p>
        <div class="part2_item p2_item-1 reveal-bottom2" @click="p2Click(0)">
          <div class="p2_iContent p2_iContent_1">
            <div class="p2_iContent-left">
              <span>内审合规部</span>
              <h6>主要职责：</h6>
              <p>
                对内部员工涉及的严重违规情况进行调查，并根据调查力求发现公司业务和管理中存在的风险。
              </p>
              <p>
                针对发现的风险，努力推动公司相关部门进行改进，包括制度的完善和执行。
              </p>
              <p>
                通过各种渠道在公司范围内开展企业合规的宣传和培训，落实合规要求。
              </p>
            </div>
            <div class="p2_iContent-right">
              <img src="../../assets/img/about/honest/part2-icon1.png" alt="" />
            </div>
          </div>
          <div class="p2_iContent p2_iContent_2">
            <div class="p2_iContent-top">
              <div>
                <span>内审合规部</span>
                <h6>主要职责：</h6>
              </div>
              <img src="../../assets/img/about/honest/part2-icon1.png" alt="" />
            </div>
            <div class="p2_iContent-bottom">
              <p>
                对内部员工涉及的严重违规情况进行调查，并根据调查力求发现公司业务和管理中存在的风险。
              </p>
              <p>
                针对发现的风险，努力推动公司相关部门进行改进，包括制度的完善和执行。
              </p>
              <p>
                通过各种渠道在公司范围内开展企业合规的宣传和培训，落实合规要求。
              </p>
            </div>
          </div>
        </div>
        <div class="part2_item p2_item-2 reveal-bottom2" @click="p2Click(1)">
          <div class="p2_iContent p2_iContent_1">
            <div class="p2_iContent-left">
              <span>关于举报</span>
              <p>
                本平台受理员工营私舞弊、弄虚作假等违反职业道德的行为的投诉和举报，其他问题的受理请拨打电话010-50950599-8783或联系业务人员反馈。
              </p>
              <p>
                支持匿名举报，但鼓励实名举报，便于及时反馈受理情况及通报处理结果。
              </p>
              <p>
                对反馈人信息进行严格保密是我们最基本职责。任何人不得对反馈人实行打击报复行为，一经发现，严肃处理。
              </p>
            </div>
            <div class="p2_iContent-right">
              <img src="../../assets/img/about/honest/part2-icon2.png" alt="" />
            </div>
          </div>
          <div class="p2_iContent p2_iContent_2">
            <div class="p2_iContent-top">
              <div>
                <span>关于举报</span>
              </div>
              <img src="../../assets/img/about/honest/part2-icon2.png" alt="" />
            </div>
            <div class="p2_iContent-bottom">
              <p>
                本平台受理员工营私舞弊、弄虚作假等违反职业道德的行为的投诉和举报，其他问题的受理请拨打电话010-50950599-8783或联系业务人员反馈。
              </p>
              <p>
                支持匿名举报，但鼓励实名举报，便于及时反馈受理情况及通报处理结果。
              </p>
              <p>
                对反馈人信息进行严格保密是我们最基本职责。任何人不得对反馈人实行打击报复行为，一经发现，严肃处理。
              </p>
            </div>
          </div>
        </div>
        <div class="p2_down reveal-bottom2">
          <div class="p2-dItem">
            <img src="../../assets/img/about/honest/part2-dIcon1.png" alt="" />
            <p>举报邮箱：jiancha@baiwutong.com</p>
          </div>
          <div class="p2-dItem">
            <img src="../../assets/img/about/honest/part2-dIcon2.png" alt="" />
            <p>
              来信来访： 北京市朝阳区阜通东大街融科望京中心B座9层 内审合规部
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pJump: [
        {
          span: '内审合规部',
          address: '.p2_item-1',
        },
        {
          span: '关于举报',
          address: '.p2_item-2',
        },
      ],
    };
  },
  mounted() {
    // 初始化样式
    // 点击块
    $('.pJ_main li').eq(0).addClass('active');
    $('.part2_item').eq(0).addClass('active');
  },
  methods: {
    // 点击块
    pJClick(e, i, address) {
      this.p2Click(i);
      $('html,body').animate(
        { scrollTop: $(address).offset().top + 'px' },
        500
      );
    },
    p2Click(i) {
      $('.pJ_main li').removeClass('active');
      $('.pJ_main li').eq(i).addClass('active');
      $('.part2_item').removeClass('active');
      $('.part2_item').eq(i).addClass('active');
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.honest {
  /* 第一部分 */
  .part1 {
    box-sizing: border-box;
    width: 100%;
    height: 369px;
    background: linear-gradient(-77deg, #ffffff 0%, #f2f6fa 100%);
    .part1_main {
      box-sizing: border-box;
      width: 1110px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .part1-title {
        span {
          font-family: FontExcellent;
          font-size: 40px;
          color: #333333;
          display: block;
          letter-spacing: 1px;
          margin-top: -54px;
        }
      }
      > img {
        position: absolute;
        width: 572px;
        height: 369px;
        bottom: 0;
        right: -34px;
      }
    }
  }
  // 跳转模块
  .part-jump {
    box-sizing: border-box;
    width: 100%;
    height: 53px;
    background: #ffffff;
    box-shadow: 0px 15px 24px 0px rgba(55, 63, 71, 0.03);
    .pJ_main {
      box-sizing: border-box;
      width: 482px;
      margin: 0 auto;
      height: 100%;
      position: relative;
      ul {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        li {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          position: relative;
          cursor: pointer;
          a {
            font-size: 24px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #333333;
            line-height: 100%;
          }
        }
        li.active {
          a {
            color: #0b62bb;
          }
        }
        li::after {
          display: block;
          content: '';
          position: absolute;
          top: 10px;
          right: 0;
          width: 1px;
          height: 33px;
          background: #bcbcbc;
        }
        li:last-child::after {
          display: none;
        }
      }
    }
  }
  .part2 {
    width: 100%;
    padding-top: 42px;
    .part-title {
      margin-bottom: 40px;
    }
    .part2_main {
      width: 1110px;
      margin: 0 auto;
      > p {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 31px;
        text-align: justify;
        margin-bottom: 88px;
      }
      .part2_item {
        width: 100%;
        height: 331px;
        border: 1px solid #bcbcbc;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .p2_iContent {
          width: 1050px;
          height: 243px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .p2_iContent-left {
            box-sizing: border-box;
            width: 730px;
            height: 100%;
            border-right: 1px solid #bcbcbc;
            span {
              display: block;
              font-size: 24px;
              font-family: FontExcellent;
              font-weight: 400;
              color: #333333;
              margin-top: -15px;
              margin-bottom: 37px;
            }
            h6,
            p {
              font-size: 18px;
              font-weight: 400;
              color: #333333;
              line-height: 30px;
            }
            h6 {
              font-weight: bold;
              margin-bottom: 16px;
            }
            p {
              display: block;
              width: 666px;
              margin-left: 24px;
              position: relative;
            }
            p::before {
              display: block;
              content: '';
              position: absolute;
              width: 10px;
              height: 10px;
              background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
              border-radius: 50%;
              top: 11px;
              left: -22px;
            }
          }
          .p2_iContent-right {
            flex: 1;
            margin-left: 34px;
            display: flex;
            flex-direction: row;
            justify-content: center;
          }
        }
        .p2_iContent_2 {
          display: none;
        }
      }
      .part2_item:nth-of-type(1) {
        margin-bottom: 56px;
      }
      .part2_item:nth-of-type(2) {
        margin-bottom: 43px;
      }
      .part2_item.active {
        border: 1px solid #0b62bb;
      }
      .p2_down {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 56px;
        .p2-dItem {
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            margin-right: 21px;
          }
          p {
            font-size: 18px;
            font-weight: 400;
            color: #666666;
          }
        }
        .p2-dItem:nth-of-type(2) {
          img {
            margin-right: 12px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .honest {
    /* 第一部分 */
    .part1 {
      height: 4.28rem;
      background: linear-gradient(64deg, #ffffff 0%, #f2f6fa 100%);
      .part1_main {
        width: 6.14rem;
        > img {
          content: url('../../assets/img/about/honest/mobile/part1-icon.png');
          width: 7.5rem;
          height: 4.28rem;
          bottom: 0px;
          right: -0.68rem;
        }
      }
      .part1-title {
        span {
          font-size: 0.4rem !important;
          margin-top: 0rem !important;
        }
      }
    }
    .part-jump {
      display: none;
    }
    .part2 {
      padding-top: 0.29rem;
      .part-title {
        margin-bottom: 0.33rem;
      }
      .part2_main {
        width: 6.15rem;
        > p {
          font-size: 0.21rem;
          line-height: 0.35rem;
          text-align: justify;
          margin-bottom: 0.61rem;
        }
        .part2_item {
          height: 5.15rem;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          .p2_iContent {
            box-sizing: border-box;
            width: 5.5rem;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding-top: 0.14rem;
            .p2_iContent-top {
              box-sizing: border-box;
              width: 100%;
              height: auto;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              margin-bottom: 0.31rem;
              span {
                display: block;
                font-size: 0.29rem;
                margin-top: 0.56rem;
                margin-bottom: 0.07rem;
                font-family: FontExcellent;
                font-weight: 400;
                color: #333333;
              }
              h6 {
                font-weight: 400;
                color: #333333;
                font-size: 0.21rem;
                font-weight: bold;
                margin-bottom: 0.16rem;
              }
            }
            .p2_iContent-bottom {
              width: 100%;
              p {
                font-size: 0.19rem;
                display: block;
                width: 5.3rem;
                margin-left: 0.2rem;
                position: relative;
                text-align: justify;
                line-height: 0.33rem;
              }
              p::before {
                display: block;
                content: '';
                position: absolute;
                width: 0.1rem;
                height: 0.1rem;
                background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
                border-radius: 50%;
                top: 0.11rem;
                left: -0.2rem;
              }
            }
          }
          .p2_iContent_1 {
            display: none;
          }
          .p2_iContent_2 {
            display: block;
          }
        }
        .part2_item:nth-of-type(1) {
          margin-bottom: 0.76rem;
          img {
            width: 2.96rem;
            height: 2.34rem;
          }
        }
        .part2_item:nth-of-type(2) {
          height: 5.74rem;
          margin-bottom: 0.37rem;
          img {
            width: 2.41rem;
            height: 2.33rem;
          }
        }
        .part2_item.active {
          border: 1px solid #0b62bb;
        }
        .p2_down {
          flex-direction: column;
          justify-content: flex-start;
          margin-bottom: 0.39rem;
          .p2-dItem {
            align-items: flex-start;
            img {
              margin-right: 0.2rem;
            }
            p {
              font-size: 0.21rem;
              line-height: 0.36rem;
            }
          }
          .p2-dItem:nth-of-type(1) {
            img {
              width: 0.31rem;
              height: 0.24rem;
              margin-top: 0.05rem;
            }
          }
          .p2-dItem:nth-of-type(2) {
            img {
              width: 0.31rem;
              height: 0.29rem;
              margin-top: 0.02rem;
              margin-right: 0.2rem;
            }
          }
        }
      }
    }
  }
}
</style>
