<template>
  <div class="WisdomMedia">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>智媒短信</span>
          <p>
            智媒短信是将传统文本短信解析重构成卡片式短信，可实现双向交互，提供生活场景服务的新一代短信产品。包含了品牌曝光、短信、公众号、智能卡片场景解析等功能。把原本只能发70个字的普通短信，变成了含有菜单、卡片以及含企业logo的新短信，让传统短信具备了互联网功能。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/wisdomMedia/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <div class="part2_main">
        <span class="part-title">智媒短信功能介绍</span>
        <p class="reveal-bottom3">
          智媒短信对传统短信进行了全面升级和扩展，为企业打造品牌传播、用户服务的新入口。目标客户在收到短信时，可以看到企业商家（短信发送者）的名称和logo，且在短信下方会出现菜单栏，可将客户引流至各页面（如官网、产品等）。
        </p>
        <div class="p2m-major p2mm-pc1">
          <div class="p2m-major-left reveal-left">
            <div
              class="p2m-major-item"
              v-for="(item, i) in p2data"
              :key="i"
              @click="p2textOver(i)"
            >
              <span>{{ item.lTitle }}</span>
              <p>{{ item.lContent }}</p>
            </div>
          </div>
          <div class="p2m-major-right reveal-right">
            <img
              src="../../assets/img/product/wisdomMedia/part2-right-img.png"
              alt=""
            />
            <span v-for="(item, i) in p2data" :key="i" @click="p2textOver(i)">{{
              item.rTitle
            }}</span>
          </div>
        </div>
        <div class="p2m-major p2mm-pc2">
          <div class="p2m-major-right reveal-right">
            <img
              src="../../assets/img/product/wisdomMedia/part2-right-img.png"
              alt=""
            />
            <span v-for="(item, i) in p2data" :key="i" @click="p2mmRClick(i)">{{
              item.rTitle
            }}</span>
          </div>
          <div class="p2m-major-left reveal-left">
            <el-carousel
              trigger="click"
              :interval="5000"
              arrow="never"
              @change="p2mmlMobile"
              ref="p2mmLeftcarousel"
            >
              <el-carousel-item
                v-for="(item, i) in p2data"
                :key="i"
                class="p2m-major-item"
              >
                <span>{{ item.lTitle }}</span>
                <p>{{ item.lContent }}</p>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </section>
    <section class="part3">
      <span class="part-title">智媒短信特色优势</span>
      <div class="part3_main p3m-1 reveal-bottom2">
        <div class="p3-item" v-for="(item, index) in p3Data" :key="index">
          <div>
            <img :src="item.img" alt="" />
          </div>
          <span>{{ item.span }}</span>
          <p>{{ item.p }}</p>
        </div>
      </div>
      <div class="part3_main p3m-2">
        <swiper :options="swiperOption">
          <swiper-slide
            v-for="(item, i) in p3Data"
            :key="i"
            class="swiper-item"
          >
            <div class="p3-item">
              <div>
                <img :src="item.img" alt="" />
              </div>
              <span>{{ item.span }}</span>
              <p>{{ item.p }}</p>
            </div>
          </swiper-slide>
        </swiper>
        <div
          class="swiper-pagination p3m-pagination swiper_bg_blue"
          slot="pagination"
        ></div>
      </div>
    </section>
    <section class="part4">
      <div class="part4_main">
        <span class="part-title">智媒短信场景应用</span>
        <div class="p4m-major">
          <img
            src="../../assets/img/product/videoSMS/part4-img-bg.png"
            alt=""
          />
          <div class="p4m-major-top reveal-bottom2">
            <div class="p4m-major-topLine"></div>
            <div
              class="p4m-major-topItem"
              v-for="(item, i) in p4data"
              :key="i"
              @click="p4TopAction(i, item.topIconOver)"
            >
              <img :src="item.topIcon" alt="" />
              <span>{{ item.topText }}</span>
            </div>
          </div>
          <div class="p4m-major-bottom reveal-bottom3">
            <div
              class="p4m-major-bottomItem"
              v-for="(item, i) in p4data"
              :key="i"
            >
              <div class="p4m-major-bottomLeft">
                <img
                  v-for="(item2, i2) in item.bottomImg"
                  :key="i2"
                  :src="item2"
                  alt=""
                />
                <div v-if="item.bottomImg.length <= 1" class="mobileImg">
                  <img
                    v-for="(item2, i2) in item.bottomImg"
                    :key="i2"
                    :src="item2"
                    alt=""
                  />
                </div>
                <el-carousel
                  trigger="click"
                  :interval="5000"
                  arrow="never"
                  v-else
                  class="mobileImg"
                >
                  <el-carousel-item
                    v-for="(item2, i2) in item.bottomImg"
                    :key="i2"
                  >
                    <img :src="item2" alt="" />
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="p4m-major-bottomRight">
                <div class="p4m-firt-div">
                  <p v-for="(item2, i2) in item.bottomContent" :key="i2">
                    {{ item2 }}
                  </p>
                </div>
                <div class="p4m-last-div">
                  <div>
                    <span v-for="(item2, i2) in item.bottomSpan" :key="i2">
                      {{ item2 }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'VideoSMS',
  data() {
    return {
      p2data: [
        {
          lTitle: '品牌认证',
          lContent:
            '取代传统短信的数字号码，直接显示企业的LOGO图标、认证名称。提高用户对短信的辨识度和信任度，增强企业品牌曝光和展示能力',
          rTitle: '品牌认证',
        },
        {
          lTitle: '消息内容',
          lContent:
            '将文本短信升级为富媒体内容，承载更多消息内容，覆盖多种场景，以卡片+公众号的界面设计、交互形式，可直接点击进入服务，将用户引流至各个产品页面，提高点击率',
          rTitle: '消息内容',
        },
        {
          lTitle: '显示原文按钮',
          lContent:
            '点击后显示原本的短信内容原文，取消卡片的形式，和原普通短信无区别',
          rTitle: '显示原文按钮',
        },
        {
          lTitle: '智能菜单',
          lContent:
            '根据业务诉求灵活自定义配置，预设短信菜单，强势曝光入口，为企业和个人之间提供消息交互接口，帮助企业更高效地服务用户、开展业务，有利于引流和转化',
          rTitle: '智能菜单',
        },
      ],
      p3Data: [
        {
          img: require('../../assets/img/product/wisdomMedia/part3-icon1.png'),
          span: '增强企业曝光度，提升品牌形象',
          p: '带有企业名称+LOGO的展现形式，可提升品牌形象，提高用户对品牌的好感，具备更高的公信力，用户打开率大幅提升',
        },
        {
          img: require('../../assets/img/product/wisdomMedia/part3-icon2.png'),
          span: '短信样式新颖',
          p: '呈现生动的卡片式样，可根据企业发送短信场景不同，匹配不同的卡片和菜单服务，起到很好的引流作用',
        },
        {
          img: require('../../assets/img/product/wisdomMedia/part3-icon3.png'),
          span: '实现流量减免',
          p: '基于短信原生入口，无需关注即可轻松互动，在线咨询、访问页面、拨打电话、引导下载等，还可以通过短信菜单窗口完成查询、购物、支付等业务体验',
        },
      ],
      // 轮播图参数
      swiperOption: {
        pagination: {
          el: '.part3_main .swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      p4data: [
        {
          topIcon: require('../../assets/img/product/wisdomMedia/part4-icon1.png'),
          topIconOver: require('../../assets/img/product/wisdomMedia/part4-icon1-over.png'),
          topText: '运营商生活缴费行业',
          bottomImg: [
            require('../../assets/img/product/wisdomMedia/part4-img1-1.png'),
          ],
          bottomContent: [
            '支持各类线上业务办理场景，比如话费充值、水电燃气缴费、物业办理等。通过智媒短信菜单跳转至H5小程序或APP/网站等方式，进行支付。',
          ],
          bottomSpan: ['流量订购', '话费充值', '水电煤气缴费'],
        },
        {
          topIcon: require('../../assets/img/product/wisdomMedia/part4-icon2.png'),
          topIconOver: require('../../assets/img/product/wisdomMedia/part4-icon2-over.png'),
          topText: '交通旅游行业',
          bottomImg: [
            require('../../assets/img/product/wisdomMedia/part4-img2-1.png'),
          ],
          bottomContent: [
            '智媒短信可通过卡片的形式下发告知具体行程，一目了然。不需下载/打开相关软件，便可通过智媒短信直接查询相关票务信息，实现票务预订、酒店预订、景区门票预定等相关服务。',
          ],
          bottomSpan: ['机票预订', '酒店预订', '航旅服务'],
        },
        {
          topIcon: require('../../assets/img/product/wisdomMedia/part4-icon3.png'),
          topIconOver: require('../../assets/img/product/wisdomMedia/part4-icon3-over.png'),
          topText: '电商行业',
          bottomImg: [
            require('../../assets/img/product/wisdomMedia/part4-img3-1.png'),
          ],
          bottomContent: [
            '电商行业利用富媒体承载丰富的卡片消息，可以提升阅读体验，利用卡片action能力，点击后直达端内，减少跳转流失人群，提升转化率。主要可用于电商节（双十一、618等大促）、优惠券、活动通知、会员活动等',
          ],
          bottomSpan: ['订单查询', '活动促销', '会员管理'],
        },
        {
          topIcon: require('../../assets/img/product/wisdomMedia/part4-icon4.png'),
          topIconOver: require('../../assets/img/product/wisdomMedia/part4-icon4-over.png'),
          topText: '银行金融行业',
          bottomImg: [
            require('../../assets/img/product/wisdomMedia/part4-img4-1.png'),
          ],
          bottomContent: [
            '智媒短信卡片式展示动账交易更简洁易懂，方便快速知晓。同时还支持银行发送的还款通知、理财收益、权益活动等场景使用，将银行服务消息进行升级，打造服务营销闭环投放延续性质的业务，提升投放业务的转化效率',
          ],
          bottomSpan: ['动账交易', '还款通知', '权益活动'],
        },
        {
          topIcon: require('../../assets/img/product/wisdomMedia/part4-icon5.png'),
          topIconOver: require('../../assets/img/product/wisdomMedia/part4-icon5-over.png'),
          topText: '互联网服务行业',
          bottomImg: [
            require('../../assets/img/product/wisdomMedia/part4-img5-1.png'),
          ],
          bottomContent: [
            '互联网服务行业，一般都以打开APP为主，智媒短信可以通过Action能力及菜单等来发送促销活动、位置预定、会员福利等，给服务短信增加营销属性，实现流量变现，降低服务成本。',
          ],
          bottomSpan: ['餐位预订', '会员福利', '会员管理'],
        },
      ],
    };
  },
  mounted() {
    this.slideBanner();
    $('.p4m-major-topItem').eq(0).addClass('active');
    $('.p4m-major-topItem')
      .eq(0)
      .find('img')
      .attr('src', this.p4data[0].topIconOver);
    $('.p4m-major-bottomItem').eq(0).css('display', 'flex');
    // 移动端初始化
    $('.p2mm-pc2 .p2m-major-right span').eq(0).toggleClass('active');
  },
  methods: {
    p2textOver(i) {
      $('.p2mm-pc1 .p2m-major-right span').removeClass('active');
      $('.p2mm-pc1 .p2m-major-item').removeClass('active');
      $('.p2mm-pc1 .p2m-major-right span').eq(i).toggleClass('active');
      $('.p2mm-pc1 .p2m-major-item').eq(i).toggleClass('active');
    },
    p4TopAction(i, iconOver) {
      let p4Obj = {};
      if ($(window).width() > 1200) {
        p4Obj.width = 22;
        p4Obj.move = 25;
      } else {
        p4Obj.width = 0;
        p4Obj.move = 0;
      }
      $('.p4m-major-topLine').width(
        $('.p4m-major-topItem').eq(i).width() + p4Obj.width
      );
      // 初始化
      for (let index = 0; index < this.p4data.length; index++) {
        $('.p4m-major-topItem')
          .eq(index)
          .find('img')
          .attr('src', this.p4data[index].topIcon);
      }
      $('.p4m-major-bottomItem').css('display', 'none');
      $('.p4m-major-topItem').removeClass('active');
      // 设置移动效果
      $('.p4m-major-topLine')
        .stop()
        .animate(
          {
            left:
              $('.p4m-major-topItem').eq(i).position().left -
              $('.p4m-major-topItem').eq(0).position().left +
              p4Obj.move,
          },
          200
        );
      $('.p4m-major-topItem').eq(i).toggleClass('active');
      $('.p4m-major-topItem').eq(i).find('img').attr('src', iconOver);
      $('.p4m-major-bottomItem').eq(i).css('display', 'flex');
    },
    p2mmRClick(i) {
      $('.p2mm-pc2 .p2m-major-right span').removeClass('active');
      $('.p2mm-pc2 .p2m-major-right span').eq(i).toggleClass('active');
      this.$refs.p2mmLeftcarousel.setActiveItem(i);
    },
    p2mmlMobile(val) {
      $('.p2mm-pc2 .p2m-major-right span').removeClass('active');
      $('.p2mm-pc2 .p2m-major-right span').eq(val).toggleClass('active');
    },
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector('.el-carousel__container');
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      };
      //手指按下
      box.addEventListener('touchstart', function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
        console.log(startPoint);
        console.log(e.changedTouches[0].pageX);
      });
      //手指滑动
      box.addEventListener('touchmove', function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this;
      box.addEventListener('touchend', function (e) {
        console.log('1：' + startPoint);
        console.log('2：' + stopPoint);
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.p2mmLeftcarousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.p2mmLeftcarousel.prev();
          return;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/* 第一个部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 723px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      width: 665px;
      height: 704px;
      margin-right: -173px;
      margin-top: 49px;
    }
    .part1-title {
      width: 538px;
      height: 280px;
      span {
        font-family: FontExcellent;
        font-size: 40px;
        color: #333333;
        display: block;
        margin-bottom: 22px;
        letter-spacing: 1px;
      }
      p {
        font-size: 16px;
        color: #666666;
        text-align: justify;
        margin-bottom: 78px;
        line-height: 28px;
      }
      a {
        display: block;
        width: 184px;
        height: 53px;
        background-color: rgb(36, 110, 187);
        border-radius: 7px;
        text-align: center;
        line-height: 53px;
        font-size: 16px;
        color: #f9fbfd;
        letter-spacing: 0.5px;
      }
    }
  }
}
/* 第二个部分 */
.part2 {
  box-sizing: border-box;
  width: 100%;
  height: 997px;
  background: linear-gradient(-88deg, #ffffff 0%, #fafbfd 52%, #ffffff 100%);
  .part2_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    padding-top: 71px;
    > span {
      margin-bottom: 47px;
    }
    > p {
      display: block;
      width: 1110px;
      font-size: 18px;
      line-height: 25px;
      font-weight: 400;
      color: #333333;
      margin-bottom: 110px;
    }
    .p2m-major {
      width: 1100px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .p2m-major-left {
        box-sizing: border-box;
        width: 575px;
        height: 555px;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .p2m-major-item {
          box-sizing: border-box;
          width: 575px;
          padding-left: 22px;
          padding-bottom: 30px;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          span {
            font-family: FontExcellent;
            font-size: 20px;
            font-weight: 400;
            color: #333333;
            margin-top: 8px;
            margin-bottom: 0px;
          }
          p {
            display: block;
            width: 532px;
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            text-align: justify;
            line-height: 27px;
          }
        }
        .p2m-major-item.active {
          background: linear-gradient(0deg, #ffffff 0%, #f6f9ff 100%);
        }
        .p2m-major-item.active span {
          color: #3176c3;
        }
      }
      .p2m-major-right {
        width: 414px;
        height: 555px;
        position: relative;
        img {
          width: 265px;
          height: 555px;
          position: absolute;
          top: 0;
          right: 0;
        }
        span {
          display: block;
          width: 117px;
          height: 37px;
          background: linear-gradient(-46deg, #3f5164 0%, #47596c 100%);
          border-radius: 5px;
          font-size: 18px;
          font-family: FontExcellent;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 37px;
          position: absolute;
        }
        span::after {
          display: block;
          content: '';
          width: 43px;
          height: 2px;
          background-color: rgb(65, 83, 102);
          margin-top: -20px;
          margin-left: 117px;
        }
        span:nth-of-type(1) {
          top: 97px;
          left: 13px;
        }
        span:nth-of-type(1)::after {
          width: 126px;
        }
        span:nth-of-type(2) {
          top: 180px;
          left: 13px;
        }
        span:nth-of-type(3) {
          top: 315px;
          left: 13px;
        }
        span:nth-of-type(4) {
          top: 448px;
          left: 13px;
        }
        span.active {
          left: 0px;
          width: 130px;
          height: 42px;
          background: linear-gradient(-46deg, #0c63bb 0%, #6392cd 100%);
          margin-top: -3px;
          line-height: 42px;
        }
        span.active::after {
          margin-top: -22px;
          margin-left: 130px;
        }
      }
    }
    .p2mm-pc2 {
      display: none;
    }
  }
}
.p2m-major-item {
  cursor: pointer;
}
.p2m-major-right span {
  cursor: pointer;
}
/* 第三个部分 */
.part3 {
  box-sizing: border-box;
  width: 100%;
  height: 768px;
  background: url('../../assets/img/product/wisdomMedia/part3-bg.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 112px;
  .part-title {
    color: white;
    margin-bottom: 60px;
  }
  .part3_main {
    width: 1111px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .p3-item {
      box-sizing: border-box;
      width: 354px;
      height: 400px;
      background: #ffffff;
      box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 27px;
      div {
        height: 90px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 26px;
      }
      span {
        font-size: 18px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #333333;
        margin-bottom: 22px;
      }
      p {
        display: block;
        width: 268px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 28px;
      }
    }
    .p3-item:hover {
      box-sizing: border-box;
      background: linear-gradient(-24deg, #0c63bb 0%, #6392cd 100%);
      border: 2px solid #ffffff;
      box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.1);
      border-radius: 10px;
      span {
        color: #ffffff;
      }
      p {
        color: #ffffff;
      }
    }
    .p3-item:nth-of-type(1):hover {
      img {
        content: url('../../assets/img/product/wisdomMedia/part3-icon1-over.png');
      }
    }
    .p3-item:nth-of-type(2):hover {
      img {
        content: url('../../assets/img/product/wisdomMedia/part3-icon2-over.png');
      }
    }
    .p3-item:nth-of-type(3):hover {
      img {
        content: url('../../assets/img/product/wisdomMedia/part3-icon3-over.png');
      }
    }
  }
  .p3m-2 {
    display: none;
  }
}
/* 第四个部分 */
.part4 {
  box-sizing: border-box;
  width: 100%;
  height: 1174px;
  background: linear-gradient(-88deg, #ffffff 0%, #fafbfd 52%, #ffffff 100%);
  .part4_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    padding-top: 133px;
    > span {
      margin-bottom: 103px;
    }
    .p4m-major {
      position: relative;
      width: 1100px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      > img {
        position: absolute;
        top: 220px;
        right: -410px;
        z-index: 1;
        width: 510px;
        height: 571px;
      }
      .p4m-major-top {
        box-sizing: border-box;
        width: 100%;
        height: 56px;
        padding: 0px 25px;
        border-bottom: 1px solid #cecece;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-bottom: 60px;
        .p4m-major-topLine {
          width: 208px;
          height: 0px;
          position: absolute;
          bottom: 0;
          left: 25px;
          background: #1266bc;
        }
        .p4m-major-topItem {
          padding: 0px 5px;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          img {
            margin-right: 10px;
          }
          span {
            font-size: 18px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #333333;
          }
        }
        .p4m-major-topItem.active span {
          color: #1267bc;
        }
      }
      .p4m-major-bottom {
        width: 1112px;
        height: 597px;
        z-index: 2;
        .p4m-major-bottomItem {
          box-sizing: border-box;
          width: 1112px;
          height: 597px;
          padding-top: 76px;
          padding-left: 42px;
          padding-right: 62px;
          background: #ffffff;
          box-shadow: 35px 38px 40px 0px rgba(134, 134, 134, 0.04);
          border-radius: 15px;
          display: none;
          flex-direction: row;
          justify-content: space-between;
          .p4m-major-bottomLeft {
            width: 490px;
            height: 480px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            img:first-child {
              width: 229px;
              height: 457px;
            }
            img:last-child {
              width: 250px;
              height: 464px;
            }
            .mobileImg {
              display: none;
            }
          }
          .p4m-major-bottomRight {
            box-sizing: border-box;
            width: 490px;
            height: 430px;
            padding-top: 11px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .p4m-firt-div {
              padding-left: 25px;
              display: flex;
              flex-direction: column;
              > span {
                display: block;
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                margin-bottom: 64px;
                letter-spacing: -0.2px;
                margin-left: -25px;
              }
              p {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                line-height: 26px;
                margin-bottom: 4px;
                position: relative;
              }
              p::after {
                display: block;
                content: '';
                width: 9px;
                height: 9px;
                background: linear-gradient(-40deg, #0c63bb 0%, #6392cd 100%);
                border-radius: 50%;
                position: absolute;
                top: 9px;
                left: -25px;
              }
            }
            .p4m-last-div {
              width: 100%;
              display: flex;
              flex-direction: column;
              div {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 32px;
              }
              span {
                display: block;
                width: 146px;
                height: 46px;
                background: linear-gradient(267deg, #0c63bb 0%, #6392cd 100%);
                border-radius: 7px;
                text-align: center;
                line-height: 46px;
                font-size: 16px;
                font-weight: 400;
                color: #ffffff;
              }
              > a {
                font-size: 14px;
                font-family: FontExcellent;
                font-weight: 400;
                color: #3e7ec6;
                margin-left: 8px;
                :hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
.p4m-major-topItem {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  /* 第一个部分 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.6rem;
      > img {
        width: 3.82rem;
        height: 4.05rem;
        margin-right: 0px;
        margin-top: 0px;
      }
      .part1-title {
        width: auto;
        height: auto;
        margin-left: 0.22rem;
        span {
          font-size: 0.4rem;
          font-weight: 400;
          margin-bottom: 0rem;
        }
        p,
        a {
          display: none;
        }
      }
    }
  }
  /* 第二个部分 */
  .part2 {
    height: 11.26rem;
    .part2_main {
      width: 6.15rem;
      padding-top: 0.34rem;
      > span {
        margin-bottom: 0.47rem;
      }
      > p {
        width: 100%;
        font-size: 0.18rem;
        line-height: 0.27rem;
        margin-bottom: 0.45rem;
        text-align: justify;
      }
      .p2m-major {
        width: 100%;
        display: block;
        .p2m-major-left {
          box-sizing: border-box;
          width: 6.12rem;
          margin: 0 auto;
          height: auto;
          padding-top: 0.67rem;
          display: block;
          .p2m-major-item {
            box-sizing: border-box;
            width: 6.12rem;
            padding-left: 0px;
            padding-bottom: 0px;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            align-items: center;
            span {
              display: block;
              width: 5.32rem;
              font-size: 0.2rem;
              color: #3176c3;
              margin-top: 0px;
              margin-bottom: 0.1rem;
            }
            p {
              width: 5.32rem;
              font-size: 0.16rem;
              line-height: 0.24rem;
            }
          }
        }
        .p2m-major-right {
          width: 4.14rem;
          height: 5.55rem;
          margin-left: 0.61rem;
          position: relative;
          img {
            width: 2.65rem;
            height: 5.55rem;
            position: absolute;
            top: 0;
            right: 0;
          }
          span {
            width: 1.17rem;
            height: 0.37rem;
            border-radius: 0.05rem;
            font-size: 0.18rem;
            line-height: 0.37rem;
          }
          span::after {
            width: 0.43rem;
            height: 0.02rem;
            margin-top: -0.2rem;
            margin-left: 1.17rem;
          }
          span:nth-of-type(1) {
            top: 0.97rem;
            left: 0.13rem;
          }
          span:nth-of-type(1)::after {
            width: 1.26rem;
          }
          span:nth-of-type(2) {
            top: 1.8rem;
            left: 0.13rem;
          }
          span:nth-of-type(3) {
            top: 3.15rem;
            left: 0.13rem;
          }
          span:nth-of-type(4) {
            top: 4.48rem;
            left: 0.13rem;
          }
          span.active {
            left: 0px;
            width: 1.3rem;
            height: 0.42rem;
            background: linear-gradient(-46deg, #0c63bb 0%, #6392cd 100%);
            margin-top: -0.03rem;
            line-height: 0.42rem;
          }
          span.active::after {
            margin-top: -0.22rem;
            margin-left: 1.3rem;
          }
        }
      }
      .p2mm-pc1 {
        display: none;
      }
      .p2mm-pc2 {
        display: block;
      }
    }
  }
  /* 第三个部分 */
  .part3 {
    height: 6.88rem;
    background: url('../../assets/img/product/wisdomMedia/part3-bg.png')
      no-repeat;
    background-size: 100% 100%;
    padding-top: 0.58rem;
    .part-title {
      color: white;
      margin-bottom: 0.63rem;
    }
    .part3_main {
      width: 4.5rem;
      margin: 0 auto;
      display: block;
      .p3-item {
        box-sizing: border-box;
        width: 3.54rem;
        height: 4rem;
        background: #ffffff;
        box-shadow: 0rem 0.3rem 0.21rem 0rem rgba(196, 197, 199, 0.1);
        border-radius: 0.1rem;
        padding-top: 0.27rem;
        margin-left: 0.48rem;
        div {
          height: 0.9rem;
          margin-bottom: 0.26rem;
          img {
            height: 0.9rem;
          }
        }
        span {
          font-size: 0.18rem;
          margin-bottom: 0.22rem;
        }
        p {
          width: 2.68rem;
          font-size: 0.16rem;
          line-height: 0.28rem;
        }
      }
      .p3-item:hover {
        border: 0.02rem solid #ffffff;
        box-shadow: 0px 0.3rem 0.21rem 0px rgba(196, 197, 199, 0.1);
        border-radius: 0.1rem;
        span {
          color: #ffffff;
        }
        p {
          color: #ffffff;
        }
      }
      .swiper-item:nth-of-type(1):hover {
        img {
          content: url('../../assets/img/product/wisdomMedia/part3-icon1-over.png');
        }
      }
      .swiper-item:nth-of-type(2):hover {
        img {
          content: url('../../assets/img/product/wisdomMedia/part3-icon2-over.png');
        }
      }
      .swiper-item:nth-of-type(3):hover {
        img {
          content: url('../../assets/img/product/wisdomMedia/part3-icon3-over.png');
        }
      }
    }
    .p3m-1 {
      display: none;
    }
    .p3m-2 {
      display: block;
    }
  }
  /* 第四个部分 */
  .part4 {
    height: 7.11rem;
    .part4_main {
      width: 6.5rem;
      padding-top: 0.63rem;
      > span {
        margin-bottom: 0.3rem;
      }
      .p4m-major {
        width: 100%;
        > img {
          display: none;
        }
        .p4m-major-top {
          width: 100%;
          height: 0.9rem;
          padding: 0px 0rem;
          border-bottom: 1px solid #cecece;
          margin-bottom: 0.42rem;
          .p4m-major-topLine {
            width: 1.66rem;
            left: 0rem;
          }
          .p4m-major-topItem {
            padding: 0px 0px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              max-height: 0.3rem;
              margin-right: 0px;
              margin-bottom: 0.15rem;
            }
            span {
              font-size: 0.18rem;
            }
          }
          .p4m-major-topItem.active span {
            color: #1267bc;
          }
        }
        .p4m-major-bottom {
          width: 6.42rem;
          height: 4.43rem;
          z-index: 2;
          .p4m-major-bottomItem {
            width: 100%;
            height: 100%;
            padding-top: 0.4rem;
            padding-left: 0.24rem;
            padding-right: 0.32rem;
            background: #ffffff;
            box-shadow: 0.35rem 0.38rem 0.4rem 0rem rgba(134, 134, 134, 0.04);
            border-radius: 0.15rem;
            .p4m-major-bottomLeft {
              width: 1.72rem;
              height: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
              margin-right: 0.2rem;
              img {
                width: 1.72rem !important;
                height: 3.43rem !important;
              }
              > img {
                display: none;
              }
              .mobileImg {
                display: block;
                width: 100%;
              }
            }
            .p4m-major-bottomRight {
              flex: 1;
              box-sizing: border-box;
              width: auto;
              height: 3.55rem;
              padding-top: 0.11rem;
              .p4m-firt-div {
                padding-left: 0rem;
                display: flex;
                flex-direction: column;
                p {
                  font-size: 0.16rem;
                  line-height: 0.24rem;
                  margin-bottom: 0.04rem;
                }
                p::after {
                  display: none;
                }
              }
              .p4m-last-div {
                width: 100%;
                display: flex;
                flex-direction: column;
                div {
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  margin-bottom: 0.32rem;
                }
                span {
                  display: block;
                  width: 1.2rem;
                  height: 0.46rem;
                  background: linear-gradient(267deg, #0c63bb 0%, #6392cd 100%);
                  border-radius: 0.07rem;
                  line-height: 0.46rem;
                  font-size: 0.16rem;
                }
                > a {
                  font-size: 0.14rem;
                  margin-left: 0.08rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
// 第一个轮播图
.p2m-major-left {
  .el-carousel__container {
    height: 1.75rem;
    .el-carousel__arrow--left {
      width: 0.36rem;
      height: 0.36rem;
      left: 0px;
    }
    .el-carousel__arrow--right {
      width: 0.36rem;
      height: 0.36rem;
      right: 0px;
    }
  }
  .el-carousel__indicator--horizontal {
    padding: 0.08rem;
  }
  .el-carousel__button {
    width: 0.14rem;
    height: 0.14rem;
    background: #46515f;
    border-radius: 50%;
  }
  .el-carousel__indicator.is-active button {
    background: #1864ba;
  }
}
// 第二个轮播图
.WisdomMedia {
  .swiper_bg_blue {
    width: 4.5rem;
    top: auto;
    margin-top: 0.45rem;
  }
}
// 第三个轮播图
.p4m-major-bottomLeft {
  .el-carousel__container {
    height: 3.91rem;
  }
  .el-carousel__indicator--horizontal {
    padding: 0.08rem;
  }
  .el-carousel__button {
    width: 0.14rem;
    height: 0.14rem;
    background: #46515f;
    border-radius: 50%;
    opacity: 1;
  }
  .el-carousel__indicator.is-active button {
    background: #1864ba;
  }
}
</style>
