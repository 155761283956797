<template>
  <div class="about">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>关于我们</span>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/about/about/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part-jump">
      <div class="pJ_main">
        <div class="pJ_line"></div>
        <ul>
          <li
            v-for="(item, i) in pJump"
            :key="i"
            @click="pJClick($event, i, item.address)"
          >
            <a href="#" v-text="item.span"></a>
          </li>
        </ul>
      </div>
    </section>
    <section class="part2">
      <span class="part-title" id="part1">企业介绍</span>
      <div class="part2_main reveal-bottom2">
        <div class="p2_p">
          <p>
            北京百悟科技有限公司（以下简称“百悟科技”）成立于2010年，是全球化企业通信专业服务商，也是以5G、互联网、人工智能等高科技为驱动的国家高新技术企业。百悟科技包含北京百悟、香港百悟、新加坡百悟，北京百悟聚焦国内业务，主要为企业客户提供企业短信、企业语音客户服务热线（含95、1010、10195码号）、5G消息、云计算、人工智能等产品及技术支撑。香港百悟和新加坡百悟经营国际业务，为众多出海企业提供国际通讯解决方案。截至目前，百悟科技已经为移动互联网、金融、电商、医疗、交通运输等众多行业客户提供企业通信服务。
          </p>
          <p>
            百悟 · 万悟互联科技产业园是百悟科技全资子公司厦门集微科技有限公司投资建设的以元宇宙为主轴的科技+文化示范园区，总建筑面积约 3.45万㎡，总绿地面积近2500㎡，绿地率为20.01%。其目标是构建以生成式AI为核心引擎的元宇宙全产业链服务能力，围绕“人文科技融合，绿色生态环绕”的理念，打造成一个以智慧办公、科技研发、文化创意等整合式创新服务于一体的办公生活平台。
          </p>
          <p>
            百悟科技是中国三大电信运营商的密切合作伙伴，在企业通信的技术平台、产品服务、行业解决方案等多领域实现了深度合作，并积累了丰富的企业通信服务经验。同时，百悟科技还自主研发了企业通信应用“百悟云”、“百悟人工智能语义分析机器人”等自主知识产权产品，推动通信技术对各个行业的支撑发展。
          </p>
          <p>
            百悟科技构建了一支由高素质人才组成的技术团队，强大的技术研发和运维服务能力创造了行业诸多新的技术突破，赢得了客户和业内同行的广泛认可。
          </p>
          <p>
            自成立以来，百悟科技始终以“上善若水，利万物而不争”为品牌价值导向，以专业的企业通信服务为众多企业客户提供完善的通信支撑。截至目前，百悟已为百度、京东、美团、韵达等诸多知名企业提供企业通信服务，业务覆盖区域广阔。
          </p>
        </div>
        <!-- <div class="p2_video"></div> -->
      </div>
      <div class="part2_down reveal-bottom2">
        <span class="part-title" id="part2">企业文化</span>
        <div class="p2_dMain">
          <div>
            <img
              src="../../assets/img/about/about/part2-dIcon-bg1.png"
              alt=""
            />
          </div>
          <div>
            <div class="p2_dContent">
              <span>企业愿景</span>
              <p>
                构建深度融合的数字化产业生态链平台，赋能10万+企业实现万物互联！
              </p>
            </div>
          </div>
          <div>
            <img
              src="../../assets/img/about/about/part2-dIcon-bg2.png"
              alt=""
            />
          </div>
          <div>
            <div class="p2_dContent active">
              <span>企业使命</span>
              <p>助力企业与用户的链接更简单、更高效、更美好！</p>
            </div>
          </div>
          <div>
            <img
              src="../../assets/img/about/about/part2-dIcon-bg3.png"
              alt=""
            />
          </div>
          <div>
            <div class="p2_dContent active">
              <span>核心价值观</span>
              <div>
                <p
                  v-for="(item, i) in p2Data"
                  :key="i"
                  v-text="item.p"
                  class="p2_dCP"
                  @click="p2Click($event, item)"
                ></p>
              </div>
              <div>
                <p v-for="(item, i) in p2Item.span" :key="i" v-text="item"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="part3">
      <span class="part-title" id="part3">企业发展历程</span>
      <div class="p3_main">
        <div class="p3_up reveal-bottom2">
          <div class="p3_uImg">
            <img
              @click="reduceP3Item"
              src="../../assets/img/about/about/part3-left.png"
              alt=""
            />
          </div>
          <div class="re-p3Roll">
            <div class="roll_line"></div>
            <div class="p3_uContent">
              <div class="p3_uCRoll">
                <p
                  v-for="(item, i) in p3UpTime"
                  :key="i"
                  v-text="item.time"
                  @click="p3TimeClick(item.index)"
                ></p>
              </div>
            </div>
          </div>
          <div class="p3_uImg">
            <img
              @click="addP3Item"
              src="../../assets/img/about/about/part3-right.png"
              alt=""
            />
          </div>
        </div>
        <div class="p3_down reveal-bottom2">
          <div class="p3_dItem" v-for="(item, i) in p3DItemData" :key="i">
            <div v-for="(item2, i2) in item" :key="i2">
              <span v-text="item2.span"></span>
              <p v-html="item2.p"></p>
            </div>
          </div>
          <img src="../../assets/img/about/about/part3-bg-dicon.png" alt="" />
        </div>
      </div>
    </section>
    <section class="part4">
      <span class="part-title" id="part4">荣誉资质</span>
      <div class="part4_main aboutPU4-swiper">
        <div class="part4_up reveal-bottom2">
          <span class="part4-title">企业荣誉</span>
          <swiper :options="swiperOptionP4U" class="p4_pc">
            <swiper-slide
              class="swiper-item"
              v-for="(item, i) in p4UData"
              :key="i"
            >
              <div class="p4-item">
                <div>
                  <img :src="item.img" alt="" />
                </div>
                <span>
                  <p v-text="item.span"></p>
                </span>
              </div>
            </swiper-slide>
          </swiper>
          <swiper :options="swiperOptionP4U2" class="p4_mobile">
            <swiper-slide
              class="swiper-item"
              v-for="(item, i) in p4UData"
              :key="i"
            >
              <div class="p4-item">
                <div>
                  <img :src="item.img" alt="" />
                </div>
                <span>
                  <p v-text="item.span"></p>
                </span>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </div>
        <div class="part4_down reveal-bottom2">
          <span class="part4-title">企业资质</span>
          <swiper :options="swiperOptionP4D" class="p4_pc">
            <swiper-slide
              class="swiper-item"
              v-for="(item, i) in p4DData"
              :key="i"
            >
              <div class="p4-item">
                <div>
                  <img :src="item.img" alt="" />
                </div>
                <span>
                  <p v-text="item.span"></p>
                </span>
              </div>
            </swiper-slide>
          </swiper>
          <swiper :options="swiperOptionP4D2" class="p4_mobile">
            <swiper-slide
              class="swiper-item"
              v-for="(item, i) in p4DData"
              :key="i"
            >
              <div class="p4-item">
                <div>
                  <img :src="item.img" alt="" />
                </div>
                <span>
                  <p v-text="item.span"></p>
                </span>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </div>
      </div>
    </section>
    <section class="part5">
      <span class="part-title" id="part5">社会责任</span>
      <div class="part5_main aboutP5-swiper reveal-bottom2">
        <swiper :options="swiperOptionP5" class="p5_pc">
          <swiper-slide
            class="swiper-item"
            v-for="(item, i) in p5Data"
            :key="i"
          >
            <div class="p5-item">
              <img :src="item.img" alt="" />
              <span v-html="item.span"></span>
            </div>
          </swiper-slide>
        </swiper>
        <swiper :options="swiperOptionP52" class="p5_mobile">
          <swiper-slide
            class="swiper-item"
            v-for="(item, i) in p5Data"
            :key="i"
          >
            <div class="p5-item">
              <img :src="item.img" alt="" />
              <span v-html="item.span"></span>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>
    </section>
    <section class="part6">
      <span class="part-title" id="part6">联系我们</span>
      <div class="part6_main">
        <div class="part6_up reveal-bottom2">
          <div class="part6-uItem" v-for="(item, i) in p6UData" :key="i">
            <div>
              <img :src="item.img" alt="" />
              <span v-text="item.span"></span>
            </div>
            <p v-html="item.p"></p>
          </div>
        </div>
        <div class="part6_down reveal-bottom2">
          <MapContainer></MapContainer>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import MapContainer from '@/components/MapContainer.vue';
import Utils from '../../assets/js/util.js';
export default {
  data() {
    return {
      pJump: [
        {
          span: '企业介绍',
          address: '.part2 .part-title',
        },
        {
          span: '企业文化',
          address: '.part2_down',
        },
        {
          span: '企业发展历程',
          address: '.part3 > .part-title',
        },
        {
          span: '荣誉资质',
          address: '.part4',
        },
        {
          span: '社会责任',
          address: '.part5',
        },
        {
          span: '联系我们',
          address: '.part6',
        },
      ],
      p2Data: [
        {
          p: '利他共赢',
          span: [
            '成人达己，成己达人！',
            '赋能同伴，就是成就我们自己！',
            '时刻帮助客户，与客户同发展，共进步！',
          ],
        },
        {
          p: '开放求真',
          span: [
            '求同存异，择善而从，勇敢拥抱变化！',
            '凡事大胆假设，小心求证，快速迭代进化！',
            '工作中敢于暴露问题，实事求是，绝不弄虚作假！',
          ],
        },
        {
          p: '结果导向',
          span: [
            '心中有目标，行动有方向！',
            '工作从我开始，问题到我为止！',
            '不为自己找借口，方法总比困难多！',
          ],
        },
        {
          p: '进取创新',
          span: [
            '敢于打破常规，推陈出新！',
            '敢于自我否定，勇于挑战极限！',
            '迎难而上，永不言弃！追求卓越，精益求精！',
          ],
        },
        {
          p: '协同高效',
          span: [
            '同心协力，打破壁垒，化繁为简！',
            '积极沟通，快速响应，敏捷应对！',
            '背靠背，共进退！他不在，我补位！',
          ],
        },
      ],
      p2Item: {},
      // p3初始化
      p3UpTime: [
        {
          time: '2025',
          index: 0,
        },
        {
          time: '2024',
          index: 1,
        },
        {
          time: '2023',
          index: 2,
        },
        {
          time: '2022',
          index: 3,
        },
        {
          time: '2021',
          index: 4,
        },
        {
          time: '2020',
          index: 5,
        },
        {
          time: '2019',
          index: 6,
        },
        {
          time: '2018',
          index: 7,
        },
        {
          time: '2017',
          index: 8,
        },
        {
          time: '2016',
          index: 9,
        },
        {
          time: '2015',
          index: 10,
        },
        {
          time: '2014',
          index: 11,
        },
        {
          time: '2013',
          index: 12,
        },
        {
          time: '2012',
          index: 13,
        },
        {
          time: '2010',
          index: 14,
        }
      ],
      p3DItemData: [
        [
          {
            span: '2025年1月',
            p: '百悟科技品牌商标“百悟”成功入选《北京重点商标保护名录》'
          }
        ],
        [
          {
            span: '2024年1月',
            p: '百悟科技向京华基金会总捐款256万元，荣获京华基金会“突出贡献奖”和“慈善大使”称号；'
          },
          {
            span: '2024年5月',
            p: '出席第三届中英企业家峰会，再绘全球布局新篇章'
          },
          {
            span: '2024年6月',
            p: '出席中国-巴基斯坦友好和商务招待会；百悟科技携前沿通信解决方案亮相2024 MWC上海峰会；百悟5G多消息融合通讯平台荣获2024年度优秀软件产品奖'
          },
          {
            span: '2024年8月',
            p: '百悟香港国际有限公司获得香港通讯事务管理局（OFCA）颁发的RSSP（注册短信服务提供商）资质'
          },
          {
            span: '2024年9月',
            p: '出席第十七届世界华商大会'
          },
          {
            span: '2024年10月',
            p: '百悟科技考察团赴日参加日本总部交流会议，深化中日合作；百悟科技携AI融合通信解决方案亮相2024中国移动全球合作伙伴大会'
          },
          {
            span: '2024年11月',
            p: '百悟科技携AI数字人解决方案亮相新加坡金融科技节'
          }
        ],
        [
          {
            span: '2023年5月',
            p: '马来西亚华总总会长吴添泉一行莅临百悟科技洽谈交流'
          },
          {
            span: '2023年7月',
            p: '出席第十六届世界华商大会，共谋全球化发展及“一带一路”新机遇'
          },
          {
            span: '2023年9月',
            p: '泰中文化委员会、法院班、皇家学院代表团访华招待会，百悟科技董事长张瑞海与泰国前副总理披尼·扎禄颂巴共商“一带一路”合作机遇'
          },
          {
            span: '2023年10月',
            p: '中国互联网企业综合实力指数（2023）发布，百悟科技入围成长型前20家企业'
          },
          {
            span: '2023年11月',
            p: '参加2023“韩国-世界华商周”'
          },
          {
            span: '2023年12月',
            p: '参加贺中国澳门特区回归二十四周年盛典'
          }
        ],
        [
          {
            span: '2022年1月',
            p: '受邀参观中国联通总部创新体验中心'
          },
          {
            span: '2022年2月',
            p: '出席“北京·预见未来：2022元宇宙应用场景”闭门研讨会'
          },
          {
            span: '2022年4月',
            p: '受邀出席5G消息团标编制研讨会；参与国内首个“智能营销产品评估模型”标准制定；全资子公司集微科技上榜福建软件与信息技术服务业50强'
          },
          {
            span: '2022年5月',
            p: '参与智能营销系列标准（MA、CMP）研制工作；百悟科技董事长张瑞海参加第二界元宇宙大会圆桌对话'
          }
        ],
        [
          {
            span: '2021年2月',
            p: '布局区块链技术与企业通信产品融合发展场景；获评北京市诚信创建企业'
          },
          {
            span: '2021年3月',
            p: '百悟科技受邀出席短信工作委员会主任单位座谈会、5G消息工作组成立大会，成为中国通信企业协会增值专委会短信工作委员会副主任单位'
          },
          {
            span: '2021年5月',
            p: '承办5G消息工作组候选理事单位座谈会、呼叫中心企业座谈会；获评北京市专精特新“小巨人”企业'
          },
          {
            span: '2021年6月',
            p: '百悟统一消息客户端平台获“2021年度优秀软件产品”荣誉；赴延安展开深入党史学习活动，探索企业通信赋能乡村振兴的数字化解决方案；百悟科技参加中国电信江北数据中心（仪征园区）开服仪式，与中国电信扬州分公司签署战略合作协议'
          },
          {
            span: '2021年7月',
            p: '百悟科技亮相2021（第二十届）中国互联网大会，展出5G消息、物联网、ICT等创新研究成果与应用场景'
          },
          {
            span: '2021年8月',
            p: '支持元宇宙万人在线论坛成功召开'
          },
          {
            span: '2021年9月',
            p: '荣获“2021中国5G实力榜”5G消息企业十强；深度参与支持2021中国品牌节元宇宙论坛；亮相2021年中国国际信息通信展览会（PT展）；全资子公司集微科技获评2021年度厦门市重点软件和信息技术服务企业'
          },
          {
            span: '2021年10月',
            p: '入选2021年北京市诚信兴商宣传月倡议企业；全资子公司集微科技3.45万平米高科技创新产业园区4栋大楼完成封顶'
          },
          {
            span: '2021年12月',
            p: '向横山公益基金会捐款100万元，推动国学文化创新发展；赞助支持并受邀出息2021中国增值电信及虚拟运营商高峰论坛；签署《垃圾信息治理自律公约》；全资子公司集微科技获得2021年福建省互联网企业综合实力评价30强'
          }
        ],
        [
          {
            span: '2020年9月',
            p: '全资子公司集微科技在厦门软件园投资建设高科技创新产业园区'
          }
        ],
        [
          {
            span: '2019年2月',
            p: '百悟科技被成功认定为北京市“2019 - 2021年度知识产权试点单位”'
          }
        ],
        [
          {
            span: '2018年7月',
            p: '在安卓大会上签署共建内容生态倡议并担任统一推送联盟理事'
          }
        ],
        [
          {
            span: '2017年5月',
            p: '在北京顺义成立高级研发中心'
          }
        ],
        [
          {
            span: '2016年2月',
            p: '百悟语音技术平台协助去哪儿网、拉卡拉等众多95码号上线，创造业内新高度'
          }
        ],
        [
          {
            span: '2015年8月',
            p: '百悟科技全资子公司厦门集微科技有限公司成立并入驻厦门集美创业大厦'
          }
        ],
        [
          {
            span: '2014年9月',
            p: '成立青年创业就业基地，为青年就业创业助力'
          }
        ],
        [
          {
            span: '2013年6月',
            p: '百悟科技与香港中信国际电讯公司达成战略合作协议，标志着百悟科技开始走向国际化'
          }
        ],
        [
          {
            span: '2012年12月',
            p: '百悟科技以近1亿元全款购买了融科望京中心整层办公区作为百悟科技北京全球发展中心总部驻地'
          }
        ],
        [
          {
            span: '2010年7月',
            p: '百悟科技成立，专注布局于企业通信业务'
          },
          {
            span: '2010年8月',
            p: '百悟科技与三大运营商签署战略合作协议'
          },
          {
            span: '2010年10月',
            p: '百悟科技基于云主机的集成性网关平台正式上线，业务开始正式涉足云服务领域'
          }
        ]
      ],
      p3UpIndex: 0,
      // p4初始化
      swiperOptionP4U: {
        autoplay: {
          delay: 3500, // 多长时间进行一次自动切换
          disableOnInteraction: false, // 用户点击一次后是否取消自动切换 默认true
        },
        navigation: {
          nextEl: '.aboutPU4-swiper .part4_up .swiper-button-next',
          prevEl: '.aboutPU4-swiper .part4_up .swiper-button-prev',
        },
        loop: true,
        slidesPerView: 3, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      swiperOptionP4U2: {
        autoplay: {
          delay: 3500, // 多长时间进行一次自动切换
          disableOnInteraction: false, // 用户点击一次后是否取消自动切换 默认true
        },
        navigation: {
          nextEl: '.aboutPU4-swiper .part4_up .swiper-button-next',
          prevEl: '.aboutPU4-swiper .part4_up .swiper-button-prev',
        },
        loop: true,
        slidesPerView: 2, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      swiperOptionP4D: {
        autoplay: {
          delay: 3500, // 多长时间进行一次自动切换
          disableOnInteraction: false, // 用户点击一次后是否取消自动切换 默认true
        },
        navigation: {
          nextEl: '.aboutPU4-swiper .part4_down .swiper-button-next',
          prevEl: '.aboutPU4-swiper .part4_down .swiper-button-prev',
        },
        loop: true,
        slidesPerView: 3, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      swiperOptionP4D2: {
        autoplay: {
          delay: 3500, // 多长时间进行一次自动切换
          disableOnInteraction: false, // 用户点击一次后是否取消自动切换 默认true
        },
        navigation: {
          nextEl: '.aboutPU4-swiper .part4_down .swiper-button-next',
          prevEl: '.aboutPU4-swiper .part4_down .swiper-button-prev',
        },
        loop: true,
        slidesPerView: 2, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      p4UData: [
        {
          img: require('../../assets/img/about/about/part4-uIcon1.png'),
          span: '2021年中国5G实力榜5G消息企业十强',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon2.png'),
          span: '诚信兴商宣传月-倡议企业',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon3.png'),
          span: '2021主动亮信企业',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon4.png'),
          span: '北京市诚信创建企业',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon5.png'),
          span: '北京市企业创新信用领跑企业',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon6.png'),
          span: '北京市专精特新小巨人企业证书',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon7.png'),
          span: '北京市专精特新中小企业证书',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon8.png'),
          span: '第四届绽放杯5G应用征集大赛5G消息专题赛行业特色奖',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon22.png'),
          span: '第五届“绽放杯”三等奖',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon23.png'),
          span: '第五届“绽放杯”特色奖',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon9.png'),
          span: '电子商务百强企业',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon10.png'),
          span: '互联网行业最佳解决方案奖',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon11.png'),
          span: '互联网行业最具影响力企业奖',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon12.png'),
          span: '神州英才共建OS系统培训基地',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon13.png'),
          span: '神州英才全球创新管理EDP实践基地',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon14.png'),
          span: '移动运营商年度最佳合作伙伴',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon15.png'),
          span: '优秀软件产品',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon16.png'),
          span: '增值业务最佳市场开拓奖',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon17.png'),
          span: '中国互联网公益奖',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon18.png'),
          span: '中国互联网领军企业',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon19.png'),
          span: '中国新媒体无线营销之星',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon20.png'),
          span: '中国信息产业高成长性企业',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon21.png'),
          span: '中国移动2021年度集团短彩信业务金牌合作伙伴',
        },
      ],
      p4DData: [
        {
          img: require('../../assets/img/about/about/part4-dIcon1.png'),
          span: '2021中关村高新企业新证书',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon2.png'),
          span: '北京市诚信创建企业',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon3.png'),
          span: '2020中国互联网协会会员单位',
        },
        {
          img: require('../../assets/img/about/about/part4-uIcon6.png'),
          span: '北京市专精特新小巨人企业证书',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon4.png'),
          span: '“ISO9001”质量管理体系认证',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon5.png'),
          span: 'ISO20000-信息技术服务管理体系认证',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon6.png'),
          span: 'ISO27001-信息安全管理体系认证',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon7.png'),
          span: '2019高新证书',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon8.png'),
          span: '2021北京百悟5G消息工作组理事单位',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon9.png'),
          span: '2021通信工作组成员单位',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon10.png'),
          span: '北京市用户满意评价证书',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon11.png'),
          span: '第四届常务委员会常务单位',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon12.png'),
          span: '副主任单位',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon13.png'),
          span: '国家高新技术企业证书',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon14.png'),
          span: '企业综合信用评价等级证书-百悟',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon15.png'),
          span: '信用企业承诺',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon16.png'),
          span: '知识产权试点单位',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon18.png'),
          span: '中国互联网协会会员',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon19.png'),
          span: '中国互联网协会理事单位及协会理事（傅竞捷）',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon20.png'),
          span: '中国互联网协会数字孪生技术应用工作委员会-成员单位证书',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon21.png'),
          span: '中国通信协会会员',
        },
        {
          img: require('../../assets/img/about/about/part4-dIcon22.png'),
          span: 'AAA级信用企业',
        },
      ],
      // p5初始化
      swiperOptionP5: {
        autoplay: {
          delay: 3500, // 多长时间进行一次自动切换
          disableOnInteraction: false, // 用户点击一次后是否取消自动切换 默认true
        },
        navigation: {
          nextEl: '.aboutP5-swiper .swiper-button-next',
          prevEl: '.aboutP5-swiper .swiper-button-prev',
        },
        loop: true,
        slidesPerView: 3, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      swiperOptionP52: {
        autoplay: {
          delay: 3500, // 多长时间进行一次自动切换
          disableOnInteraction: false, // 用户点击一次后是否取消自动切换 默认true
        },
        navigation: {
          nextEl: '.aboutP5-swiper .swiper-button-next',
          prevEl: '.aboutP5-swiper .swiper-button-prev',
        },
        loop: true,
        slidesPerView: 2, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      p5Data: [
        {
          img: require('../../assets/img/about/about/part5-icon9.png'),
          span: '向北京横山公益基金会捐赠100万元',
        },
        {
          img: require('../../assets/img/about/about/part5-icon11.png'),
          span: '出席2021中国品牌节第十五届年会',
        },
        {
          img: require('../../assets/img/about/about/part5-icon12.png'),
          span: '出席“融合通信创新”论坛',
        },
        {
          img: require('../../assets/img/about/about/part5-icon7.png'),
          span: '2016—2017年度中国互联网公益奖',
        },
        {
          img: require('../../assets/img/about/about/part5-icon4.png'),
          span: '百悟科技捐赠4000册《悟商》书籍',
        },
        {
          img: require('../../assets/img/about/about/part5-icon3.png'),
          span: '“乡村•中国梦”心爱素拓计划',
        },
        {
          img: require('../../assets/img/about/about/part5-icon1.png'),
          span: '青年创业就业服务基地',
        },
        {
          img: require('../../assets/img/about/about/part5-icon2.png'),
          span: '助力福建农林大学悟商奖助学金',
        },
        // {
        //   img: require('../../assets/img/about/about/part5-icon5.png'),
        //   span: '参与“萤火虫之夜”慈善捐款活动',
        // },
        {
          img: require('../../assets/img/about/about/part5-icon6.png'),
          span: '“我的青春我做主”手拉手公益活动',
        },
        {
          img: require('../../assets/img/about/about/part5-icon8.png'),
          span: '获得“积极贡献”荣誉称号',
        },
        {
          img: require('../../assets/img/about/about/part5-icon10.png'),
          span: '慈善助力帮扶困难群众危房重建',
        },
      ],
      // p6初始化
      p6UData: [
        {
          img: require('../../assets/img/about/about/part6-uIcon1.png'),
          span: '电话',
          p: '010-50950599',
        },
        {
          img: require('../../assets/img/about/about/part6-uIcon2.png'),
          span: '地址',
          p: '北京地址：北京市朝阳区阜通东大街融科望京中心B座9层<br><br>厦门地址：厦门市软件园三期D15栋、D16栋、D17栋、D18栋',
        },
        {
          img: require('../../assets/img/about/about/part6-uIcon3.png'),
          span: '邮箱',
          p: 'supervise@baiwutong.com',
        },
      ],
      p6UDataP1: '010-50950599',
      p6UDataP1s: '010-50950599',
      flag: true,
    };
  },
  components: {
    MapContainer,
  },
  mounted() {
    // 初始化样式
    // 点击块
    $('.pJ_main li').eq(0).addClass('active');
    // p3效果
    $('.p3_uImg').eq(0).css('opacity', 0.5);
    $('.p3_dItem').eq(0).addClass('active');
    // 监控页面
    this.start();
    this.getCode();
    var that = this;
    Utils.$on('demo', function () {
      that.getCode();
    });
  },
  methods: {
    getCode() {
      setTimeout(function () {
        // 获取hash地址信息
        var hash = window.location.hash;
        // 提取井号内容,即id信息
        var id = '#' + hash.split('#').pop();
        if (id != '#/about') {
          // 利用scrollIntoView这个api实现自动跳转
          var top = $(id).offset().top;
          $('html, body').animate({ scrollTop: top }, 500);
        }
      }, 500);
    },
    // 点击块
    pJClick(e, i, address) {
      $('.pJ_main li').removeClass('active');
      $(e.currentTarget).addClass('active');
      $('.pJ_line').animate({ left: 204 * i + 24 + 'px' }, 100);
      $('html,body').animate(
        { scrollTop: $(address).offset().top + 'px' },
        500
      );
    },
    // p3效果
    reduceP3Item() {
      if (this.p3UpIndex > 0) {
        this.throttle('-');
      }
    },
    addP3Item() {
      if (this.p3UpIndex < this.p3UpTime.length - 1) {
        this.throttle('+');
      }
    },
    // 节流操作
    throttle(chance) {
      if (this.flag) {
        this.flag = false;
        const that = this;
        setTimeout(() => {
          switch (chance) {
            case '+':
              that.p3UpIndex++;
              if (that.p3UpIndex >= that.p3UpTime.length - 1) {
                that.p3UpIndex = that.p3UpTime.length - 1;
              }
              break;
            case '-':
              that.p3UpIndex--;
              if (that.p3UpIndex <= 0) {
                that.p3UpIndex = 0;
              }
              break;
            default:
              break;
          }
          that.rollMove(that.p3UpIndex);
          that.flag = true;
        }, 300);
      }
    },
    rollMove(i) {
      // 应该选择第几个元素作为参数移动
      let leftI;
      if ($(window).width() <= 768) {
        leftI = i - 2;
      } else {
        leftI = i - 5;
      }
      // RollMove线移动值
      let RollMove =
        -$('.p3_uCRoll p').eq(leftI).position().left +
        parseInt($('.p3_uCRoll').css('marginLeft'));
      // move圈移动值
      let move =
        $('.p3_uCRoll p').eq(i).position().left +
        ($('.p3_uCRoll p').eq(i).width() - $('.roll_line').width()) / 2;
      if (
        $('.p3_uCRoll p').eq(i).position().left >= $('.p3_uContent').width()
      ) {
        // 向左移动
        move -= $('.p3_uCRoll p').eq(leftI).position().left;
        $('.p3_uCRoll').animate({ marginLeft: RollMove + 'px' }, 300);
        $('.roll_line').animate({ left: move + 'px' }, 300);
      } else if ($('.p3_uCRoll p').eq(i).position().left < 0) {
        // 向右移动
        RollMove =
          parseInt($('.p3_uCRoll').css('marginLeft')) +
          $('.p3_uCRoll p')
            .eq(i + 1)
            .position().left -
          $('.p3_uCRoll p').eq(i).position().left;
        move +=
          $('.p3_uCRoll p')
            .eq(i + 1)
            .position().left - $('.p3_uCRoll p').eq(i).position().left;
        $('.p3_uCRoll').animate({ marginLeft: RollMove + 'px' }, 300);
        $('.roll_line').animate({ left: move + 'px' }, 300);
      } else {
        $('.roll_line').animate({ left: move + 'px' }, 300);
      }
    },
    // p3初始化动画效果
    rollStart() {
      parseInt($('.p3_uCRoll').css('marginLeft', '0px'));
      this.p3UpIndex = 0;
    },
    // p3点击效果
    p3TimeClick(i) {
      this.p3UpIndex = i;
      this.throttle();
    },
    // 监控页面初始化
    start() {
      if ($(window).width() <= 768) {
        $('.roll_line').css('left', '.21rem');
        this.p6UData[0].p = this.p6UDataP1s;
      } else {
        this.p6UData[0].p = this.p6UDataP1;
        $('.roll_line').css('left', '21px');
      }
      const that = this;
      $(window).resize(function () {
        that.rollStart();
        if ($(window).width() <= 768) {
          // 轮播图
          that.p6UData[0].p = that.p6UDataP1s;
          // 进度条
          $('.roll_line').css('left', '.21rem');
        } else {
          $('.p4-item img').css({ width: 'auto' });
          that.p6UData[0].p = that.p6UDataP1s;
          $('.roll_line').css('left', '21px');
        }
      });
      this.p2Item = this.p2Data[0];
      $('.p2_dCP').eq(0).addClass('active');
    },
    p2Click(e, item) {
      this.p2Item = item;
      $('.p2_dCP').removeClass('active');
      $(e.currentTarget).addClass('active');
    },
  },
  watch: {
    p3UpIndex(newName, oldName) {
      $('.p3_dItem').removeClass('active');
      $('.p3_dItem').eq(newName).addClass('active');
      if (newName <= 0) {
        $('.p3_uImg').eq(0).css('opacity', 0.5);
      } else {
        $('.p3_uImg').eq(0).css('opacity', 1);
      }
      if (newName >= this.p3UpTime.length - 1) {
        $('.p3_uImg').eq(1).css('opacity', 0.5);
      } else {
        $('.p3_uImg').eq(1).css('opacity', 1);
      }
      switch (newName) {
          case 4:
          $('.p3_down > img').css('display', 'none');
          break;
          default:
          $('.p3_down > img').css('display', 'block');
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.about {
  /* 第一部分 */
  .part1 {
    box-sizing: border-box;
    width: 100%;
    height: 369px;
    background: linear-gradient(-77deg, #ffffff 0%, #f2f6fa 100%);
    .part1_main {
      box-sizing: border-box;
      width: 1110px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .part1-title {
        span {
          font-family: FontExcellent;
          font-size: 40px;
          color: #333333;
          display: block;
          letter-spacing: 1px;
          margin-top: -54px;
        }
      }
      > img {
        position: absolute;
        width: 749px;
        height: 369px;
        bottom: 0;
        right: -34px;
      }
    }
  }
  // 跳转模块
  .part-jump {
    box-sizing: border-box;
    width: 100%;
    height: 53px;
    background: #ffffff;
    box-shadow: 0px 15px 24px 0px rgba(55, 63, 71, 0.03);
    .pJ_main {
      box-sizing: border-box;
      width: 1224px;
      margin: 0 auto;
      height: 100%;
      position: relative;
      .pJ_line {
        position: absolute;
        bottom: 0px;
        left: 24px;
        width: 163px;
        height: 1px;
        background: #0b62bb;
      }
      ul {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        li {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          position: relative;
          cursor: pointer;
          a {
            font-size: 24px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #333333;
            line-height: 100%;
          }
        }
        li.active {
          a {
            color: #0b62bb;
          }
        }
        li::after {
          display: block;
          content: '';
          position: absolute;
          top: 10px;
          right: 0;
          width: 1px;
          height: 33px;
          background: #bcbcbc;
        }
        li:last-child::after {
          display: none;
        }
      }
    }
  }
  .part2 {
    width: 100%;
    background: #ffffff;
    padding-top: 43px;
    > .part-title {
      margin-bottom: 34px;
    }
    .part2_main {
      width: 1110px;
      margin: 0 auto;
      .p2_p {
        width: 100%;
        margin-bottom: 33px;
        p {
          font-size: 18px;
          font-weight: 400;
          color: #333333;
          line-height: 32px;
          text-align: justify;
          margin-bottom: 15px;
        }
      }
      .p2_video {
        width: 100%;
        height: 490px;
        background: linear-gradient(
          -38deg,
          #ffffff 0%,
          #f2f4fa 52%,
          #ffffff 100%
        );
      }
    }
    .part2_down {
      padding-top: 53px;
      .part-title {
        margin-bottom: 49px;
      }
      .p2_dMain {
        width: 1113px;
        margin: 0 auto;
        display: flex;
        display: grid;
        grid-template-columns: repeat(3, 371px);
        grid-template-rows: repeat(2, 312px);
        margin-bottom: -313px;
        > div {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: linear-gradient(-30deg, #316ebc 0%, #005cb9 100%);
          position: relative;
          > img {
            width: calc(100% + 1px);
            height: calc(100% + 1px);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
          }
          .p2_dContent {
            width: 271px;
            padding-top: 58px;
            span {
              display: block;
              font-size: 24px;
              font-family: FontExcellent;
              font-weight: 400;
              color: #333333;
              margin-bottom: 16px;
            }
            p {
              font-size: 18px;
              font-weight: 400;
              color: #666666;
              line-height: 31px;
              text-align: justify;
            }
            div:nth-of-type(1) {
              margin-bottom: 25px;
              p {
                display: inline;
                margin-right: 25px;
              }
              p:nth-of-type(3) {
                margin-right: 0px;
              }
            }
            div:nth-of-type(2) {
              p {
                font-size: 14px;
                line-height: 23px;
              }
            }
          }
          .p2_dContent:last-child {
            div:nth-of-type(1) {
              p {
                cursor: pointer;
              }
              p.active {
                position: relative;
                font-weight: bold;
              }
              p.active::before {
                display: block;
                content: '';
                position: absolute;
                top: 10px;
                left: -15px;
                width: 5px;
                height: 5px;
                background: #ffffff;
                border-radius: 50%;
              }
              p:nth-of-type(4).active::before {
                top: 41px;
                left: -281px;
              }
            }
          }
          .p2_dContent.active {
            span,
            p {
              color: #ffffff;
            }
          }
        }
        > div:nth-of-type(2) {
          background: #f4f4f6;
        }
        > div:nth-of-type(3) {
          img {
            left: -1px;
          }
        }
      }
    }
  }
  .part3 {
    width: 100%;
    background: url('../../assets/img/about/about/part3-bg.png') no-repeat;
    background-size: 100% 100%;
    padding-top: 379px;
    .part-title {
      color: #ffffff;
      margin-bottom: 46px;
    }
    .p3_main {
      width: 1110px;
      margin: 0 auto;
      .p3_up {
        height: 44px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #909090;
        padding-bottom: 21px;
        margin-bottom: 61px;
        .swiper-item {
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          span {
            font-size: 24px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .p3_uImg {
          height: 44px;
          img {
            width: 44px;
            cursor: pointer;
          }
        }
        .p3_uImg:first-child {
          margin-right: 40px;
        }
        .p3_uImg:last-child {
          margin-left: 40px;
        }
        .re-p3Roll {
          flex: 1;
          position: relative;
          .roll_line {
            width: 29px;
            height: 29px;
            background: url('../../assets/img/about/about/part3-rollLine.png')
              no-repeat;
            background-size: 100% 100%;
            position: absolute;
            bottom: -35px;
            left: 21px;
          }
          .p3_uContent {
            width: 100%;
            overflow: hidden;
            position: relative;
            .p3_uCRoll {
              width: 208%;
              height: 44px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              p {
                font-size: 24px;
                font-family: FontExcellent;
                font-weight: 400;
                color: #ffffff;
                cursor: pointer;
              }
            }
          }
        }
      }
      .p3_down {
        width: 1000px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        > img {
          width: 422px;
          height: 369px;
        }
        .p3_dItem {
          width: 550px;
          height: 700px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          display: none;
          div {
            margin-right: 50px;
          }
          span,
          p {
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
            line-height: 31px;
            text-align: justify;
          }
          p {
            font-weight: 400;
          }
        }
        .p3_dItem.active {
          display: flex;
        }
      }
    }
  }
  .part4 {
    padding-top: 51px;
    .part-title {
      margin-bottom: 58px;
    }
    .part4_main {
      width: 1120px;
      margin: 0 auto;
      .part4-title {
        display: block;
        font-size: 24px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #666666;
        text-align: center;
        margin-bottom: 34px;
      }
      .swiper-item {
        height: 326px;
        .p4-item {
          width: 304px;
          height: 326px;
          margin: 0 auto;
          position: relative;
          background: linear-gradient(
            -38deg,
            #ffffff 0%,
            #f2f4fa 52%,
            #ffffff 100%
          );
          > div {
            width: 100%;
            height: 280px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            img {
              z-index: 3;
            }
          }
          span {
            z-index: 2;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('../../assets/img/about/about/part4-item-bg.png')
              no-repeat;
            background-size: 100% 100%;
            text-align: end;
            p {
              box-sizing: border-box;
              width: 100%;
              height: 65px;
              font-size: 16px;
              font-weight: 400;
              color: #ffffff;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              text-align: center;
              padding: 0px 25px;
            }
          }
        }
      }
      .part4_up,
      .part4_down {
        position: relative;
        padding: 0px 72px;
      }
      .part4_up {
        margin-bottom: 48px;
      }
      .part4_down {
        margin-bottom: 61px;
      }
    }
    .p4_mobile {
      display: none;
    }
  }
  .part5 {
    width: 100%;
    background: url('../../assets/img/about/about/part5-bg.png') no-repeat;
    background-size: 100% 100%;
    padding-top: 82px;
    padding-bottom: 199px;
    .part-title {
      color: #ffffff;
      margin-bottom: 111px;
    }
    .part5_main {
      width: 1264px;
      margin: 0 auto;
      padding: 0 72px;
      position: relative;
      .swiper-item {
        height: 288px;
        .p5-item {
          width: 346px;
          height: 288px;
          background: #ffffff;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          img {
            width: 346px;
            height: 232px;
          }
          span {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex: 1;
            font-size: 18px;
            font-weight: 400;
            color: #333333;
            padding: 0px 25px;
            text-align: center;
          }
        }
      }
    }
    .p5_mobile {
      display: none;
    }
  }
  .part6 {
    width: 100%;
    padding-top: 75px;
    .part-title {
      margin-bottom: 65px;
    }
    .part6_main {
      width: 1109px;
      margin: 0 auto;
      .part6_up {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .part6-uItem {
          width: 275px;
          height: 350px;
          display: flex;
          flex-direction: column;
          align-items: center;
          > div {
            box-sizing: border-box;
            width: 146px;
            height: 124px;
            background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 25px;
            margin-bottom: 51px;
            img {
              height: 37px;
              margin-bottom: 21px;
            }
            span {
              font-size: 18px;
              font-weight: bold;
              color: #ffffff;
            }
          }
          p {
            font-size: 18px;
            font-weight: 400;
            color: #333333;
            text-align: center;
          }
        }
      }
      .part6_down {
        width: 100%;
        height: 609px;
        margin-bottom: 145px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .about {
    /* 第一部分 */
    .part1 {
      height: 4.28rem;
      background: linear-gradient(64deg, #ffffff 0%, #f2f6fa 100%);
      .part1_main {
        width: 6.14rem;
        > img {
          content: url('../../assets/img/about/about/mobile/part1-icon.png');
          width: 5.85rem;
          height: 4.1rem;
          bottom: 0px;
          right: -0.68rem;
        }
      }
      .part1-title {
        span {
          font-size: 0.4rem !important;
          margin-top: 0rem !important;
        }
      }
    }
    .part-jump {
      display: none;
    }
    .part2 {
      padding-top: 0.51rem;
      > .part-title {
        margin-bottom: 0.44rem;
      }
      .part2_main {
        width: 6.07rem;
        .p2_p {
          width: 100%;
          margin-bottom: 0.07rem;
          p {
            font-size: 0.19rem;
            line-height: 0.33rem;
            margin-bottom: 0.31rem;
          }
        }
        .p2_video {
          width: 100%;
          height: 3.4rem;
          background: linear-gradient(
            -38deg,
            #ffffff 0%,
            #f2f4fa 52%,
            #ffffff 100%
          );
        }
      }
      .part2_down {
        padding-top: 0.54rem;
        .part-title {
          margin-bottom: 0.6rem;
        }
        .p2_dMain {
          width: 6.18rem;
          grid-template-columns: repeat(3, 2.06rem);
          grid-template-rows: repeat(2, 2.37rem);
          margin-bottom: -2.37rem;
          > div {
            > img {
              width: 100%;
              height: 100%;
            }
            .p2_dContent {
              width: 1.52rem;
              padding-top: 0.49rem;
              span {
                display: block;
                font-size: 0.25rem;
                margin-bottom: 0.08rem;
              }
              p {
                font-size: 0.15rem;
                line-height: 0.25rem;
                text-align: justify;
              }
              div:nth-of-type(1) {
                margin-bottom: 0px;
                p {
                  margin-right: 0.28rem;
                }
                p:nth-of-type(3) {
                  margin-right: 0.28rem;
                }
                p:nth-of-type(2) {
                  margin-right: 0rem;
                }
                p:nth-of-type(4) {
                  margin-right: 0rem;
                }
              }
              div:nth-of-type(2) {
                display: none;
              }
            }
            .p2_dContent:last-child {
              div:nth-of-type(1) {
                p {
                  font-weight: 400;
                }
                p.active {
                  font-weight: 400;
                }
                p.active::before {
                  display: none;
                }
              }
            }
          }
          > div:nth-of-type(3) {
            img {
              left: 0px;
            }
          }
        }
      }
    }
    .part3 {
      padding-top: 3.19rem;
      .part-title {
        margin-bottom: 0.6rem;
      }
      .p3_main {
        width: 6.2rem;
        padding-bottom: 1rem;
        .p3_up {
          width: 100%;
          height: 0.44rem;
          padding-bottom: 0.28rem;
          margin-bottom: 0.65rem;
          .p3_uImg {
            height: 0.44rem;
            img {
              width: 0.44rem;
            }
          }
          .p3_uImg:first-child {
            margin-right: 0.24rem;
          }
          .p3_uImg:last-child {
            margin-left: 0.24rem;
          }
          .re-p3Roll {
            width: calc(100% - 1.36rem);
            .roll_line {
              width: 0.29rem;
              height: 0.29rem;
              background: url('../../assets/img/about/about/part3-rollLine.png')
                no-repeat;
              background-size: 100% 100%;
              bottom: -0.44rem;
              left: 0.21rem;
            }
            .p3_uContent {
              .p3_uCRoll {
                width: 472%;
                height: 0.44rem;
                p {
                  font-size: 0.24rem;
                }
              }
            }
          }
        }
        .p3_down {
          width: 5.5rem;
          > img {
            display: none !important;
          }
          .p3_dItem {
            width: 5.5rem;
            height: 5rem;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            display: none;
            overflow: auto;
            div {
              margin-right: 0rem;
            }
            span,
            p {
              font-size: 0.18rem;
              font-weight: bold;
              color: #ffffff;
              line-height: 0.31rem;
              text-align: justify;
            }
            p {
              font-weight: 400;
            }
          }
          .p3_dItem.active {
            display: flex;
          }
        }
      }
    }
    .part4 {
      padding-top: 0.55rem;
      .part-title {
        margin-bottom: 0.4rem;
      }
      .part4_main {
        width: 6.6rem;
        .part4-title {
          font-size: 0.24rem;
          margin-bottom: 0.4rem;
        }
        .swiper-item {
          height: 2.63rem;
          .p4-item {
            width: 2.45rem;
            height: 2.63rem;
            background: linear-gradient(
              -38deg,
              #ffffff 0%,
              #f2f4fa 52%,
              #ffffff 100%
            );
            > div {
              height: 2.2rem;
              img {
                max-width: 75%;
                max-height: 85%;
                z-index: 3;
              }
            }
            span {
              width: 100%;
              height: 100%;
              background: url('../../assets/img/about/about/part4-item-bg.png')
                no-repeat;
              background-size: 100% 100%;
              p {
                height: 0.65rem;
                font-size: 0.16rem;
                padding: 0px 0.25rem;
              }
            }
          }
        }
        .part4_up,
        .part4_down {
          position: relative;
          padding: 0px 0.72rem;
        }
        .part4_up {
          margin-bottom: 0.48rem;
        }
        .part4_down {
          margin-bottom: 0.61rem;
        }
      }
      .p4_pc {
        display: none;
      }
      .p4_mobile {
        display: block;
      }
    }
    .part5 {
      width: 100%;
      background: url('../../assets/img/about/about/mobile/part5-bg.png')
        no-repeat;
      background-size: 100% 100%;
      padding-top: 0.59rem;
      padding-bottom: 1.4rem;
      .part-title {
        margin-bottom: 0.7rem;
      }
      .part5_main {
        width: 6.1rem;
        padding: 0 0.72rem;
        .swiper-item {
          height: 2.43rem;
          .p5-item {
            width: 2.93rem;
            height: 2.43rem;
            img {
              width: 100%;
              height: auto;
            }
            span {
              font-size: 0.18rem;
              padding: 0px 0.25rem;
            }
          }
        }
      }
      .p5_pc {
        display: none;
      }
      .p5_mobile {
        display: block;
      }
    }
    .part6 {
      padding-top: 0.54rem;
      .part-title {
        margin-bottom: 0.62rem;
      }
      .part6_main {
        width: 6.2rem;
        .part6_up {
          .part6-uItem {
            width: 1.5rem;
            height: 4.3rem;
            > div {
              width: 1.46rem;
              height: 1.24rem;
              background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
              border-radius: 0.1rem;
              padding-top: 0.25rem;
              margin-bottom: 0.3rem;
              img {
                height: 0.37rem;
                margin-bottom: 0.21rem;
              }
              span {
                font-size: 0.18rem;
              }
            }
            p {
              display: block;
              width: 100%;
              word-wrap: break-word;
              word-break: break-all;
              font-size: 0.18rem;
            }
          }
          .part6-uItem:nth-of-type(2) {
            p {
              width: 140%;
            }
          }
        }
        .part6_down {
          width: 100%;
          height: 3.42rem;
          margin-bottom: 1.02rem;
        }
      }
    }
  }
}
</style>
<style lang="less">
.aboutPU4-swiper {
  .swiper-button-prev,
  .swiper-button-next {
    width: 44px;
    background-size: 44px 44px;
  }
  .swiper-button-prev {
    background-image: url('../../assets/img/about/about/part4-left.png');
  }
  .swiper-button-next {
    background-image: url('../../assets/img/about/about/part4-right.png');
  }
}
.aboutP5-swiper {
  .swiper-button-prev,
  .swiper-button-next {
    width: 44px;
    background-size: 44px 44px;
  }
  .swiper-button-prev {
    background-image: url('../../assets/img/about/about/part5-left.png');
  }
  .swiper-button-next {
    background-image: url('../../assets/img/about/about/part5-right.png');
  }
}
@media screen and (max-width: 768px) {
  .aboutPU4-swiper {
    .swiper-button-prev,
    .swiper-button-next {
      width: 0.44rem;
      background-size: 0.44rem 0.44rem;
    }
  }
  .aboutP5-swiper {
    .swiper-button-prev,
    .swiper-button-next {
      width: 0.44rem;
      background-size: 0.44rem 0.44rem;
    }
    .swiper-button-prev {
      left: 0.25rem;
    }
    .swiper-button-next {
      right: 0.25rem;
    }
  }
}
</style>
