<template>
  <div class="Message955G">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>95 5G消息</span>
          <p>
            95号码属于全网呼叫中心号码，全国统一的稀缺资源。95号码通过落地移动、联通、电信可实现三网语音呼叫服务能力。95
            5G消息是通过落地移动、联通、电信三网5G消息能力，最终实现三网统一号码的5G消息服务能力。
          </p>
          <p>
            95
            5G消息的码号由企业自行提供，百悟科技协助落地三网5G消息能力；同时我司可提供协助企业申请95码号等95全链业务能力。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/message955G/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <PartFlexSwiper
      :pFlexSwiperData="p2Data"
      :pFlexSwiperDatas="p2Datas"
      :pFlexSwiperTitle="p2Title"
    ></PartFlexSwiper>
  </div>
</template>

<script>
import PartFlexSwiper from '@/components/solution/PartFlexSwiper.vue';
export default {
  data() {
    return {
      // p2
      p2Data: [
        {
          img: require('../../assets/img/product/message955G/part2-icon1.png'),
          imgOver: require('../../assets/img/product/message955G/part2-icon1-over.png'),
          span: '资源稀缺',
          p: '95号码，全国唯一，数量有限资源稀缺',
        },
        {
          img: require('../../assets/img/product/message955G/part2-icon2.png'),
          imgOver: require('../../assets/img/product/message955G/part2-icon2-over.png'),
          span: '三网合一',
          p: '支持移动、联通、电信号码，一点接入，全网送达',
        },
        {
          img: require('../../assets/img/product/message955G/part2-icon3.png'),
          imgOver: require('../../assets/img/product/message955G/part2-icon3-over.png'),
          span: '急速稳定',
          p: '通道稳定、海量并发、极速到达',
        },
        {
          img: require('../../assets/img/product/message955G/part2-icon4.png'),
          imgOver: require('../../assets/img/product/message955G/part2-icon4-over.png'),
          span: '快速上线',
          p: '专注移动商务20余年，中国移动、联通、电信深度合作伙伴，丰富代办经验技术对接零障碍',
        },
      ],
      p2Datas: [],
      p2Title: ' 95 5G消息特殊优势',
    };
  },
  components: {
    PartFlexSwiper,
  },
  mounted() {
    // 数据初始化
    this.p2Datas = [
      JSON.parse(JSON.stringify(this.p2Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p2Data.slice(-2))),
    ];
  },
  methods: {},
};
</script>

<style lang="less" scoped>
/* 第一部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 723px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      position: absolute;
      width: 692px;
      height: 630px;
      z-index: -1;
      top: 54px;
      right: -119px;
    }
  }
  .part1-title {
    width: 538px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 35px;
      line-height: 28px;
    }
    p:nth-of-type(2) {
      margin-bottom: 50px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
@media screen and (max-width: 768px) {
  /* 第一部分 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        content: url('../../assets/img/product/message955G/mobile/part1-icon.png');
        width: 4.24rem;
        height: 4.09rem;
        top: 0.19rem;
        right: -0.1rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0px;
        margin-top: -0.62rem;
      }
      p,
      a {
        display: none;
      }
    }
  }
}
</style>
<style lang="less">
.Message955G {
  .partFlexSwiper {
    background: url('../../assets/img/product/message955G/part2-bg.png')
      no-repeat;
    background-size: 100% 100%;
    padding-top: 102px;
    padding-bottom: 60px;
    .part-title {
      margin-bottom: 93px;
    }
    .partFlexSwiper-item {
      width: 250px !important;
      height: 400px !important;
      padding-top: 53px !important;
      > div {
        height: 98px !important;
      }
      p {
        width: 172px !important;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .Message955G {
    .partFlexSwiper {
      height: 6.34rem;
      background: url('../../assets/img/product/message955G/mobile/part2-bg.png')
        no-repeat;
      background-size: 100% 100%;
      padding-top: 0.6rem;
      .part-title {
        margin-bottom: 0.33rem;
      }
      .partFlexSwiper-item {
        width: 2.5rem !important;
        height: 4rem !important;
        padding-top: 0.53rem !important;
        > div {
          height: 0.98rem !important;
        }
        p {
          width: 1.72rem !important;
        }
      }
      .swiper_bg_blue {
        margin-top: 0.31rem;
      }
    }
  }
}
</style>
