<template>
  <div class="fuseMessage">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>融合通信平台</span>
          <p>
            百悟融合通信平台是集成了5G消息、视频短信、行业短信、公众号、APP消息等多种通信能力多元化的PaaS平台，企业或组织在统一的界面，通过简单直观的界面实现消息的制作，实现图文推送、通知触达、业务交互等多场景的B2C沟通服务能力，涵盖社交、金融、电商、新媒体、快递、各种行业。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/fuseMessage/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <span class="part-title">融合通信平台功能介绍</span>
      <div class="part2_main reveal-bottom2 p2_m_pc">
        <div
          class="part2-item"
          v-for="(item, index) in part2Data"
          :key="index"
          @mousemove="part2ItemMove(item, index)"
        >
          <div class="part2-iLeft">
            <div>
              <div>
                <img :src="item.icon" alt="" />
              </div>
              <span>{{ item.span }}</span>
            </div>
          </div>
          <div class="part2-iRight">
            <p>{{ item.p }}</p>
            <p v-html="item.p2"></p>
          </div>
        </div>
      </div>
      <div class="part2_main reveal-bottom2 p2_m_mobile">
        <div
          class="part2-item"
          v-for="(item, index) in part2Data"
          :key="index"
          @click="p2ItemClick(item, index)"
        >
          <div class="part2-iLeft">
            <div>
              <div>
                <img :src="item.icon" alt="" />
              </div>
              <span>{{ item.span }}</span>
            </div>
          </div>
          <div class="part2-iRight">
            <p>{{ item.p }}</p>
            <p v-html="item.p2"></p>
          </div>
        </div>
      </div>
    </section>
    <section class="part3">
      <span class="part-title">百悟融合通信平台简介</span>
      <p>更专业、全站式、一体化多场景解决方案</p>
      <div class="part3-up reveal-bottom">
        <div
          v-for="(item, index) in part3Up"
          :key="index"
          class="part3-uItem"
          @click="part3uItem(index)"
          style="cursor: pointer"
        >
          <span>{{ item.span }}</span>
        </div>
      </div>
      <div class="part3-down">
        <div class="part3-dMain reveal-bottom">
          <div
            class="part3-dItem"
            v-for="(item, index) in part3Down1"
            :key="index"
            @mousemove="p3Move(index, item.imgOver, (chance = 1))"
            @mouseout="p3Move(index, item.img, (chance = 2))"
          >
            <div>
              <img :src="item.img" alt="" />
            </div>
            <span>{{ item.span }}</span>
            <p>{{ item.p }}</p>
          </div>
          <swiper :options="swiperOption">
            <swiper-slide v-for="(item, index) in part3Down1s" :key="index">
              <div class="p3-mobile-dItem">
                <div
                  class="part3-dItem"
                  v-for="(item2, index2) in item"
                  :key="index2"
                  @click="p3MClick($event, item2)"
                >
                  <div>
                    <img :src="item2.img" alt="" />
                  </div>
                  <span>{{ item2.span }}</span>
                  <p>{{ item2.p }}</p>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div
            class="swiper-pagination page01 swiper_bg_blue"
            slot="pagination"
          ></div>
        </div>
        <div class="part3-dMain">
          <p style="margin-bottom: 9px">
            云通讯，安全稳定，助力企业与会员之间即时沟通
          </p>
          <p>
            10000+活跃客户数，秒级到达，10亿+日发送量，30亿+日承载量，100亿年互动次数
          </p>
          <swiper :options="swiperOption2">
            <swiper-slide v-for="(item, index) in part3Down2s" :key="index">
              <div>
                <div
                  @click="part3DItemClick($event)"
                  class="part3-dItem"
                  v-for="(item2, index2) in item"
                  :key="index2"
                  :style="{
                    width: '2.55rem',
                    height: '1.71rem',
                    background:
                      'url(' + item2.bg + ') rgba(0, 92, 185, 0.94) no-repeat',
                    backgroundSize: '100% 100%',
                  }"
                >
                  <span>{{ item2.span }}</span>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="main3-item">
            <div
              class="part3-dItem"
              v-for="(item, index) in part3Down2"
              :key="index"
              @mousemove="part3DItemMove(index)"
              @mouseout="part3DItemOut"
            >
              <span>{{ item.span }}</span>
            </div>
          </div>
          <div
            class="swiper-pagination page02 swiper_bg_blue"
            slot="pagination"
          ></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      part2Data: [
        {
          icon: require('../../assets/img/product/fuseMessage/part2-icon1.png'),
          iconOver: require('../../assets/img/product/fuseMessage/part2-icon1-over.png'),
          span: '通知短信',
          p: '订单通知、会议通知、用户注册、登录验证',
          p2: '订单通知、会议通知<br>用户注册、登录验证',
          bgOver: require('../../assets/img/product/fuseMessage/part2-bg1.png'),
        },
        {
          icon: require('../../assets/img/product/fuseMessage/part2-icon2.png'),
          iconOver: require('../../assets/img/product/fuseMessage/part2-icon2-over.png'),
          span: '会员短信',
          p: '会员服务、新品上线、活动推广、新品祝福',
          p2: '会员服务、新品上线<br>活动推广、新品祝福',
          bgOver: require('../../assets/img/product/fuseMessage/part2-bg2.png'),
        },
        {
          icon: require('../../assets/img/product/fuseMessage/part2-icon3.png'),
          iconOver: require('../../assets/img/product/fuseMessage/part2-icon3-over.png'),
          span: '5G消息',
          p: '以企业chatbot为窗口，构建全新的消息生态',
          p2: '以企业chatbot为窗口<br>构建全新的消息生态',
          bgOver: require('../../assets/img/product/fuseMessage/part2-bg3.png'),
        },
        {
          icon: require('../../assets/img/product/fuseMessage/part2-icon4.png'),
          iconOver: require('../../assets/img/product/fuseMessage/part2-icon4-over.png'),
          span: '智媒消息',
          p: '识别短信核心内容智能转换终端卡片消息，精准营销降本增效',
          p2: '识别短信核心内容智能转换终端卡片消息，精准营销降本增效',
          bgOver: require('../../assets/img/product/fuseMessage/part2-bg4.png'),
        },
        {
          icon: require('../../assets/img/product/fuseMessage/part2-icon5.png'),
          iconOver: require('../../assets/img/product/fuseMessage/part2-icon5-over.png'),
          span: '视频短信',
          p: '图片、动画、音频等信息内容，高效直达，即点即看',
          p2: '图片、动画、音频等信息内容<br>高效直达，即点即看',
          bgOver: require('../../assets/img/product/fuseMessage/part2-bg5.png'),
        },
        {
          icon: require('../../assets/img/product/fuseMessage/part2-icon6.png'),
          iconOver: require('../../assets/img/product/fuseMessage/part2-icon6-over.png'),
          span: '公众号',
          p: '图文信息推送、模板消息接口下发',
          p2: '图文信息推送、模板消息接口下发',
          bgOver: require('../../assets/img/product/fuseMessage/part2-bg6.png'),
        },
        {
          icon: require('../../assets/img/product/fuseMessage/part2-icon7.png'),
          iconOver: require('../../assets/img/product/fuseMessage/part2-icon7-over.png'),
          span: 'APP/邮件',
          p: '统一消息入口，接口下发',
          p2: '统一消息入口，接口下发',
          bgOver: require('../../assets/img/product/fuseMessage/part2-bg7.png'),
        },
      ],
      part3Up: [
        { span: '行业痛点', chance: true },
        { span: '多媒体营销、应用融合解决方案', chance: false },
      ],
      part3Down1: [
        {
          img: require('../../assets/img/product/fuseMessage/part3-icon1.png'),
          imgOver: require('../../assets/img/product/fuseMessage/part3-icon1-over.png'),
          span: '获客成本高',
          p: '单一营销工具，无法快速、便捷的完成用户获取、咨询与转化、售后服务、用户关怀、二次销售、用户挽留等各个环节，造成损失',
        },
        {
          img: require('../../assets/img/product/fuseMessage/part3-icon2.png'),
          imgOver: require('../../assets/img/product/fuseMessage/part3-icon2-over.png'),
          span: '服务体验差',
          p: '随着互联网流量红利的减少，通过传统的互联网方式服务用户变得很艰难，如何提升服务体验，增强客户黏性产生复购',
        },
        {
          img: require('../../assets/img/product/fuseMessage/part3-icon3.png'),
          imgOver: require('../../assets/img/product/fuseMessage/part3-icon3-over.png'),
          span: '品牌宣传弱',
          p: '中小企业的品牌溢价能力非常弱，如何建立专属频道展示自身产品、提高专属企业标识等提高企业知名度',
        },
        {
          img: require('../../assets/img/product/fuseMessage/part3-icon4.png'),
          imgOver: require('../../assets/img/product/fuseMessage/part3-icon4-over.png'),
          span: '缺乏技术支撑',
          p: '企业信息化建设力度不强，技术人员成本高、平台兼容性弱、权限配置、系统布署、维护等各方面问题',
        },
      ],
      part3Down2: [
        {
          span: '文本短信',
          bg: require('../../assets/img/product/fuseMessage/part3-bg1.png'),
        },
        {
          span: 'RCS短信',
          bg: require('../../assets/img/product/fuseMessage/part3-bg2.png'),
        },
        {
          span: '智能短信',
          bg: require('../../assets/img/product/fuseMessage/part3-bg3.png'),
        },
        {
          span: '短信服务号',
          bg: require('../../assets/img/product/fuseMessage/part3-bg4.png'),
        },
        {
          span: '邮箱',
          bg: require('../../assets/img/product/fuseMessage/part3-bg5.png'),
        },
        {
          span: '公众号',
          bg: require('../../assets/img/product/fuseMessage/part3-bg6.png'),
        },
        {
          span: '视频短信',
          bg: require('../../assets/img/product/fuseMessage/part3-bg7.png'),
        },
        {
          span: 'APP',
          bg: require('../../assets/img/product/fuseMessage/part3-bg8.png'),
        },
      ],
      swiperOption: {
        pagination: {
          el: '.page01',
          clickable: true, // 允许点击小圆点跳转
        },
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
        grabCursor: true, //小手掌抓取滑动
        loop: false,
      },
      swiperOption2: {
        pagination: {
          el: '.page02',
          clickable: true, // 允许点击小圆点跳转
        },
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
        loop: false,
        observer: true,
        observeParents: true,
      },
      part3Down1s: [],
      part3Down2s: [],
    };
  },
  mounted() {
    // 状态初始化
    $('.part3-uItem').eq(0).addClass('active');
    $('.part3-dMain').css('display', 'none');
    if ($(window).width() <= 1200) {
      $('.part3-dMain').eq(0).css('display', 'block');
    } else {
      $('.part3-dMain').eq(0).css('display', 'grid');
    }
    const that = this;
    $(window).resize(function () {
      if ($(window).width() <= 768) {
        $('.part3-dMain').css('display', 'none');
        $('.part3-dMain').eq(0).css('display', 'block');
      } else {
        $('.part3-dMain').css('display', 'none');
        $('.part3-dMain').eq(0).css('display', 'grid');
      }
      if (that.part3Up[0].chance == false) {
        $('.part3-dMain').css('display', 'block');
        $('.part3-dMain').eq(0).css('display', 'none');
      }
    });
    // 内容初始化
    this.part3Down1s = [
      JSON.parse(JSON.stringify(this.part3Down1.slice(0, 2))),
      JSON.parse(JSON.stringify(this.part3Down1.slice(-2))),
    ];
    this.part3Down2s = [
      JSON.parse(JSON.stringify(this.part3Down2.slice(0, 4))),
      JSON.parse(JSON.stringify(this.part3Down2.slice(-4))),
    ];
    this.part2ItemMove(this.part2Data[0], 0);
    this.p2ItemClick(this.part2Data[0], 0);
  },
  methods: {
    part2ItemMove(item, index) {
      $('.p2_m_pc .part2-item').removeClass('active');
      $('.p2_m_pc .part2-item')
        .find('.part2-iLeft')
        .css('background', '#ffffff');
      $('.p2_m_pc .part2-item').each((index2, item2) => {
        $(item2)
          .find('.part2-iLeft')
          .find('img')
          .attr('src', this.part2Data[index2].icon);
      });
      $('.p2_m_pc .part2-item').eq(index).addClass('active');
      $('.p2_m_pc .part2-item')
        .eq(index)
        .find('.part2-iLeft')
        .css('background', `url(${item.bgOver})`);
      $('.p2_m_pc .part2-item')
        .eq(index)
        .find('.part2-iLeft')
        .find('img')
        .attr('src', item.iconOver);
    },
    p2ItemClick(item, index) {
      $('.p2_m_mobile .part2-item').removeClass('active');
      $('.p2_m_mobile .part2-item')
        .find('.part2-iLeft')
        .css('background', '#ffffff');
      $('.p2_m_mobile .part2-item').each((index2, item2) => {
        $(item2)
          .find('.part2-iLeft')
          .find('img')
          .attr('src', this.part2Data[index2].icon);
      });
      $('.p2_m_mobile .part2-item').eq(index).addClass('active');
      $('.p2_m_mobile .part2-item')
        .eq(index)
        .find('.part2-iLeft')
        .css('background', `url(${item.bgOver})`);
      $('.p2_m_mobile .part2-item')
        .eq(index)
        .find('.part2-iLeft')
        .find('img')
        .attr('src', item.iconOver);
    },
    // part2ItemOut(item, index) {
    //   $('.part2-item').removeClass('active');
    //   $('.part2-item')
    //     .eq(index)
    //     .find('.part2-iLeft')
    //     .css('background', '#ffffff');
    //   $('.part2-item')
    //     .eq(index)
    //     .find('.part2-iLeft')
    //     .find('img')
    //     .attr('src', item.icon);
    // },
    part3uItem(index) {
      $('.part3-uItem').removeClass('active');
      $('.part3-uItem').eq(index).addClass('active');
      if (index == 0) {
        this.part3Up[0].chance = true;
        if ($(window).width() <= 1200) {
          $('.part3-dMain').css('display', 'none');
          $('.part3-dMain').eq(0).css('display', 'block');
        } else {
          $('.part3-dMain').css('display', 'none');
          $('.part3-dMain').eq(0).css('display', 'grid');
        }
        $('.part3-down').removeClass('active');
      } else {
        this.part3Up[0].chance = false;
        $('.part3-dMain').css('display', 'none');
        $('.part3-dMain').eq(index).css('display', 'block');
        $('.part3-down').removeClass('active');
        $('.part3-down').addClass('active');
      }
    },
    part3DItemMove(index) {
      $('.main3-item .part3-dItem').removeClass('active');
      $('.main3-item .part3-dItem').eq(index).addClass('active');
    },
    part3DItemOut() {
      $('.main3-item .part3-dItem').removeClass('active');
    },
    part3DItemClick(e) {
      $('.part3-dItem').css({
        boxSizing: 'border-box',
        color: '#333333',
        backgroundBlendMode: 'initial',
        border: '0px solid #f7f3e7',
        borderRadius: '0.1rem',
      });
      $(e.currentTarget).css({
        boxSizing: 'border-box',
        color: '#ffffff',
        backgroundBlendMode: 'multiply',
        border: '2px solid #f7f3e7',
        borderRadius: '0.1rem',
      });
    },
    p3Move(i, img, chance) {
      switch (chance) {
        case 1:
          $('.part3-dItem').eq(i).addClass('active');
          break;
        case 2:
          $('.part3-dItem').eq(i).removeClass('active');
          break;
      }
      $('.part3-dItem').eq(i).find('img').attr('src', img);
    },
    p3MClick(e, item) {
      $('.part3-dItem').removeClass('active');
      $('.p3-mobile-dItem .part3-dItem').each((index, item2) => {
        this.part3Down1.find((item3) => {
          if (item3.span == $(item2).children('span').text()) {
            $(item2).find('img').attr('src', item3.img);
          }
        });
      });
      $(e.currentTarget).addClass('active');
      $(e.currentTarget).find('img').attr('src', item.imgOver);
    },
  },
};
</script>

<style lang="less" scoped>
/* 头图样式 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 715px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      width: 747px;
      height: 947px;
      z-index: -1;
      position: absolute;
      top: 56px;
      left: 440px;
    }
  }
  .part1-title {
    width: 480px;
    height: 280px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 78px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
.part2 {
  box-sizing: border-box;
  width: 100%;
  height: 1430px;
  background: url('../../assets/img/product/fuseMessage/part2-bg.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 84px;
  .part-title {
    margin-bottom: 113px;
  }
  .part2_main {
    width: 1084px;
    margin: 0 auto;
    .part2-item {
      width: 1084px;
      height: 153px;
      background: url('../../assets/img/product/fuseMessage/part2-item-bg.png')
        no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: row;
      .part2-iLeft {
        box-sizing: border-box;
        width: 500px;
        height: 151px;
        background: #ffffff;
        border: 0px solid #dcdcdc;
        box-shadow: 5px 12px 7px 0px rgba(55, 63, 71, 0.02);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          div {
            width: 63px;
            height: 61px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 24px;
          }
          span {
            font-size: 18px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #333333;
          }
        }
      }
      .part2-iRight {
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 67px;
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
        p:last-child {
          display: none;
        }
      }
    }
    .part2-item.active {
      .part2-iLeft {
        width: 567px;
        height: 153px;
        box-shadow: 0px 12px 7px 0px rgba(55, 63, 71, 0.02);
        div {
          flex-direction: column;
          div {
            width: auto;
            margin-right: 0px;
            margin-bottom: 17px;
            justify-content: center;
          }
          span {
            font-size: 18px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
      .part2-iRight {
        p {
          font-size: 16px;
          font-family: FontExcellent;
          font-weight: 400;
          color: #005cb9;
        }
      }
    }
  }
  .p2_m_mobile {
    display: none;
  }
}
.part3 {
  padding-top: 88px;
  .part-title {
    margin-bottom: 27px;
  }
  > p {
    font-size: 18px;
    font-weight: 400;
    color: #666666;
    text-align: center;
  }
  .part3-up {
    width: 1084px;
    margin: 0 auto;
    margin-top: 91px;
    display: grid;
    grid-template-columns: 502px 502px;
    grid-template-rows: 128px;
    grid-column-gap: 80px;
    margin-bottom: 89px;
    .part3-uItem {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: linear-gradient(0deg, #ffffff 0%, #e6e6e6 100%);
      opacity: 0.84;
      border-radius: 8px;
      span {
        font-size: 20px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #333333;
      }
    }
    .part3-uItem.active {
      background: linear-gradient(0deg, #ffffff 0%, #d0ddf4 100%);
    }
  }
  .part3-down {
    box-sizing: border-box;
    width: 100%;
    height: 741px;
    background: url('../../assets/img/product/fuseMessage/part3-bg.png')
      no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    .part3-dMain:first-child {
      width: 1084px;
      margin: 0 auto;
      margin-top: 164px;
      display: grid;
      grid-template-columns: repeat(4, 250px);
      grid-template-rows: 400px;
      grid-column-gap: 28px;
      .swiper-container {
        display: none;
      }
      .swiper-pagination {
        display: none;
      }
      .part3-dItem {
        background: #ffffff;
        box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.1);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
        div {
          height: 79px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 15px;
        }
        span {
          font-size: 18px;
          font-family: FontExcellent;
          font-weight: 400;
          color: #333333;
          margin-bottom: 23px;
        }
        p {
          display: block;
          width: 172px;
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          text-align: justify;
          line-height: 27px;
        }
      }
      .part3-dItem.active {
        background: linear-gradient(0deg, #0c63bb 0%, #6392cd 100%);
        border: 2px solid #ffffff;
        span,
        p {
          color: #ffffff;
        }
      }
    }
    .part3-dMain:last-child {
      box-sizing: border-box;
      width: 1078px;
      margin: 0 auto;
      text-align: center;
      padding-top: 103px;
      > p {
        font-family: FontExcellent;
        color: #ffffff;
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
      }
      > p:last-child {
        display: none;
      }
      .swiper-container {
        display: none;
      }
      .swiper-pagination {
        display: none;
      }
      .main3-item {
        margin-top: 94px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 253px);
        grid-template-rows: repeat(2, 169px);
        grid-column-gap: 22px;
        grid-row-gap: 25px;
        .part3-dItem {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          font-family: FontExcellent;
          border-radius: 5px;
          font-size: 20px;
          font-weight: 400;
          color: #333333;
        }
        .part3-dItem:nth-of-type(1) {
          background: url('../../assets/img/product/fuseMessage/part3-bg1.png')
            rgba(0, 92, 185, 0.94) no-repeat;
          background-size: 100% 100%;
        }
        .part3-dItem:nth-of-type(2) {
          background: url('../../assets/img/product/fuseMessage/part3-bg2.png')
            rgba(0, 92, 185, 0.94) no-repeat;
          background-size: 100% 100%;
        }
        .part3-dItem:nth-of-type(3) {
          background: url('../../assets/img/product/fuseMessage/part3-bg3.png')
            rgba(0, 92, 185, 0.94) no-repeat;
          background-size: 100% 100%;
        }
        .part3-dItem:nth-of-type(4) {
          background: url('../../assets/img/product/fuseMessage/part3-bg4.png')
            rgba(0, 92, 185, 0.94) no-repeat;
          background-size: 100% 100%;
        }
        .part3-dItem:nth-of-type(5) {
          background: url('../../assets/img/product/fuseMessage/part3-bg5.png')
            rgba(0, 92, 185, 0.94) no-repeat;
          background-size: 100% 100%;
        }
        .part3-dItem:nth-of-type(6) {
          background: url('../../assets/img/product/fuseMessage/part3-bg6.png')
            rgba(0, 92, 185, 0.94) no-repeat;
          background-size: 100% 100%;
        }
        .part3-dItem:nth-of-type(7) {
          background: url('../../assets/img/product/fuseMessage/part3-bg7.png')
            rgba(0, 92, 185, 0.94) no-repeat;
          background-size: 100% 100%;
        }
        .part3-dItem:nth-of-type(8) {
          background: url('../../assets/img/product/fuseMessage/part3-bg8.png')
            rgba(0, 92, 185, 0.94) no-repeat;
          background-size: 100% 100%;
        }
        .part3-dItem.active {
          color: #ffffff;
          background-blend-mode: multiply;
          border: 2px solid #f7f3e7;
          opacity: 0.94;
          border-radius: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        width: 4.26rem;
        height: 5.41rem;
        top: 0.49rem;
        left: 2.35rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0px;
      }
      p,
      a {
        display: none;
      }
    }
  }
  .part2 {
    height: 8.41rem;
    background: url('../../assets/img/product/fuseMessage/mobile/part2-bg.png')
      no-repeat;
    background-size: 100% 100%;
    padding-top: 0.43rem;
    .part-title {
      margin-bottom: 0.53rem;
    }
    .part2_main {
      width: 6.15rem;
      .part2-item {
        width: 100%;
        height: 0.87rem;
        background: url('../../assets/img/product/fuseMessage/part2-item-bg.png')
          no-repeat;
        background-size: 100% 100%;
        .part2-iLeft {
          width: 2.84rem;
          height: 100%;
          background: #ffffff;
          border: 0px solid #dcdcdc;
          box-shadow: 0rem 0.12rem 0.24rem 0rem rgba(55, 63, 71, 0.02);
          div {
            div {
              width: 0.35rem;
              height: auto;
              margin-right: 0.13rem;
              img {
                width: 0.35rem;
                height: auto;
              }
            }
            span {
              font-size: 0.18rem;
            }
          }
        }
        .part2-iRight {
          padding-left: 0.35rem;
          p {
            display: block;
            width: 2.4rem;
            font-size: 0.16rem;
          }
          p:first-child {
            display: none;
          }
          p:last-child {
            display: block;
          }
        }
      }
      .part2-item.active {
        .part2-iLeft {
          width: 3.22rem;
          height: 100%;
          box-shadow: 0px 0.12rem 0.07rem 0rem rgba(55, 63, 71, 0.02);
          div {
            div {
              height: 0.31rem;
              width: auto;
              margin-right: 0rem;
              margin-bottom: 0.05rem;
              img {
                height: 0.31rem;
                width: auto;
              }
            }
            span {
              font-size: 0.18rem;
            }
          }
        }
        .part2-iRight {
          p {
            font-size: 0.16rem;
          }
        }
      }
    }
    .p2_m_mobile {
      display: block;
    }
    .p2_m_pc {
      display: none;
    }
  }
  .part3 {
    padding-top: 0.6rem;
    .part-title {
      margin-bottom: 0.18rem;
    }
    > p {
      font-size: 0.18rem;
    }
    .part3-up {
      width: 6.32rem;
      margin-top: 0.43rem;
      grid-template-columns: 3.1rem 3.1rem;
      grid-template-rows: 0.92rem;
      grid-column-gap: 0.12rem;
      margin-bottom: 0.62rem;
      .part3-uItem {
        border-radius: 0.08rem;
        span {
          font-size: 0.18rem;
        }
      }
    }
    .part3-down {
      height: 5.63rem;
      background: url('../../assets/img/product/fuseMessage/mobile/part3-bg.png')
        no-repeat;
      background-size: 100% 100%;
      overflow: hidden;
      .part3-dMain:first-child {
        width: 6rem;
        margin: 0 auto;
        margin-top: 0.64rem;
        display: block;
        position: relative;
        > .part3-dItem {
          display: none;
        }
        .swiper-container {
          width: 6rem;
          display: block;
          height: 100%;
        }
        .swiper-pagination {
          display: block;
          left: 0;
          width: 100%;
          bottom: -0.63rem;
        }
        .swiper-slide {
          width: 100%;
          > div {
            width: 5.5rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0px 0.25rem;
            .part3-dItem {
              box-sizing: border-box;
              width: 2.5rem;
              height: 4rem;
              border-radius: 0.1rem;
              box-shadow: 0px 0.3rem 0.21rem 0px rgba(196, 197, 199, 0.1);
              padding-top: 0.5rem;
              div {
                height: 0.79rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 0.15rem;
                img {
                  height: 0.79rem;
                  width: auto;
                }
              }
              span {
                font-size: 0.18rem;
                margin-bottom: 0.25rem;
              }
              p {
                width: 1.72rem;
                font-size: 0.16rem;
                line-height: 0.24rem;
              }
            }
            .part3-dItem.active {
              background: linear-gradient(0deg, #0c63bb 0%, #6392cd 100%);
              border: 0.02rem solid #ffffff;
              span,
              p {
                color: #ffffff;
              }
            }
          }
        }
      }
      .part3-dMain:last-child {
        width: 6rem;
        margin: 0 auto;
        padding-top: 0.45rem;
        position: relative;
        > p {
          font-size: 0.2rem;
          margin-bottom: 0rem !important;
        }
        > p:nth-of-type(2) {
          display: none;
        }
        > p:last-child {
          display: block;
        }
        .swiper-container {
          display: block;
          height: 100%;
          margin-top: 0.41rem;
        }
        .swiper-pagination {
          display: block;
          left: 0;
          width: 100%;
          bottom: -0.63rem;
        }
        .swiper-slide {
          > div {
            display: grid;
            grid-template-columns: repeat(2, 2.55rem);
            grid-template-rows: repeat(2, 1.71rem);
            grid-column-gap: 0.47rem;
            grid-row-gap: 0.34rem;
            padding: 0 0.25rem;
          }
          .part3-dItem {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            font-family: FontExcellent;
            border-radius: 0.05rem;
            font-size: 0.2rem;
            font-weight: 400;
            color: #333333;
          }
          .part3-dItem.active {
            box-sizing: border-box;
            color: #ffffff;
            background-blend-mode: multiply;
            border: 2px solid #f7f3e7;
            opacity: 0.94;
            border-radius: 0.1rem;
          }
        }
        .main3-item {
          display: none;
        }
      }
    }
    .part3-down.active {
      height: 6.38rem;
    }
  }
}
</style>
<style lang="less">
.fuseMessage {
  .swiper_bg_blue {
    width: 6rem;
  }
}
</style>
