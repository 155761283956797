<template>
  <div class="telephoneCard">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>电话名片</span>
          <p>
            是将企业电话号码数据进行处理，通过中国可信号码数据中心权威认证，将企业号码进行智能翻译识别的业务，能够在企业与客户进行来去电沟通时，将企业名称、号码、地址等可信信息展示在用户手机上。该业务通过移动端企业号码品牌，保证企业号码安全有效！
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/telephoneCard/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <span class="part-title">电话名片产品特性</span>
      <div class="part2_main reveal-bottom2">
        <div class="p2-item" v-for="(item, index) in part2Data" :key="index">
          <div>
            <img :src="item.img" alt="" />
          </div>
          <span>{{ item.span }}</span>
        </div>
      </div>
      <div class="part2_main p2-swiper">
        <swiper :options="swiperOption">
          <swiper-slide
            v-for="(item, i) in part2Datas"
            :key="i"
            class="swiper-item"
          >
            <div class="p2-item" v-for="(item2, i2) in item" :key="i2">
              <div>
                <img :src="item2.img" alt="" />
              </div>
              <span>{{ item2.span }}</span>
            </div>
          </swiper-slide>
        </swiper>
        <div
          class="swiper-pagination p2m-pagination swiper_bg_blue"
          slot="pagination"
        ></div>
      </div>
    </section>
    <section class="part3">
      <span class="part-title">电话名片特殊优势</span>
      <div class="part3_main">
        <img
          class="reveal-null"
          src="../../assets/img/product/telephoneCard/part3-icon1.png"
          alt=""
        />
        <img
          class="reveal-null"
          src="../../assets/img/product/telephoneCard/part3-icon2.png"
          alt=""
        />
        <div class="p3-content">
          <div class="p3-item p3-medium reveal-left2">覆盖渠道广</div>
          <img
            class="p3-arrow reveal-left3"
            src="../../assets/img/product/telephoneCard/part3-icon.png"
            alt=""
          />
          <div class="p3-item p3-medium2 reveal-left4">上线时间短</div>
          <img
            class="p3-arrow reveal-left5"
            src="../../assets/img/product/telephoneCard/part3-icon.png"
            alt=""
          />
          <div class="p3-item p3-max reveal-null">售后跟踪</div>
          <div class="p3-item p3-min reveal-right2">
            商务
            <div class="p3-minItem p3-min">市场</div>
            <div class="p3-minItem p3-min">运维</div>
            <div class="p3-minItem p3-min">技术</div>
            <div class="p3-minItem p3-min">客服</div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      part2Data: [
        {
          img: require('../../assets/img/product/telephoneCard/part2-icon1.png'),
          span: '公信力强',
        },
        {
          img: require('../../assets/img/product/telephoneCard/part2-icon2.png'),
          span: '范围广',
        },
        {
          img: require('../../assets/img/product/telephoneCard/part2-icon3.png'),
          span: '适合企业码号推广宣传',
        },
        {
          img: require('../../assets/img/product/telephoneCard/part2-icon4.png'),
          span: '提升企业号码接通率',
        },
        {
          img: require('../../assets/img/product/telephoneCard/part2-icon5.png'),
          span: '降低号码误标记',
        },
      ],
      part2Datas: [],
      // 轮播图参数
      swiperOption: {
        pagination: {
          el: '.p2-swiper .p2m-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false, // 手动切换之后继续自动轮播
        // },
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
    };
  },
  mounted() {
    // 内容初始化
    this.part2Datas = [
      JSON.parse(JSON.stringify(this.part2Data.slice(0, 3))),
      JSON.parse(JSON.stringify(this.part2Data.slice(-2))),
    ];
  },
};
</script>

<style lang="less" scoped>
/* 头图样式 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 715px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      width: 584px;
      height: 652px;
      margin-right: 12px;
      margin-top: 34px;
      z-index: -1;
    }
  }
  .part1-title {
    width: 480px;
    height: 280px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 78px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
.part2 {
  box-sizing: border-box;
  width: 100%;
  height: 570px;
  background: url('../../assets/img/product/telephoneCard/part2-bg.png')
    no-repeat;
  background-size: 100% 100%;
  padding-top: 75px;
  .part-title {
    color: #ffffff;
    margin-bottom: 66px;
  }
  .part2_main {
    width: 1083px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .p2-item {
      box-sizing: border-box;
      width: 211px;
      height: 250px;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 74px;
      div {
        height: 83px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 30px;
      }
      span {
        font-size: 18px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #333333;
      }
    }
    .p2-item:hover {
      span {
        color: #005cb9;
      }
    }
  }
  .part2_main:nth-of-type(2) {
    display: none;
  }
}
.part3 {
  box-sizing: border-box;
  width: 100%;
  height: 1000px;
  padding-top: 67px;
  .part-title {
    margin-bottom: 74px;
  }
  .part3_main {
    width: 751px;
    margin: 0 auto;
    height: 751px;
    background: url('../../assets/img/product/telephoneCard/part3-bg.png')
      no-repeat center center;
    position: relative;
    .p3-content {
      width: 896px;
      height: 232px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 259px;
      left: -239px;
      > img:nth-last-of-type(1) {
        margin-right: 2px;
      }
      .p3-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        font-size: 18px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #ffffff;
      }
      .p3-item::after {
        display: block;
        content: '';
        width: 100%;
        position: absolute;
        font-size: 18px;
        font-family: none;
        font-weight: 400;
        color: #666666;
        text-align: center;
      }
      .p3-medium {
        width: 156px;
        height: 156px;
        background: linear-gradient(130deg, #6c97ce 0%, #005cb9 100%);
        border-radius: 50%;
      }
      .p3-medium::after {
        content: '安卓系统的90%\A以上机型全覆盖';
        white-space: pre;
        top: calc(100% + 23px);
        left: 0px;
      }
      .p3-medium2 {
        width: 175px;
        height: 175px;
        background: linear-gradient(130deg, #6c97ce 0%, #005cb9 100%);
        border-radius: 50%;
      }
      .p3-medium2::after {
        content: '10天工作日';
        top: calc(100% + 14px);
        left: 0px;
      }
      .p3-max {
        width: 232px;
        height: 232px;
        background: linear-gradient(130deg, #6c97ce 0%, #005cb9 100%);
        border-radius: 50%;
      }
      .p3-min {
        width: 114px;
        height: 114px;
        background: linear-gradient(140deg, #6c97ce 0%, #005cb9 100%);
        border-radius: 50%;
        margin-left: 24.5px;
        .p3-minItem {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-family: FontExcellent;
          font-weight: 400;
          color: #ffffff;
          position: absolute;
          top: 0px;
          left: 0px;
        }
        .p3-minItem::after {
          display: block;
          content: '';
          width: 150%;
          position: absolute;
          font-size: 18px;
          font-family: none;
          font-weight: 400;
          color: #666666;
          text-align: center;
        }
        .p3-minItem:nth-of-type(1) {
          top: -225px;
          left: -228px;
        }
        .p3-minItem:nth-of-type(1)::after {
          content: '24小时电话响应';
          top: 10px;
          left: calc(90% - 2px);
        }
        .p3-minItem:nth-of-type(2) {
          top: -160px;
          left: -81px;
        }
        .p3-minItem:nth-of-type(2)::after {
          content: '系统维护';
          top: 40%;
          left: 70%;
        }
        .p3-minItem:nth-of-type(3) {
          top: 160px;
          left: -81px;
        }
        .p3-minItem:nth-of-type(3)::after {
          content: 'ROM对接';
          top: 41%;
          left: calc(70% + 2px);
        }
        .p3-minItem:nth-of-type(4) {
          top: 225px;
          left: -228px;
        }
        .p3-minItem:nth-of-type(4)::after {
          content: '报备档案建立';
          bottom: 10px;
          left: calc(90% - 2px);
        }
      }
      > .p3-min::after {
        width: 150%;
        content: '手机厂商关系协调';
        top: 41%;
        left: calc(100% + 6px);
      }
    }
  }
  .part3_main > img {
    position: absolute;
  }
  .part3_main > img:nth-of-type(1) {
    top: 558px;
    left: 109px;
  }
  .part3_main > img:nth-of-type(2) {
    top: 127px;
    left: 186px;
  }
}
@media screen and (max-width: 768px) {
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        width: 3.82rem;
        height: 4.26rem;
        margin-right: -0.09rem;
        margin-top: 0rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0rem;
        margin-top: -0.72rem;
      }
      p,
      a {
        display: none;
      }
    }
  }
  .part-title {
    font-size: 0.3rem;
    margin-bottom: 0rem;
  }
  .part2 {
    height: 5.52rem;
    background: url('../../assets/img/product/telephoneCard/mobile/part2-bg.png')
      no-repeat;
    background-size: 100% 100%;
    padding-top: 0.31rem;
    .part-title {
      margin-bottom: 0.8rem;
    }
    .part2_main {
      width: 6.54rem;
      .swiper-item {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0rem 0.2rem;
      }
      .swiper-item:nth-of-type(2) {
        justify-content: flex-start;
        .p2-item {
          margin-right: 0.2rem;
        }
      }
      .p2-item {
        width: 1.92rem;
        height: 2.5rem;
        padding-top: 0.74rem;
        div {
          height: 0.78rem;
          margin-bottom: 0.35rem;
          img {
            height: 0.78rem;
          }
        }
        span {
          font-size: 0.18rem;
        }
      }
      .p2-item:hover {
        span {
          color: #005cb9;
        }
      }
    }
    .part2_main:nth-of-type(1) {
      display: none;
    }
    .part2_main:nth-of-type(2) {
      display: block;
    }
  }
  .part3 {
    height: 12.09rem;
    padding-top: 0.49rem;
    .part-title {
      margin-bottom: 3.48rem;
    }
    .part3_main {
      padding: 0.01rem;
      width: 7.52rem;
      height: 7.52rem;
      background: url('../../assets/img/product/telephoneCard/part3-bg.png')
        no-repeat;
      background-size: 100% 100%;
      > img {
        display: none;
      }
      .p3-content {
        width: 2.32rem;
        height: 10.4rem;
        flex-direction: column;
        justify-content: flex-start;
        position: initial;
        margin: 0 auto;
        margin-top: -2.88rem;
        .p3-arrow {
          width: 40px;
          height: 31px;
          transform: rotate(90deg) !important;
        }
        .p3-arrow:nth-last-of-type(1) {
          margin-top: 0.4rem;
          margin-bottom: 0.16rem;
          margin-right: 0rem;
        }
        .p3-arrow:nth-last-of-type(2) {
          margin-top: 0.84rem;
          margin-bottom: 0.13rem;
        }
        .p3-item {
          font-size: 0.18rem;
        }
        .p3-item::after {
          font-size: 0.18rem;
        }
        .p3-medium {
          width: 1.56rem;
          height: 1.56rem;
        }
        .p3-medium::after {
          top: calc(100% + 0.07rem);
        }
        .p3-medium2 {
          width: 1.75rem;
          height: 1.75rem;
        }
        .p3-medium2::after {
          top: calc(100% + 0.06rem);
        }
        .p3-max {
          width: 2.32rem;
          height: 2.32rem;
          margin-bottom: 0.53rem;
        }
        .p3-min {
          width: 1.14rem;
          height: 1.14rem;
          margin-left: 0px;
          .p3-minItem {
            font-size: 0.18rem;
          }
          .p3-minItem::after {
            font-size: 0.18rem;
            font-family: none;
          }
          .p3-minItem:nth-of-type(1) {
            top: -2.21rem;
            left: -2.26rem;
          }
          .p3-minItem:nth-of-type(1)::after {
            content: '24小时电话响应';
            top: 1.22rem;
            left: -0.22rem;
          }
          .p3-minItem:nth-of-type(2) {
            top: -0.57rem;
            left: -1.78rem;
          }
          .p3-minItem:nth-of-type(2)::after {
            content: '系统维护';
            top: 1.22rem;
            width: 100%;
            left: 0px;
          }
          .p3-minItem:nth-of-type(3) {
            top: -0.57rem;
            left: 1.78rem;
          }
          .p3-minItem:nth-of-type(3)::after {
            content: 'ROM对接';
            top: 1.22rem;
            width: 100%;
            left: 0px;
          }
          .p3-minItem:nth-of-type(4) {
            top: -2.21rem;
            left: 2.26rem;
          }
          .p3-minItem:nth-of-type(4)::after {
            content: '报备档案建立';
            top: 1.22rem;
            width: 100%;
            left: 0px;
          }
        }
        > .p3-min::after {
          width: 150%;
          content: '手机厂商关系协调';
          top: 1.22rem;
          left: -0.27rem;
        }
      }
    }
  }
}
</style>
<style lang="less">
// 轮播图
// p2
.p2-swiper .swiper_bg_blue {
  width: 6.54rem;
  margin-top: 0.71rem;
}
</style>
