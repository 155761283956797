<template>
  <div class="invite">
    <section class="part1">
      <div class="part1_main">
        <img
          src="../../assets/img/about/invite/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <div class="part2_main">
        <p class="p2_title" v-html="p2Data.title"></p>
        <p class="p2_time" v-html="p2Data.time"></p>
        <div class="p2_content">
          <p v-for="(item, i) in p2Data.list" :key="i" v-html="item"></p>
        </div>
      </div>
    </section>
    <section class="part3">
      <div class="part3_main">
        <div class="p3_top">
          <p>项目公告</p>
        </div>
        <div class="p3_item" v-for="(item, i) in p2Datas" :key="i" @click="p3Click(i)">
          <div class="p3_left">
            <img src="../../assets/img/about/invite/part3-icon.png" alt="">
            <p v-html="item.title"></p>
          </div>
          <div class="p3_right">
            <img src="../../assets/img/about/invite/part3-time.png" alt="">
            <p v-html="item.time"></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      p2Datas: [
        {
          title: '补充医疗险项目-邀标公告',
          time: '2023-03-01',
          list: [
            '北京百悟科技有限公司对《补充医疗险项目》进行邀标，请收到通知的单位联系报名。',
            '报名时间：2023年3月1号-2023年3月9号（9：00-18：00）',
            '开标时间（北京时间）：2023年3月13号 上午10：00',
            '报名方式：2568319862@qq.com（发送公司报名信息、营业执照<盖章>），审核通过后回复邮件，以获取招标文件',
            '联系人：宋经理',
            '电话：010-50950599',
            '联系地址：北京市朝阳区阜通东大街融科望京中心B座9层',
          ]
        },
        {
          title: '北京百悟科技有限公司百悟5G数智短信服务项目招标公告',
          time: '2023-03-10',
          list: [
            '北京百悟科技有限公司（简称“百悟科技”）拟对百悟科技5G数智短信服务项目进行公开询价采购，欢迎符合要求的单位参加报价，有关事项如下：',
            '一、项目名称：百悟5G数智短信服务项目',
            '二、项目地点：北京',
            '  1.1.采购内容：拟通过定向比选，最终确定1家中标单位，为招标人提供全国范围内的5G数智短信服务，包括提供信息发送5G消息通道、大数据能力、通道封装能力、终端解析能力、数据报告及用户平台能力.',
            '  1.2.服务期限：1年，中标公告后自合同签订之日起计算，具体以合同约定内容为准',
            '四、采购控制价：4700万元(含税)',
            '五、供应商资格要求',
            '1.供应商应为在中华人民共和国注册的具有独立承担民事责任的法人或其他组织，取得合法企业工商营业执照且在有效期内；',
            '2. 投标人须同时提供有效期内的以下证书：《中华人民共和国增值电信业务经营许可证》；',
            '3. 投标人须就能够为本项目开具增值税专用发票事项单独提供承诺函，国家相关法律法规规定不适用开具增值税专用发票的情形除外。',
            '4. 投标人未被列入招标人不良供应商名单中。',
            '5. 本项目不接受联合体报价。',
            '六、评选方法',
            '1.满足采购文件各项要求，报价最低的为中选单位。',
            '2.采购人不对未中选人就评选过程以及未能中选原因作出任何解释。',
            '七、关于报价',
            '（1）百悟科技5G数智短信服务项目报价函',
            '（2）法定代表人（负责人）身份证明书',
            '（3）法定代表人（负责人）授权书',
            '（4）营业执照副本复印件、增值电信业务经营许可证复印件',
            '上述报价文件均需加盖公章。',
            '快递地址：北京市朝阳区阜通东大街融科望京中心B座9层',
            '2.报价截止时间：2023年3月10日17时。',
            '八、联系方式',
            '业务咨询联系人：蔡经理 18611886070',
            '地址：北京市朝阳区阜通东大街融科望京中心B座9层'
          ]
        },
        {
          title: '补充医疗险项目-中标公告',
          time: '2023-03-13',
          list: [
            '2023年3月13号，我司在北京组织召开了：“补充医疗险项目”评审会，通过资格审查、招标小组打分等程序，最终结果如下：',
            '中标单位：中国太平洋财产保险股份有限公司北京分公司',
            '中标金额：184160元',
            '公示日期：2023年3月13号',
            '联系人：宋经理 010-50950599',
          ]
        },
        {
          title: '北京百悟科技有限公司百悟5G数智短信服务项目中标公告',
          time: '2023-03-23',
          list: [
            '北京百悟科技有限公司百悟5G数智短信服务项目比选结果公告：',
            '一、项目名称: 百悟5G数智短信服务项目',
            '二、比选 (成交) 信息',
            '供应商名称: 中国移动通信集团广东有限公司广州分公司',
            '中标 (成交) 金额:4700万元（含税）',
            '三、主要标的信息',
            '全国范围内的5G数智短信服务，服务期1年',
            '四、公告期限',
            '自本公告发布之日起1个工作日',
            '五、凡对本次公告内容提出询问，请按以下方式联系。',
            '名称: 北京百悟科技有限公司',
            '地址: 北京市朝阳区阜通东大街融科望京中心B座9层',
            '联系方式: 蔡经理 18611886070'
          ]
        },
      ],
      p2Data: {}
    }
  },
  mounted() {
    this.p2Data = this.p2Datas[0]
  },
  methods: {
    p3Click(i) {
      this.p2Data = this.p2Datas[i]
    }
  }
}
</script>

<style lang="less" scoped>
.invite{
  /* 第一部分 */
  .part1 {
    box-sizing: border-box;
    width: 100%;
    .part1_main {
      width: 1110px;
      margin: 0 auto;
      > img {
        width: 1110px;
      }
    }
  }
  .part2{
    width: 100%;
    .part2_main{
      width: 1110px;
      margin: 0 auto;
      margin-top: 38px;
      margin-bottom: 90px;
    }
    .p2_title{
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    .p2_time{
      margin-top: 10px;
      margin-bottom: 30px;
      text-align: center;
      font-size: 15px;
      color: #333333;
    }
    .p2_content{
      p{
        margin-bottom: 5px;
        font-size: 16px;
        color: #333333;
      }
    }
  }
  .part3{
    width: 100%;
    .part3_main{
      width: 1110px;
      margin: 0 auto;
      margin-bottom: 90px;
      background: #FAFBFD;
    }
    .p3_top{
      position: relative;
      padding: 20px;
      font-weight: 500;
      font-size: 14px;
      color: #1B1D21;
      &::after{
        content: '';
        position: absolute;
        top: 20px;
        left: 0px;
        display: block;
        width: 4px;
        height: 20px;
        background: #0B62BB;
        border-radius: 2px;
      }
    }
    .p3_item{
      padding: 20px;
      border-top: 1px solid #EDEDED;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .p3_left, .p3_right{
        display: flex;
        align-items: center;
      }
      .p3_left{
        img{
          width: 28px;
          height: 28px;
          margin-right: 12px;
        }
        p{
          font-size: 14px;
          font-weight: 500;
          color: #1B1D21;
        }
      }
      .p3_right{
        img{
          width: 14px;
          height: 14px;
          margin-right: 10px;
        }
        p{
          font-size: 14px;
          color: #888888;
        }
      }
    }
  }
}
</style>