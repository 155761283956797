<template>
  <section class="partFlexSwiper">
    <span class="part-title" v-text="pFlexSwiperTitle"></span>
    <div class="partFlexSwiper_main reveal-bottom2 pFlexSwiper">
      <div
        class="partFlexSwiper-item"
        v-for="(item, i) in pFlexSwiperData"
        :key="i"
        @mousemove="pFlexSwiperMove(i, item.imgOver, (chance = 1))"
        @mouseout="pFlexSwiperMove(i, item.img, (chance = 2))"
      >
        <div>
          <img :src="item.img" alt="" />
        </div>
        <span v-text="item.span"></span>
        <p v-text="item.p"></p>
      </div>
    </div>
    <div class="partFlexSwiper_main pFlexSwiper-swiper">
      <swiper :options="swiperOption">
        <swiper-slide
          class="swiper-item"
          v-for="(item, i) in pFlexSwiperDatas"
          :key="i"
        >
          <div
            class="partFlexSwiper-item"
            v-for="(item2, i2) in item"
            :key="i2"
            @click="pFlexSwiperMClick($event, item2)"
          >
            <div>
              <img :src="item2.img" alt="" />
            </div>
            <span v-text="item2.span"></span>
            <p v-text="item2.p"></p>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination swiper_bg_blue" slot="pagination"></div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      // pFlexSwiper
      swiperOption: {
        pagination: {
          el: '.pFlexSwiper-swiper .swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
    };
  },
  props: ['pFlexSwiperData', 'pFlexSwiperDatas', 'pFlexSwiperTitle'],
  methods: {
    // pFlexSwiper
    pFlexSwiperMove(i, img, chance) {
      switch (chance) {
        case 1:
          $('.partFlexSwiper-item').eq(i).addClass('active');
          break;
        case 2:
          $('.partFlexSwiper-item').eq(i).removeClass('active');
          break;
      }
      $('.partFlexSwiper-item').eq(i).find('img').attr('src', img);
    },
    pFlexSwiperMClick(e, item) {
      $('.partFlexSwiper-item').removeClass('active');
      $('.pFlexSwiper-swiper .partFlexSwiper-item').each((index, item2) => {
        this.pFlexSwiperData.find((item3) => {
          if (item3.span == $(item2).children('span').text()) {
            $(item2).find('img').attr('src', item3.img);
          }
        });
      });
      $(e.currentTarget).addClass('active');
      $(e.currentTarget).find('img').attr('src', item.imgOver);
    },
  },
};
</script>

<style lang="less" scoped>
.partFlexSwiper {
  width: 100%;
  background: url('../../assets/img/solution/governmentAndEnterprise/part2-bg.png')
    no-repeat;
  background-size: 100% 100%;
  padding-top: 72px;
  .part-title {
    color: #ffffff;
    margin-bottom: 92px;
  }
  .partFlexSwiper_main {
    width: 1120px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    .partFlexSwiper-item {
      box-sizing: border-box;
      width: 256px;
      height: 483px;
      background: #ffffff;
      box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.1);
      border-radius: 10px;
      margin-bottom: 75px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 20px;
      > div {
        height: 94px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
      }
      span {
        font-size: 22px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #333333;
        margin-bottom: 20px;
      }
      p {
        width: 216px;
        font-size: 16px;
        line-height: 27px;
        font-weight: 400;
        color: #666666;
        text-align: justify;
      }
    }
    .partFlexSwiper-item.active {
      background: linear-gradient(0deg, #0c63bb 0%, #6392cd 100%);
      border: 2px solid #ffffff;
      span,
      p {
        color: #ffffff;
      }
    }
  }
  .pFlexSwiper-swiper {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  /* 第二部分 */
  .partFlexSwiper {
    box-sizing: border-box;
    height: 7.68rem;
    background: url('../../assets/img/solution/governmentAndEnterprise/mobile/part2-bg.png')
      no-repeat;
    background-size: 100% 100%;
    padding-top: 0.52rem;
    .part-title {
      margin-bottom: 0.49rem;
    }
    .partFlexSwiper_main {
      width: 5.6rem;
      .swiper-item {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 0.1rem;
      }
      .partFlexSwiper-item {
        width: 2.56rem;
        height: 4.83rem;
        box-shadow: 0px 0.3rem 0.21rem 0px rgba(196, 197, 199, 0.1);
        border-radius: 0.1rem;
        padding-top: 0.2rem;
        margin-bottom: 0px;
        > div {
          height: 0.94rem;
          margin-bottom: 0.13rem;
          img {
            max-height: 100%;
          }
        }
        span {
          font-size: 0.21rem;
          margin-bottom: 0.18rem;
        }
        p {
          width: 2.17rem;
          font-size: 0.17rem;
          line-height: 0.27rem;
        }
      }
      .partFlexSwiper-item.active {
        background: linear-gradient(0deg, #0c63bb 0%, #6392cd 100%);
        border: 0.02rem solid #ffffff;
        span,
        p {
          color: #ffffff;
        }
      }
    }
    .pFlexSwiper {
      display: none;
    }
    .pFlexSwiper-swiper {
      display: block;
    }
  }
}
</style>
<style lang="less">
@media screen and (max-width: 768px) {
  .swiper_bg_blue {
    width: 5.6rem;
    margin-top: 0.45rem;
    .swiper-pagination-bullet {
      box-sizing: border-box;
      width: 0.18rem;
      height: 0.18rem;
      background: #f1f1f1;
      border-radius: 50%;
      margin: 0rem 0.15rem;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      border: 1px solid #ffffff;
      background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
    }
  }
}
</style>
