<template>
  <section class="partImg">
    <span class="part-title">典型案例</span>
    <div class="pImg_main pImg_m_pc reveal-left">
      <div
        class="pImg_item"
        v-for="(item, i) in pImgData"
        :key="i"
        @mousemove="pImgClick(i, item.imgOver)"
      >
        <img :src="item.img" alt="" />
      </div>
    </div>
    <div class="pImg_main pImg_m_mobile">
      <swiper :options="swiperOptionPImg">
        <swiper-slide
          class="swiper-item"
          v-for="(item, i) in pImgDatas"
          :key="i"
        >
          <div
            class="pImg_item"
            v-for="(item2, i2) in item"
            :key="i2"
            @click="pImgClickMobile(i, i2, item2.imgOver)"
          >
            <img :src="item2.img" alt="" />
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination swiper_bg_white" slot="pagination"></div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      // pImg
      swiperOptionPImg: {
        pagination: {
          el: '.pImg_m_mobile .swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
    };
  },
  props: ['pImgData', 'pImgDatas'],
  mounted() {},
  methods: {
    // pImg
    pImgClick(i, img) {
      $('.pImg_m_pc .pImg_item').each((index, item2) => {
        $(item2).find('img').attr('src', this.pImgData[index].img);
      });
      $('.pImg_m_pc .pImg_item').removeClass('active');
      $('.pImg_m_pc .pImg_item').eq(i).addClass('active');
      $('.pImg_m_pc .pImg_item').eq(i).find('img').attr('src', img);
    },
    pImgClickMobile(i, i2, img) {
      $('.pImg_m_mobile .pImg_item').each((index, item2) => {
        $(item2).find('img').attr('src', this.pImgData[index].img);
      });
      $('.pImg_m_mobile .pImg_item').removeClass('active');
      $('.pImg_m_mobile .swiper-item')
        .eq(i)
        .find('.pImg_item')
        .eq(i2)
        .addClass('active');
      $('.pImg_m_mobile .swiper-item')
        .eq(i)
        .find('.pImg_item')
        .eq(i2)
        .find('img')
        .attr('src', img);
    },
  },
};
</script>

<style lang="less" scoped>
.partImg {
  width: 100%;
  padding-top: 78px;
  padding-bottom: 84px;
  .part-title {
    margin-bottom: 86px;
  }
  .pImg_main {
    width: 1120px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .pImg_item {
      box-sizing: border-box;
      width: 344px;
      height: 162px;
      background: #ffffff;
      border: 2px solid #f1f1f1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .pImg_item.active {
      border: 2px solid #0b63bb;
    }
  }
  .pImg_m_mobile {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .partImg {
    padding-top: 0.58rem;
    padding-bottom: 1.88rem;
    .part-title {
      margin-bottom: 0.68rem;
    }
    .pImg_main {
      width: 6.14rem;
      .swiper-item {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
      .swiper-item:nth-of-type(2) {
        justify-content: center;
      }
      .pImg_item {
        box-sizing: border-box;
        width: 2.91rem;
        height: 1.37rem;
        border: 0.02rem solid #f1f1f1;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .pImg_item.active {
        border: 0.02rem solid #0b63bb;
      }
    }
    .pImg_m_pc {
      display: none;
    }
    .pImg_m_mobile {
      display: block;
    }
  }
}
</style>
